import React from 'react';
import calendar from '../assets/calendar.svg';
import side from '../assets/calendar-side.svg';

function Interviews() {
  return (
    <div className="flex justify-between">
      <img src={calendar} />
      <img src={side} />
    </div>
  );
}

export default Interviews;
