import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Job } from '../../models/job';
import MobilePreview from './MobilePreview';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import JobSurveyModal from './JobSurveyModal';
interface IDropDownProps {
  job: Partial<Job>;
  DeleteJob: (id: string) => void;
}

const DropdownMenu: React.FC<IDropDownProps> = (props) => {
  const [showCloseSurvey, setShowCloseSurvey] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const { DeleteJob } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {showConfirmDelete ? (
        <ConfirmDeleteModal
          job={props.job}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={() => {
            if (props.job.id === undefined) return;
            DeleteJob(props.job.id);
            setShowConfirmDelete(false);
          }}
        />
      ) : null}
      {showCloseSurvey ? <JobSurveyModal job={props.job} onClose={() => setShowCloseSurvey(false)} /> : null}
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded p-2 text-sm font-medium text-black hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <EllipsisHorizontalIcon className="h-5 w-5" />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="px-1 py-1">
          <Menu.Item>{() => <MobilePreview job={props.job} isDropDown />}</Menu.Item>
          <Menu.Item>
            {() => (
              <Link
                className={'hover: flex flex-row hover:bg-gray-100'}
                to={`/jobs/${props.job.id}/edit`}
                state={{ initJob: props.job }}
              >
                <PencilIcon className="mx-2 h-5 w-5" />
                <p>Edit</p>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? 'bg-gray-100' : ''
                } group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                onClick={() => setShowConfirmDelete(true)}
              >
                <TrashIcon className="mr-2 h-5 w-5" />
                Delete
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default DropdownMenu;
