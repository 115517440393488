import { AxiosError } from 'axios';
import React from 'react';
import { FormEvent, useEffect, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/axios';
import { useAuth } from '../../contexts/user';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps, ErrorResponse } from '../../utils/errors';
import clsx from 'clsx';

//PersonForm
interface PersonForm {
  title?: string;
  phone_number?: string;
  first_name: string;
  last_name: string;
}
type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
interface FormFields {
  name: string;
  description: string;
  headcount: string;
  industry_id: string;
  logo: File;
}

const headcounts: { id: string; name: string }[] = [
  { id: 'ten', name: '1-10' },
  { id: 'fifty', name: '11-50' },
  { id: 'two_hundred', name: '51-200' },
  { id: 'five_hundred', name: '201-500' },
  { id: 'one_thousand', name: '501-1,000' },
  { id: 'five_thousand', name: '1,001-5,000' },
  { id: 'ten_thousand', name: '5,001-10,000' },
  { id: 'max', name: '10,000+' },
];

export default function Profile() {
  const { axios } = useAxios();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userInputs, setUserInputs] = useState<PersonForm>({
    title: '',
    phone_number: '',
    first_name: '',
    last_name: '',
  });
  const [userPictureURL, setUserPicture] = useState<string | undefined>(
    user?.photo ? process.env.REACT_APP_API_BASE + user?.photo : undefined
  );

  const [industries, setIndustries] = useState<{ id: string; name: string }[]>([]);
  const [company, setCompany] = useState<Partial<FormFields>>({
    headcount: '',
    description: '',
    industry_id: '',
    name: '',
  });
  const [companyLogoURL, setCompanyLogo] = useState<string | undefined>(undefined);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (company.industry_id && company.description && company.headcount) {
      navigate('/onboard/plans');
    }
  };
  useEffect(() => {
    getUserProfile();
    getIndustries();
    getCompanyProfile();
  }, []);

  const getIndustries = () => {
    axios.get('/recruiters/industries').then(
      (res) => {
        setIndustries(res.data);
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_INDUSTRIES_LOADED);
      },
      (err: AxiosError<ErrorResponse>) => {
        console.warn(err);
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_INDUSTRIES_FAILED, errorProps(err));
      }
    );
  };

  const getCompanyProfile = () => {
    axios.get('/recruiters/company').then(
      (res) => {
        setCompany(res.data);
        if (res.data.logo) {
          setCompanyLogo(process.env.REACT_APP_API_BASE + res.data.logo);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getUserProfile = () => {
    axios.get('/recruiters/user_profile').then(
      (res) => {
        if (res.data.photo) {
          setUserPicture(process.env.REACT_APP_API_BASE + res.data.photo);
        }
        setUserInputs(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // TODO: debounce this so we don't save on every char change
  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserInputs((values) => ({ ...values, [name]: value }));

    // don't attempt to save unless the input is valid
    if (event.target.validity.valid) {
      axios
        .put('/recruiters/user_profile', {
          user: {
            [name]: value,
          },
        })
        .then(() => {
          Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_SUCCESS, { field: name });
        })
        .catch((error: AxiosError<ErrorResponse>) => {
          console.warn('error saving profile', error);
          Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_FAILED, errorProps(error));
        });
    }
  };

  //handle upload change
  const handleUserPictureChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target && target.files) {
      const formData = new FormData();
      formData.append('user[photo]', target.files[0], target?.files[0].name);
      axios
        .put('/recruiters/user_profile', formData)
        .then((result) => {
          Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_PHOTO_SUCCESS);
          setUserPicture(process.env.REACT_APP_API_BASE + result.data.photo);
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          console.warn('error saving photo', err);
          Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_PHOTO_FAILED, errorProps(err));
        });
    }
  };

  // TODO: debounce this so we don't save on every char change
  function handleCompanyChange(e: ChangeEvent<InputElement>): void {
    const name = e.target.name;
    const value = e.target.value;

    setCompany({
      ...company,
      [e.target.name]: value,
    });

    axios
      .put('/recruiters/company', {
        company: {
          [name]: value,
        },
      })
      .then(() => {
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_SUCCESS);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_FAILED, errorProps(err));
        console.warn('failed', err);
      });
  }

  const handleCompanyLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target && target.files) {
      const formData = new FormData();
      formData.append('company[logo]', target.files[0], target.files[0].name);
      axios
        .put('/recruiters/company', formData)
        .then((result) => {
          Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_LOGO_SUCCESS);
          setCompanyLogo(process.env.REACT_APP_API_BASE + result.data.logo);
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          console.warn('error saving logo', err);
          Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_LOGO_FAILED, errorProps(err));
        });
    }
  };

  return (
    <form
      className="container flex max-w-3xl flex-col gap-3 rounded-xl bg-white px-10 py-7"
      id="personForm"
      onSubmit={handleSubmit}
    >
      {/* User Profile */}
      <h1 className="mb-2 text-2xl font-black">Create your user profile</h1>

      <h2 className="mb-2 text-xl font-semibold">Your information</h2>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">First Name</h3>
        <input
          type="text"
          required
          placeholder="First Name"
          name="first_name"
          value={userInputs.first_name}
          onChange={handleUserChange}
        />
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Last Name</h3>
        <input
          type="text"
          required
          placeholder="Last Name"
          name="last_name"
          value={userInputs.last_name}
          onChange={handleUserChange}
        />
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Phone number</h3>
        <input
          type="tel"
          required
          placeholder="Phone number"
          name="phone_number"
          minLength={10}
          maxLength={10}
          value={userInputs.phone_number}
          onChange={handleUserChange}
        />
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Job title</h3>
        <input
          type="text"
          required
          placeholder="Job title"
          name="title"
          value={userInputs.title}
          onChange={handleUserChange}
        />
      </label>

      <label className="relative">
        <h3 className="mb-2 w-fit text-base font-medium">Profile Picture</h3>
        <div
          className={clsx(
            'w-full rounded-xl bg-primary/10 p-7 text-center transition-colors hover:bg-primary/30',
            userPictureURL && 'hidden'
          )}
        >
          Upload your Profile Picture
          <input id="dropzone-file" type="file" className="hidden" name="photo" onChange={handleUserPictureChange} />
        </div>
        {userPictureURL && (
          <img src={userPictureURL} className="mx-auto h-28 w-28 cursor-pointer rounded-full object-cover" />
        )}
      </label>

      {/* Company Profile */}
      <h1 className="mt-7 text-2xl font-black">Create your company profile</h1>

      <h2 className="mb-2 text-xl font-semibold">Company Infomation</h2>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Company Name</h3>
        <input
          type="text"
          required
          placeholder="Company Name"
          name="name"
          value={company.name}
          onChange={handleCompanyChange}
        />
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Company industry</h3>
        <select name="industry_id" id="industry_id" value={company.industry_id} required onChange={handleCompanyChange}>
          <option value="" disabled>
            Industry
          </option>
          {industries.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Tell us more about the company</h3>
        <textarea
          id="description"
          name="description"
          className="min-h-[5rem]"
          placeholder="About the company"
          required
          value={company.description}
          onChange={handleCompanyChange}
        />
      </label>

      <label>
        <h3 className="mb-2 w-fit text-base font-medium">Number of employees</h3>
        <select id="headcount" name="headcount" required value={company.headcount} onChange={handleCompanyChange}>
          <option value="" disabled>
            Number of employees
          </option>
          {headcounts.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </label>

      <label className="relative">
        <h3 className="mb-2 w-fit text-base font-medium">Company Logo</h3>
        <div
          className={clsx(
            'w-full rounded-xl bg-primary/10 p-7 text-center transition-colors hover:bg-primary/30',
            companyLogoURL && 'hidden'
          )}
        >
          Upload your Company&apos;s Logo
          <input id="dropzone-file" type="file" className="hidden" name="photo" onChange={handleCompanyLogoChange} />
        </div>
        {companyLogoURL && (
          <img src={companyLogoURL} className="mx-auto h-28 w-28 cursor-pointer rounded-full object-cover" />
        )}
      </label>

      <button type="submit" className="btn btn-primary">
        Save & Continue
      </button>
    </form>
  );
}
