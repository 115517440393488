import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

if (process.env?.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e9b851b3edec4fa5a0f48b958f5c88b5@o4504205963165696.ingest.sentry.io/4504206047510528',
  });
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
