import { AxiosError } from 'axios';

export interface ErrorResponse {
  errors?: string[];
  error?: string;
}

type GenericProps = {
  [key: string]: string | number;
};

export function errorProps(error: AxiosError<ErrorResponse>) {
  const props: GenericProps = {
    message: error.message,
  };

  if (error.response) {
    props['status'] = error.response.status;
    if (error.response.data.errors) {
      props['message'] = error.response.data.errors.join(', ');
    }
    if (error.response.data.error) {
      props['message'] = error.response.data.error;
    }
  }

  return props;
}
