import React from 'react';
import { BriefcaseIcon, BookOpenIcon } from '@heroicons/react/20/solid';
import { JobPostingMatch } from '../../../models/job-posting-match';
import { JobExperience, Education } from '../../../models/candidate';
import { SkillPills } from '../../../utils/pills';

/* Empty Block */
const Block = () => {
  return <div className="col-span-3 w-full"></div>;
};

/* Work Experience Dot */
const Dot = ({ type }: { type: 'Education' | 'JobExperience' }) => {
  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="absolute z-10 h-full w-0 border border-gray-300" />
      <div className="z-30 h-min w-min rounded-full border-2 border-gray-300 bg-white p-2">
        {type === 'Education' ? (
          <BookOpenIcon className="h-6 w-6 rounded-full bg-white text-pgreen" />
        ) : (
          <BriefcaseIcon className="h-6 w-6 rounded-full bg-white text-primary" />
        )}
      </div>
    </div>
  );
};

/* Line Connector */
const Line = () => {
  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute w-0 border border-gray-300" />
    </div>
  );
};

/* Education Block on Timeline */
function EducationBlock({ education }: { education: Education }) {
  return (
    <div
      className="col-span-3 flex flex-col gap-y-2 rounded-xl border border-gray-300 bg-pgreen/10 p-4"
      data-testid="education"
    >
      <div className="text-pgreen">
        {new Date(education.start_date).getFullYear()} - {new Date(education.end_date).getFullYear()}
      </div>
      <div className="text-xs font-semibold">{education.institution}</div>
      <div className="text-xs font-semibold text-subtitle">{education.degree}</div>
      <div className="text-xs">{education.gpa}</div>
    </div>
  );
}

/* Experience Block on Timeline */
function ExperienceBlock({ experience }: { experience: JobExperience }) {
  return (
    <div
      className="col-span-3 flex flex-col gap-y-2 rounded-xl border border-gray-300 bg-lightblue p-4"
      data-testid="experience"
    >
      <div className="text-primary">
        {new Date(experience.start_date).getFullYear()} - {new Date(experience.end_date).getFullYear()}
      </div>
      <div className="text-xs font-semibold">{experience.title}</div>
      <div className="text-xs font-semibold text-subtitle">{experience.company_name}</div>
      <div className="text-xs">{experience.location}</div>
      <SkillPills skills={experience.skills} />
    </div>
  );
}

export function JobTimeline({ match }: { match: JobPostingMatch }) {
  const exp = match.candidate?.job_experiences || [];
  const edu = match.candidate?.educations || [];
  const items = [...exp, ...edu];
  function isEducation(item: any): item is Education {
    return 'institution' in item;
  }
  return items.length === 0 ? (
    <div className="flex w-full flex-col items-center px-4 text-lg text-primary">No Education or Job Experiences</div>
  ) : (
    <div className="flex h-full w-full flex-col items-center overflow-y-scroll px-4 lg:px-[5rem]">
      <div className="w-full py-4 text-center">Present</div>
      <div className="z-30 h-min w-min rounded-full bg-primary p-2" />
      {items
        .sort((e1, e2) => new Date(e2.end_date).getTime() - new Date(e1.end_date).getTime())
        .map((item, index) => (
          <div key={item.id} className="grid w-full grid-cols-7 items-center justify-center">
            <Block />
            <Line />
            <Block />
            {index % 2 == 0 ? (
              <Block />
            ) : isEducation(item) ? (
              <EducationBlock education={item} />
            ) : (
              <ExperienceBlock experience={item} />
            )}
            {isEducation(item) ? <Dot type={'Education'} /> : <Dot type={'JobExperience'} />}
            {index % 2 == 0 ? (
              isEducation(item) ? (
                <EducationBlock education={item} />
              ) : (
                <ExperienceBlock experience={item} />
              )
            ) : (
              <Block />
            )}
          </div>
        ))}
      <div className="flex h-full min-h-[2rem] grow border border-gray-300" />
    </div>
  );
}
