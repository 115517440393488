import React from 'react';
import { useNavigate } from 'react-router-dom';
import pageNotFound from '../assets/page-not-found.svg';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <img src={pageNotFound} className="h-[15rem] w-[15rem]" />
      <div className="mt-2 text-center">
        <h1 className="mb-2 mt-2 text-lg font-medium">This page doesn’t exist</h1>
        <h1 className="w-60 md:w-full">Please check your URL or return to Prosper home.</h1>

        <button
          type="button"
          onClick={() => navigate('/')}
          className="mt-4 rounded-lg border border-primary px-10 py-1 text-primary hover:bg-primary hover:text-white"
        >
          Go to home
        </button>
      </div>
    </div>
  );
}

export default PageNotFound;
