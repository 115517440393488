import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { PencilIcon } from '@heroicons/react/20/solid';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { errorProps, ErrorResponse } from '../utils/errors';
import { GetIndustries } from '../services/industries';
import { Industry } from '../models/industry';
import { Company } from '../models/company';
import { GetCompany } from '../services/companies';

function ViewCompany() {
  const [company, setCompany] = useState<Company>({
    name: '',
    founded: 0,
    headcount: '',
    headquarters: '',
    website: '',
    description: '',
    industry_id: '',
    logo: '',
  });
  const [industries, setIndustries] = useState<Industry[]>([]);
  const { axios } = useAxios();
  const navigate = useNavigate();
  const navigateToSettings = () => {
    navigate('/settings/company');
  };

  useEffect(() => {
    GetCompany(axios)
      .then((res) => {
        Mixpanel.track(MixpanelEventType.VIEW_COMPANY_LOADED);
        setCompany(res.data);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        console.warn('error loading company profile', err);
        Mixpanel.track(MixpanelEventType.VIEW_COMPANY_FAILED, errorProps(err));
      });
    GetIndustries(axios).then(
      (res) => {
        setIndustries(res.data);
        Mixpanel.track(MixpanelEventType.VIEW_COMPANY_INDUSTRIES_LOADED);
      },
      (err: AxiosError<ErrorResponse>) => {
        console.warn(err);
        Mixpanel.track(MixpanelEventType.VIEW_COMPANY_INDUSTRIES_FAILED, errorProps(err));
      }
    );
  }, []);

  const getHeadcount = () => {
    const table = [
      {
        value: '',
        conversion: 'unknown',
      },
      {
        value: 'ten',
        conversion: '1-10',
      },
      {
        value: 'fifty',
        conversion: '11-50',
      },
      {
        value: 'two_hundred',
        conversion: '51-200',
      },
      {
        value: 'five_hundred',
        conversion: '201-500',
      },
      {
        value: 'one_thousand',
        conversion: '501-1000',
      },
      {
        value: 'five_thousand',
        conversion: '1001-5000',
      },
      {
        value: 'ten_thousand',
        conversion: '5001-10000',
      },
      {
        value: 'max',
        conversion: '10000+',
      },
    ];
    return table.find((item) => item.value == company.headcount)?.conversion;
  };

  const getIndustry = () => {
    if (industries.length != 0) {
      return industries.find((i) => i.id == company.industry_id)?.name;
    }
    return '';
  };

  const CompanyItem = ({ heading, value }: { heading: string; value: string }) => {
    return (
      <div className="flex flex-col justify-between text-sm xl:flex-row">
        <div className="text-gray-500">{heading}</div>
        <div className="">{value}</div>
      </div>
    );
  };
  return (
    <section className="w-screen bg-lightblue p-4">
      <h1 className="mb-6 text-2xl font-black">Profile</h1>
      <div className="flex h-5/6 flex-col items-stretch justify-center gap-8 sm:flex-row">
        {/* Company Card */}
        <div className="flex w-full flex-col justify-between rounded-lg bg-white p-8 sm:w-1/4">
          <div className="flex flex-col justify-center gap-2">
            <div className="flex flex-col items-center justify-center gap-4">
              <img
                src={process.env.REACT_APP_API_BASE + company.logo}
                className="h-12 w-12 rounded"
                alt={company.name}
              />
              <div className="text-xl font-semibold">{company.name}</div>
            </div>
            <hr className="my-4"></hr>
            <div className="flex min-w-min flex-col flex-wrap gap-y-2 text-sm">
              <CompanyItem heading={'Headquarters'} value={company.headquarters} />
              <CompanyItem heading={'Company Size'} value={getHeadcount() || ''} />
              <CompanyItem heading={'Industry'} value={getIndustry() || ''} />
              <CompanyItem heading={'Founded'} value={company.founded?.toString()} />
            </div>
            <hr className="my-4"></hr>
            <div className="text-sm text-gray-500">Email </div>
            <div className="break-all text-sm">{company.name}</div>
            <div className="text-sm text-gray-500">Website </div>
            <div className="break-all text-sm">{company.website}</div>
          </div>
          <div className="mt-12 flex w-full items-center justify-center">
            <button className="btn bg-lightblue text-primary" onClick={navigateToSettings}>
              <PencilIcon className="h-6 w-6 pr-2" /> Edit
            </button>
          </div>
        </div>
        {/* About Card */}
        <div className="flex w-full flex-col justify-between rounded-lg bg-white p-8 sm:w-2/3">
          <div className="flex flex-col justify-center gap-4">
            <div className="items-center justify-center">
              <h1 className="w-full text-center text-xl font-black">About</h1>
            </div>
            <div className="flex flex-col justify-start">
              <p className="text-sm font-semibold">Mission</p>
              <p className="text-sm text-gray-500">{company.description}</p>
            </div>
            <div className="flex flex-col justify-start text-sm">
              <p className="text-sm font-semibold">Values</p>
              <p className="text-sm text-gray-500">{company.description}</p>
            </div>
          </div>
          <div className="flex w-full items-center justify-center">
            <button className="btn items-center justify-center bg-lightblue text-primary" onClick={navigateToSettings}>
              <PencilIcon className="h-6 w-6 pr-2" /> Edit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewCompany;
