import React from 'react';
const CreateJobInputSection = ({
  title,
  htmlFor,
  children,
}: {
  title: string;
  htmlFor?: string;
  children: React.ReactNode;
}) => (
  <div className="grid grid-cols-[1fr_2fr] border-b border-subtitle/25 pb-6 last:border-b-0">
    <label htmlFor={htmlFor} className="text-sm">
      {title}
    </label>
    <div>{children}</div>
  </div>
);

export default CreateJobInputSection;
