import React, { useContext, useEffect, useState } from 'react';
import { JobContext, stages } from './CreateJob';
import TagInput from './TagInput';
import { Option } from '../../models/options';
import { useImmer } from 'use-immer';
import { titleize } from '../../utils/string';
import clsx from 'clsx';
import CreateJobInputSection from './CreateJobInputSection';

const phoneNumberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

const OnSite = () => {
  const { job, setJob } = useContext(JobContext);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };
  return (
    <div className="grid grid-cols-2 gap-3 ">
      <label className="col-span-2">
        <span>Street Address</span>
        <input type="text" name="street" placeholder="Street Address" />
      </label>
      <label className="space-y-2">
        <span>Apartment, Suite # (optional)</span>
        <input type="text" name="street" placeholder="Number" />
      </label>
      <label className="space-y-2">
        <span>Zipcode</span>
        <input type="text" name="street" placeholder="Zipcode" />
      </label>
      <label className="space-y-2">
        <span>State</span>
        <input type="text" name="street" placeholder="State" />
      </label>
      <label className="space-y-2">
        <span>Country</span>
        <input type="text" name="street" placeholder="Country" />
      </label>
    </div>
  );
};

const VideoCall = () => {
  const { job, setJob } = useContext(JobContext);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setJob((prev) => ({ ...prev, interview_location: value }));
  };
  return (
    <div className="flex justify-evenly">
      <label className="flex items-center gap-2">
        <input
          type="radio"
          name="meeting_platform"
          onChange={handleChange}
          defaultChecked={job.interview_location === 'zoom'}
          value="zoom"
          required
        />
        Zoom
      </label>
      <label className="flex items-center gap-2">
        <input
          type="radio"
          name="meeting_platform"
          onChange={handleChange}
          defaultChecked={job.interview_location === 'skype'}
          value="skype"
          required
        />
        Skype
      </label>
      <label className="flex items-center gap-2">
        <input
          type="radio"
          name="meeting_platform"
          onChange={handleChange}
          defaultChecked={job.interview_location === 'google_meet'}
          value="google_meet"
          required
        />
        Google Meet
      </label>
      <label className="flex items-center gap-2">
        <input
          type="radio"
          name="meeting_platform"
          onChange={handleChange}
          defaultChecked={job.interview_location === 'other'}
          value="other"
          required
        />
        Other
      </label>
    </div>
  );
};
const PhoneCall = () => {
  const { job, setJob } = useContext(JobContext);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setJob((prev) => ({ ...prev, interview_location: value }));
  };
  return (
    <label>
      <span>Phone Number</span>
      <input
        type="tel"
        name="phone"
        placeholder="+1 (555) 000-0000"
        defaultValue={
          job.interview_location && phoneNumberRegex.test(job.interview_location) ? job.interview_location : ''
        }
        required
        onChange={handleChange}
      />
    </label>
  );
};

type Tabs = 'on-site' | 'video_call' | 'phone_call';
const tabsObj: { [x in Tabs]: React.ReactNode } = {
  'on-site': <OnSite />,
  video_call: <VideoCall />,
  phone_call: <PhoneCall />,
};
const InterviewSection = () => {
  const { job } = useContext(JobContext);
  const startingTab = (() => {
    switch (job.interview_location) {
      case 'zoom':
      case 'skype':
      case 'google_meet':
      case 'other':
        return 'video_call';
      default:
        if (job.interview_location && phoneNumberRegex.test(job.interview_location)) return 'phone_call';
        else return 'on-site';
    }
  })();
  const [tab, setTab] = useState<Tabs>(startingTab);

  return (
    <CreateJobInputSection title="Interview Type">
      <section className="flex flex-col rounded-lg border border-subtitle/25">
        <div className="flex">
          {Object.entries(tabsObj).map(([str]) => (
            <label
              key={str}
              className={clsx(
                'flex grow items-center justify-center gap-3 border-r border-subtitle/25 p-1 first:rounded-tl-lg last:rounded-tr-lg last:border-r-0',
                tab === str ? 'bg-white text-primary' : 'border-b bg-subtitle/10 text-subtitle'
              )}
            >
              <input
                type="radio"
                className="h-3 w-3"
                name="interview_type"
                value={str}
                defaultChecked={tab === str}
                onChange={(e) => setTab(e.target.value as Tabs)}
              />
              {titleize(str)}
            </label>
          ))}
        </div>

        <div className="p-5">{tabsObj[tab]}</div>
      </section>
    </CreateJobInputSection>
  );
};

function NewJobInterview() {
  const { job, saveJob, toNextPage } = useContext(JobContext);

  const [participants, setParticipants] = useState<Option[]>([]);
  const selectedParticipantsState = useImmer<Option[]>([]);

  useEffect(() => {
    // ! Change to API call when endpoint is ready
    const participants: Option[] = [
      { value: '1', label: 'Participant 1' },
      { value: '2', label: 'Participant 2' },
      { value: '3', label: 'Participant 3' },
      { value: '4', label: 'Participant 4' },
    ];

    setParticipants(participants);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (job) {
      saveJob()
        .then(() => toNextPage())
        .catch((err) => console.log(err));
    }
  };
  return (
    <form id={stages[4].name} onSubmit={handleSubmit} className="flex flex-col gap-5 p-5">
      <h1 className="font-bold">Interview Settings</h1>

      <InterviewSection />

      <CreateJobInputSection title="Interview Duration" htmlFor="interview_duration">
        <select name="interview_duration" id="interview_duration" defaultValue="" required>
          <option value="" disabled>
            Interview Duration
          </option>
          <option value={15}>15 minutes</option>
          <option value={30}>30 minutes</option>
          <option value={45}>45 minutes</option>
          <option value={60}>60 minutes</option>
        </select>
      </CreateJobInputSection>
      <CreateJobInputSection title="Interviewers">
        {/* <input type="text" name="interviewers" placeholder="Add Participants" required /> */}
        <TagInput
          options={participants}
          selectedOptionsState={selectedParticipantsState}
          onChoiceChange={(choice: Option) => console.log(choice)}
          placeholder="Add Participants"
          labelText=""
        />
      </CreateJobInputSection>
    </form>
  );
}

export default NewJobInterview;
