import React, { useState } from 'react';
import PasswordResetEmail from './passwordResetEmail';
import PasswordResetVerifyEmail from './passwordResetVerifyEmail';
import FeaturesPanel from './featuresPanel';

function PasswordReset() {
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <div>
      <div className="flex h-screen w-full flex-row">
        <FeaturesPanel className="hidden w-5/12 flex-1 lg:flex" />
        <div className="flex bg-lightblue lg:w-7/12">
          {completed ? (
            <PasswordResetVerifyEmail email={email} />
          ) : (
            <PasswordResetEmail setEmail={setEmail} email={email} setCompleted={setCompleted} />
          )}
        </div>
      </div>
    </div>
  );
}
export default PasswordReset;
