import * as React from 'react';
import { JSX } from 'react/jsx-runtime';
const HandshakeIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#EF9645"
        d="M13.502 24.394a1.797 1.797 0 0 0 2.502-.442.62.62 0 0 0-.153-.866l-1.439-1.516 3.284 2.298a1.164 1.164 0 0 0 1.808-.703 1.168 1.168 0 0 0-.47-1.209l-2.797-2.57 4.18 2.927a1.165 1.165 0 0 0 1.818-1.16 1.167 1.167 0 0 0-.48-.752l-3.707-3.168 4.561 3.193a1.165 1.165 0 0 0 1.818-1.159 1.17 1.17 0 0 0-.48-.753L4.407 9.51 1.71 13.804a1.13 1.13 0 0 0 .214 1.47l6.056 5.19c.276.236.563.459.861.667l4.661 3.263Z"
      />
      <path
        fill="#fff"
        stroke="#DD9200"
        strokeWidth={0.2}
        d="m23.847 17.803-.001-.001-8.79-6.34-.013-.009-.01-.013-.046-.062-.041-.056.038-.058.042-.063.005-.008.007-.007.041-.041.054-.053.065.036.665.364.006.004c.364.236.969.531 1.643.768.675.237 1.411.41 2.04.41.43 0 .763-.08 1.004-.23.238-.148.394-.369.468-.667a.996.996 0 0 0-.814-1.143 5.38 5.38 0 0 1-2.218-.864l5.855 8.033Zm0 0a.989.989 0 0 1-1.135 1.62l-4.398-3.078a.392.392 0 0 0-.528.068.424.424 0 0 0-.04.5c.032.05.073.094.122.128l3.786 2.65a.99.99 0 0 1-1.134 1.62l-3.992-2.795a.43.43 0 0 0-.575.076.43.43 0 0 0 .083.627l2.897 2.027a.989.989 0 0 1-1.135 1.62l-.057.082.057-.082-3.094-2.166 9.143-2.897ZM9.4 9.299c.46.004.916-.069 1.351-.216l.986-.339c.916-.319 1.845-.64 2.755-.64.61 0 1.142.144 1.63.44l.002.001c.402.238.95.607 1.436.935l.431.29-8.59-.57m-.001.1v-.1m0 .1v-.1m0 .1c-1.218 0-2.407-.461-3.536-.899l-.012-.004H5.85l-.113-.045.035-.094M9.4 9.2c-1.2 0-2.374-.454-3.509-.894l-.003-.002-.115-.045m0 0-.036.093m.036-.093a1.08 1.08 0 0 0-1.302.436l1.266-.343m11.879 1.046a32.384 32.384 0 0 0-1.441-.938l1.44.938Zm0 0 .431.29m-.431-.29.431.29M5.737 8.351a.98.98 0 0 0-1.18.396h-.001l-2.697 4.296a.956.956 0 0 0 .18 1.24l6.056 5.19c.271.232.556.453.847.656l4.796 3.357a1.456 1.456 0 0 0 2.027-.357l.082.057M5.737 8.351l8.967 12.35a.429.429 0 0 0-.576.075.43.43 0 0 0 .083.627l1.426.998a.524.524 0 0 1 .128.728l.082.057m0 0a.624.624 0 0 0-.152-.867l-2.015 1.249a1.554 1.554 0 0 0 2.167-.382Zm2.2-13.499a5.28 5.28 0 0 0 2.179.849c.286.049.54.209.709.445l-2.887-1.294Z"
      />
      <path
        fill="#EF9645"
        d="M13.586 22.526a1.168 1.168 0 0 1-.303.53l-.78.774a1.163 1.163 0 0 1-1.952-1.12c.053-.2.157-.38.303-.526l.78-.774a1.16 1.16 0 0 1 1.83.232c.156.266.2.585.122.884Zm-8.2-3.828 1.168-1.164a1.167 1.167 0 0 0-1.034-1.972 1.163 1.163 0 0 0-.613.324l-1.17 1.164a1.166 1.166 0 1 0 1.648 1.648Zm4.047.04a1.166 1.166 0 1 0-1.65-1.646l-2.336 2.331a1.167 1.167 0 1 0 1.65 1.648l2.336-2.332Zm1.75 2.334a1.167 1.167 0 0 0-1.23-1.916c-.157.059-.3.151-.418.271l-1.56 1.552a1.167 1.167 0 0 0 .736 1.988c.354.028.68-.11.912-.342l1.56-1.553Zm12.876-3.217.487-.422-5.376-8.439-9.544 1.544a1.173 1.173 0 0 0-.96 1.351c.512 2.096 4.669.539 6.498-.467l8.895 6.433Z"
      />
      <path
        fill="#fff"
        stroke="#DD9200"
        strokeWidth={0.2}
        d="m5.314 17.85 1.17-1.165.07.07a1.166 1.166 0 0 0-.345-1.888l-.895 2.983Zm0 0a1.063 1.063 0 0 1-1.77-1.07c.052-.165.142-.315.264-.437m1.506 1.507-1.506-1.507m0 0-.07-.07 1.169-1.165-1.099 1.235Zm6.64 1.964-.008.1a1.067 1.067 0 0 1 .672 1.816l-1.56 1.553a1.058 1.058 0 0 1-.833.313 1.067 1.067 0 0 1-.673-1.817l1.56-1.552a1.059 1.059 0 0 1 .834-.313l.008-.1Zm0 0a1.167 1.167 0 0 1 .735 1.987l-1.648-1.645a1.158 1.158 0 0 1 .913-.342Zm1.984 4.674a1.062 1.062 0 0 1-1.783-.47 1.067 1.067 0 0 1 .275-1.034l.78-.774.002-.001a1.062 1.062 0 0 1 1.783 1.02c-.05.19-.147.354-.276.485l-.78.774Zm-3.068-5.093-.002.002-2.335 2.332-.002.002a1.068 1.068 0 1 1-1.51-1.506l.003-.002 2.335-2.332.002-.003a1.066 1.066 0 1 1 1.51 1.507Zm18.709-4.808.002.003a1.028 1.028 0 0 1-.191 1.336l-3.338 2.885-8.946-6.499a.852.852 0 0 0-.883-.071c-.982.494-2.377 1.05-3.58 1.239-.603.095-1.147.096-1.564-.039-.41-.133-.699-.396-.81-.844a1.068 1.068 0 0 1 .88-1.232 5.445 5.445 0 0 0 2.187-.853c.135-.09.288-.192.45-.302a31.44 31.44 0 0 1 1.42-.924h.002c.727-.44 1.49-.514 2.264-.411.733.098 1.468.353 2.188.603l.13.045.854.294c1.64.557 3.324-.095 4.83-.68l.132-.05a1.057 1.057 0 0 1 1.273.423l2.695 5.069.002.004.003.004Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.725.803h28v28h-28z" />
      </clipPath>
    </defs>
  </svg>
);
export default HandshakeIcon;
