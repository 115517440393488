import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useForm, SubmitHandler } from 'react-hook-form';
import Logo from './shared/images/Logo';
import LoadingSpinner from './shared/loadingSpinner';
import clsx from 'clsx';
import FeaturesPanel from './featuresPanel';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { IUser } from '../models/user';
import { Toast } from '../utils/toast';
import { AxiosError } from 'axios';
import { errorProps, ErrorResponse } from '../utils/errors';

interface ISignupForm {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  companyName?: string;
  confirmPassword?: string;
  acceptTerms?: boolean;
}

function Signup() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ISignupForm>({ shouldUseNativeValidation: true });

  const navigate = useNavigate();
  const { axios, loading } = useAxios();

  const toggleShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit: SubmitHandler<ISignupForm> = (data) => {
    setDisabled(true);
    axios
      .post<IUser>('/recruiters/register', {
        user: {
          email: data.email,
          first_name: data.firstName?.trim(),
          last_name: data.lastName?.trim(),
          password_confirmation: data.confirmPassword,
          password: data.password,
        },
        organization: {
          name: data.companyName?.trim(),
        },
      })
      .then((resp) => {
        Mixpanel.alias(resp.data.id);
        Mixpanel.track(MixpanelEventType.SIGNUP_SUCCESS);
        (document.getElementById('signupForm') as HTMLFormElement).reset();
        setTimeout(() => {
          setDisabled(false);
          navigate('/verify', { replace: true });
        }, 3000);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        setDisabled(false);
        Mixpanel.track(MixpanelEventType.SIGNUP_FAILED, errorProps(error));
        const status = error.response ? error.response.status : error.message;
        // user already exists
        switch (status) {
          case 422:
            Toast.error('User with that email address already exists.');
            break;
          // server error
          case 500:
            Toast.error(error.message);
            break;
          // default general error
          default:
            Toast.error('An error occurred, please try again');
        }
      });
  };

  return (
    <div className="flex h-screen">
      <FeaturesPanel className="hidden w-0 flex-1 lg:flex" />

      {/** Showcase And Form Split  */}
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          {/* Header */}
          <div>
            {/** Logo */}
            <Logo className="mb-3 mt-[8rem] block h-10 text-primary md:invisible md:mb-0 md:mt-0" />
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:-mt-10">
              Sign up for a 14-day Free Trial - Includes 1 Free Job!
            </h2>
            <p className="mt-2 text-sm text-gray-600">Already have a Prosper Online account?&nbsp;</p>
            <span>
              <Link className="text-sm font-medium text-primary no-underline hover:underline" to={'/login'}>
                Sign in
              </Link>
            </span>
          </div>
          <div className="mt-4">
            <div>
              {/** Sign Up Form */}
              <form id="signupForm" onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                {/** Company Email Address */}
                <div>
                  <label htmlFor="user_email">Company email address*</label>
                  <div className="mt-1">
                    <input
                      id="user_email"
                      type="email"
                      autoComplete="email"
                      {...register('email', { required: 'Email is required' })}
                      aria-invalid={errors.email ? 'true' : 'false'}
                      className={clsx(errors.email && 'invalid')}
                    />
                  </div>
                </div>

                {/** First Name & Last Name */}
                <div className="flex flex-col md:flex-row">
                  {/** First Name */}
                  <div className="mb-4 md:mb-0 md:pr-5">
                    <label htmlFor="user_first_name">First name*</label>
                    <div className="mt-1">
                      <input
                        id="user_first_name"
                        type="text"
                        {...register('firstName', { required: 'First name is required', maxLength: 50 })}
                        aria-invalid={errors.firstName ? 'true' : 'false'}
                        className={clsx(errors.firstName && 'invalid')}
                      />
                    </div>
                  </div>

                  {/** Last Name */}
                  <div className="">
                    <label htmlFor="user_last_name">Last name*</label>
                    <div className="mt-1">
                      <input
                        id="user_last_name"
                        type="text"
                        {...register('lastName', { required: 'Last name is required', maxLength: 50 })}
                        aria-invalid={errors.lastName ? 'true' : 'false'}
                        className={clsx(errors.lastName && 'invalid')}
                      />
                    </div>
                  </div>
                </div>

                {/** Company Name  */}
                <div>
                  <label htmlFor="user_company_name">Company name*</label>
                  <div className="mt-1">
                    <input
                      id="user_company_name"
                      type="text"
                      {...register('companyName', {
                        required: 'Company name is required',
                        minLength: {
                          value: 3,
                          message: 'Name must be at least 3 characters',
                        },
                        maxLength: 50,
                      })}
                      aria-invalid={errors.companyName ? 'true' : 'false'}
                      className={clsx(errors.companyName && 'invalid')}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/** Password */}
                <div>
                  <label htmlFor="user_password">Password*</label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="user_password"
                      type={passwordShown ? 'text' : 'password'}
                      {...register('password', {
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                      })}
                      aria-invalid={errors.password ? 'true' : 'false'}
                      className={clsx(errors.password && 'invalid')}
                      autoComplete="current-password"
                    />
                    <>
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        data-testid="password-show"
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        {passwordShown ? (
                          <EyeSlashIcon className="h-5 w-5 text-black" aria-hidden="true" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-black" aria-hidden="true" />
                        )}
                      </button>
                    </>
                  </div>
                </div>

                {/** Confirm Password */}
                <div>
                  <label htmlFor="confirm_user_password">Confirm Password*</label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="confirm_user_password"
                      type={passwordShown ? 'text' : 'password'}
                      {...register('confirmPassword', {
                        required: 'Confirm your password',
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        validate: (val) => {
                          if (watch('password') != val) {
                            return 'Passwords do not match';
                          }
                        },
                      })}
                      aria-invalid={errors.confirmPassword ? 'true' : 'false'}
                      className={clsx(errors.confirmPassword && 'invalid')}
                      autoComplete="current-password"
                    />
                    <>
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        {passwordShown ? (
                          <EyeSlashIcon className="h-5 w-5 text-black" aria-hidden="true" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-black" aria-hidden="true" />
                        )}
                      </button>
                    </>
                  </div>
                </div>

                {/** Terms And Policy Fine Print */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="user_accepts_policy_and_terms"
                      {...register('acceptTerms', { required: 'You must accept the terms' })}
                      className={clsx(
                        'block appearance-none rounded-md border border-gray-300 shadow-sm hover:cursor-pointer focus:border-primary focus:outline-none focus:ring-primary sm:text-sm',
                        errors.acceptTerms && 'invalid'
                      )}
                    />
                    <label className="ml-3 block text-sm text-gray-600" htmlFor="user_accept_policy_and_terms">
                      By clicking Start Free Trial, I agree that I have read and accepted the Terms of Use and Privacy
                      Policy.
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={disabled}
                    data-testid="submit"
                    className={clsx('btn btn-primary w-full', {
                      'disabled:cursor-not-allowed disabled:opacity-80': !loading || disabled === undefined,
                    })}
                  >
                    {!loading && disabled ? (
                      <LoadingSpinner className="fill-gray mr-2 h-4 w-4 text-white" />
                    ) : (
                      'Start Free Trial'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-8">
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm"></div>
            </div>

            {/** Privacy Policy & Terms Of Service fine print */}
            <div className="mb-5 mt-6 md:mb-0">
              <p className=" text-sm text-gray-600">
                Protected by reCAPTCHA and subject to the Prosper{' '}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href="https://www.joinprosper.ai/privacy"
                  target="_blank"
                  className="text-primary transition-all hover:opacity-70"
                >
                  Prosper Privacy Policy
                </a>
                {' and '}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href="https://www.joinprosper.ai/terms"
                  target="_blank"
                  className="text-primary transition-all hover:opacity-70"
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
