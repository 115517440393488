import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import DashboardTable from './dashboardTable';
import DashboardInterviews from './dashboardInterviews';
import DashboardApp from './dashboardApp';
import { Job } from '../models/job';
import { GetJobPostings } from '../services/job-postings';
import { useAxios } from '../contexts/axios';
import { GetInterviews, Interview } from '../services/interviews';

function Dashboard() {
  const today = new Date();
  const { axios } = useAxios();
  const [jobs, setJobs] = useImmer<Job[]>([]);
  const [matches, setMatches] = useState(0);
  const [interviews, setInterviews] = useState<Interview[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { data: interviewsData } = await GetInterviews(axios);
        setInterviews(interviewsData);
      } catch (error) {
        console.error(error);
      }

      try {
        const { data: jobsPostingsData } = await GetJobPostings(axios);
        setJobs(jobsPostingsData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const HeadingPill = ({ title, number }: { title: string; number: number }) => {
    return (
      <div className="flex w-full flex-col rounded-xl bg-white p-2 px-4">
        <span className="whitespace-nowrap text-lg font-semibold text-subtitle">{title}</span>
        <span className="text-2xl font-semibold">{number}</span>
      </div>
    );
  };

  return (
    <div className="flex grow flex-col gap-5">
      <h1 className="text-3xl font-semibold">Dashboard</h1>

      <div className="flex grow flex-wrap gap-6 2xl:flex-nowrap">
        <div className="flex grow flex-col gap-6">
          {/* Heading Pills */}
          <section className="flex grid w-full grid-cols-2 gap-12 lg:grid-cols-4">
            <HeadingPill title="New Matches" number={matches} />
            <HeadingPill title="Job Postings" number={jobs.length} />
            <HeadingPill title="Interviews" number={interviews.length} />
            <HeadingPill title="Expiring Soon" number={0 /* jobs.filter(job => job.isJobExpiringSoon).length */} />
          </section>
          <DashboardTable />
        </div>

        <div className="flex grow flex-col gap-4">
          <DashboardInterviews today={today} interviews={interviews} />
          <DashboardApp />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
