import clsx from 'clsx';
import React from 'react';

export default function Loader({ className }: { className: string }) {
  return (
    // Use before: to change the loader itself
    <div
      className={clsx('before:h-8 before:w-8 before:animate-loading before:rounded-full before:bg-primary', className)}
    ></div>
  );
}
