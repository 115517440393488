import { AxiosInstance, AxiosResponse } from 'axios';
import omit from 'lodash/omit';
import { Job } from '../models/job';
import { JobPostingMatch } from '../models/job-posting-match';

export function GetJobPosting(axios: AxiosInstance, id: string): Promise<AxiosResponse<Job>> {
  return axios.get<Job>(`/recruiters/job_postings/${id}`);
}

export function GetJobPostings(axios: AxiosInstance, page?: number, per_page?: number): Promise<AxiosResponse<Job[]>> {
  return axios.get<Job[]>('/recruiters/job_postings', { params: { page, per_page } });
}

export function CreateJobPosting(axios: AxiosInstance, job: Partial<Job>): Promise<AxiosResponse<Job>> {
  return axios.post<Job>('/recruiters/job_postings', {
    job_posting: job,
  });
}

export function UpdateJobPosting(axios: AxiosInstance, job: Partial<Job>): Promise<AxiosResponse<Job>> {
  return axios.put<Job>(`/recruiters/job_postings/${job.id}`, {
    job_posting: omit(job, ['id', 'company', 'skills', 'majors']),
  });
}

export function DeleteJobPosting(axios: AxiosInstance, id: string | undefined): Promise<AxiosResponse<Job>> {
  if (id === undefined) {
    console.error('No id provided');
  }

  return axios.delete<Job>(`/recruiters/job_postings/${id}`);
}

export function SetJobPostingStatus(
  axios: AxiosInstance,
  job: Job,
  status: 'active' | 'draft'
): Promise<AxiosResponse<Job>> {
  return axios.put<Job>(`/recruiters/job_postings/${job.id}`, {
    job_posting: {
      status,
    },
  });
}

export function GetJobMatches(
  axios: AxiosInstance,
  id: string,
  filter?: string,
  page?: number,
  per_page?: number
): Promise<AxiosResponse<JobPostingMatch[]>> {
  let url = `/recruiters/job_postings/${id}/matches`;
  if (filter) {
    url += `/${filter}`;
  }
  return axios.get(url, {
    params: {
      page,
      per_page,
    },
  });
}

export function GetJobMatchDetails(
  axios: AxiosInstance,
  job_id: string,
  match_id: string
): Promise<AxiosResponse<JobPostingMatch>> {
  return axios.get(`/recruiters/job_postings/${job_id}/matches/${match_id}`);
}

export function UpdateJobMatch(
  axios: AxiosInstance,
  job_id: string,
  match_id: string,
  status: string
): Promise<AxiosResponse<JobPostingMatch>> {
  return axios.put<JobPostingMatch>(`/recruiters/job_postings/${job_id}/matches/${match_id}`, {
    job_posting_match: {
      status,
    },
  });
}
