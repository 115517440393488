import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAxios } from '../contexts/axios';
import { useAuth } from '../contexts/user';
import FeaturesPanel from './featuresPanel';
import CheckMarkCircle from './shared/icons/CheckMarkCircle';
import LoadingSpinner from './shared/loadingSpinner';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { errorProps, ErrorResponse } from '../utils/errors';
import { AxiosError } from 'axios';

function ConfirmEmailVerified() {
  const [validUrl, setValidUrl] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  /* const [isEmailAlreadyConfirmed, setIsEmailAlreadyConfirmed] = useState<boolean>(false); */
  const { token } = useParams();
  const { axios } = useAxios();
  const auth = useAuth();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      await axios
        .get(`/recruiters/confirmation?confirmation_token=${token}`)
        .then((resp) => {
          Mixpanel.track(MixpanelEventType.CONFIRM_EMAIL_SUCCESS);
          auth.signin(resp.data);
          setValidUrl(true);
        })
        .catch((error: AxiosError<ErrorResponse>) => {
          Mixpanel.track(MixpanelEventType.CONFIRM_EMAIL_INVALID, errorProps(error));
          setValidUrl(false);
          console.log(error.response?.data.errors);
          // TODO possibly inform user email is already confirmed despite error.response.status === 422?
          /* if (error.response.data?.email) {
            setIsEmailAlreadyConfirmed(true);
          } */
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // delay api call
    const delayFetchTimer = setTimeout(() => {
      verifyEmailUrl();
    }, 1000);

    // clean up (useEffect) function - clear timer
    return () => clearTimeout(delayFetchTimer);
  }, []);

  //TODO should the app loading indicator be here instead of LoadingSpinner component?
  if (loading) {
    return (
      <div className="mx-auto flex h-screen w-full items-center justify-center">
        <LoadingSpinner className="w-10 fill-white text-primary" />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      {validUrl ? (
        <>
          <FeaturesPanel className="hidden w-0 flex-1 lg:flex" />

          {/** Showcase and form split  */}
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                {/** 'CheckMarkCircle' icon */}
                <CheckMarkCircle className="mx-auto -mt-20 w-[256px]" />
              </div>
              <div>
                <h1 className="text-center text-2xl font-black">Verified!</h1>
              </div>
              <div className="mt-5">
                <p className="text-center text-sm font-[600] text-[#858585]">
                  Yay! you have successfully verified your account.
                </p>
              </div>
              <div>
                <Link to={'/onboard/profile'}>
                  <button className="mx-auto mt-10 flex rounded-md border border-transparent bg-primary px-12 py-3 text-sm font-medium text-white shadow-sm md:px-28 md:py-4">
                    Setup profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        /**
         * TODO if user exits tab or email already confirmed show error content and exit onboarding track/path...
         *  navigates to home page or allow user to continue onboarding? - possibly a different task/ticket?
         */
        <div className="mx-auto flex h-screen items-center">
          <div>
            <div>
              {/** 'CheckMarkCircle' icon */}
              <ExclamationCircleIcon className="mx-auto -mt-20 w-[256px] text-black" />
            </div>
            <div>
              <h1 className="text-center text-2xl font-black">Verification Invalid!</h1>
            </div>
            <div className="mt-5">
              <p className="text-center text-sm font-[600] text-red-500">Email verification link expired or invalid.</p>
            </div>
            <div>
              <Link to={'/'}>
                <button className="mx-auto mt-10 flex rounded-md border border-transparent bg-primary px-12 py-3 text-sm font-medium text-white shadow-sm md:px-28 md:py-4">
                  Go to home page
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmEmailVerified;
