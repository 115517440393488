import React, { FormEvent, Fragment, useEffect } from 'react';
import { useImmer } from 'use-immer';
import clsx from 'clsx';
import axios from 'axios';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementOptions,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps } from '../../utils/errors';
import { Toast } from '../../utils/toast';
import { EllipsisHorizontalIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import ShowHideSection from './ShowHideSection';
import CardLogo from '../shared/icons/CardLogo';
import { PaymentMethod } from '../../models/payment-method';
import { Card } from '../../models/card';

const stripeStyle: StripeElementStyle = {
  base: {
    color: 'rgb(153 159 169)',
    fontSize: '16px',
    fontFamily: 'Nunito',
  },
};

const cardOptions: StripeCardNumberElementOptions = {
  style: stripeStyle,
  showIcon: true,
};

const expiryOptions: StripeCardExpiryElementOptions = {
  style: stripeStyle,
};

const cvcOptions: StripeCardCvcElementOptions = {
  style: stripeStyle,
};

type InvoiceInfo = {
  created: number;
  number: string;
  hosted_url: string;
  pdf_url: string;
  status: string;
  subtotal: number;
  tax: number;
  total: number;
};

export default function BillingSettings() {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethods, setPaymentMethods] = useImmer<PaymentMethod[]>([]);

  const [invoices, setInvoices] = useImmer<InvoiceInfo[]>([]);

  useEffect(() => {
    axios.get<PaymentMethod[]>('/recruiters/billing/payment_methods').then(
      (res) => {
        setPaymentMethods(res.data);

        Mixpanel.track(MixpanelEventType.SETTING_BILLING_PAYMENT_METHODS_LOADED);
        console.table(res.data);
      },
      (err) => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_PAYMENT_METHODS_FAILED, errorProps(err));
        console.error(err);
      }
    );
  }, []);

  useEffect(() => {
    axios.get('/recruiters/billing/invoices').then(
      (res) => {
        setInvoices(res.data);

        Mixpanel.track(MixpanelEventType.SETTING_BILLING_INVOICES_LOADED);
        console.log(res.data);
      },
      (err) => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_INVOICES_FAILED, errorProps(err));
        console.error(err);
      }
    );
  }, []);

  // from 1234 to 12.34
  function centsToDollars(cents: number): string {
    return (cents / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  function dateConverter(dateNum: number): string {
    return new Date(dateNum * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  const savePaymentMethod = async (e: FormEvent) => {
    e.preventDefault();
    console.log('Saved Payment Method');

    if (!stripe || !elements) {
      return;
    }

    const cardNumElem = elements.getElement(CardNumberElement);

    if (cardNumElem) {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumElem,
        // billing_details: {
        //   address: {
        //     postal_code: billingInfo.billing_zip,
        //   },
        // },
      });

      if (result.error) {
        console.log('Error creating PaymentMethod', result.error);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_FAILED, {
          error_code: result.error.code,
        });
        Toast.error('Error saving payment method');
      } else {
        console.log('Created PaymentMethod', result.paymentMethod);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_SUCCESS);
        axios
          .post('/recruiters/billing/payment_methods', {
            payment_method_id: result.paymentMethod.id,
          })
          .then(() => {
            cardNumElem.clear();
            elements.getElement(CardExpiryElement)?.clear();
            elements.getElement(CardCvcElement)?.clear();
            setPaymentMethods((draft) => {
              if (result.paymentMethod.card) {
                // unset current default
                const currentDefault = draft.find((pm) => pm.default);
                if (currentDefault) {
                  currentDefault.default = false;
                }

                // add new default card
                const newPaymentMethod: PaymentMethod = {
                  id: result.paymentMethod.id,
                  type: 'card',
                  default: true,
                  card: result.paymentMethod.card as Card,
                };
                draft.unshift(newPaymentMethod);
              }
              return draft;
            });
            Mixpanel.track(MixpanelEventType.SETTING_BILLING_ADD_PAYMENT_METHOD_SUCCESS);
            Toast.success('Payment method saved');
          })
          .catch((err) => {
            Mixpanel.track(MixpanelEventType.SETTING_BILLING_ADD_PAYMENT_METHOD_FAILED, errorProps(err));
            Toast.error('Error saving payment method');
            console.log(err);
          });
      }
    }
  };

  const setDefaultPaymentMethod = (id: string) => {
    axios
      .put(`/recruiters/billing/payment_methods/${id}`)
      .then(() => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_SET_DEFAULT_PAYMENT_METHOD_SUCCESS);

        setPaymentMethods((draft) => {
          // unset current default
          const currentDefault = draft.find((pm) => pm.default);
          if (currentDefault) {
            currentDefault.default = false;
          }

          // set new default
          const currentPaymentMethod = draft.find((pm) => pm.id === id);
          if (currentPaymentMethod) {
            currentPaymentMethod.default = true;
          }
          return draft;
        });
        Toast.success('Default payment method saved');
      })
      .catch((err) => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_SET_DEFAULT_PAYMENT_METHOD_FAILED, errorProps(err));
        Toast.error('Error setting default payment method');
      });
  };

  const deletePaymentMethod = (id: string) => {
    axios
      .delete(`/recruiters/billing/payment_methods/${id}`)
      .then(() => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_DELETE_PAYMENT_METHOD_SUCCESS);
        setPaymentMethods((draft) => {
          draft.splice(
            draft.findIndex((pm) => pm.id === id),
            1
          );
          return draft;
        });
        Toast.success('Payment method deleted');
      })
      .catch((err) => {
        Mixpanel.track(MixpanelEventType.SETTING_BILLING_DELETE_PAYMENT_METHOD_FAILED, errorProps(err));
        Toast.error('Error deleting payment method');
      });
  };

  return (
    <form className="flex flex-col gap-8" onSubmit={savePaymentMethod}>
      <h1 className="text-xl font-bold ">Payment Method</h1>

      <ShowHideSection className="" sectionName="Pay With">
        <div className="mt-5 flex flex-col gap-5 pb-4 sm:flex-row">
          <label className="inline-flex cursor-pointer items-center gap-2 text-base font-semibold" htmlFor="card">
            <input
              className="peer hidden"
              id="card"
              type="radio"
              name="paymentMethod"
              value="card"
              defaultChecked={true}
            />
            {/* Custom Radio Input */}
            <div className="h-[1.25em] w-[1.25em] rounded-full border-2 border-subtitle p-1 after:block after:h-full after:w-full after:scale-0 after:rounded-full after:bg-primary peer-checked:border-primary peer-checked:after:scale-100" />
            Credit/ Debit Card
          </label>
        </div>
      </ShowHideSection>

      <div className="flex flex-col gap-3">
        <label className="flex w-[calc(50%_-_0.5rem)] flex-col gap-3 text-base">
          Card Details
          <CardNumberElement options={cardOptions} />
        </label>
        <div className="flex gap-4">
          <label className="flex w-full flex-col gap-3 text-base">
            Expiration Date
            <CardExpiryElement options={expiryOptions} />
          </label>

          <label className="flex w-full flex-col gap-3 text-base">
            <span className="">CVV</span>
            <CardCvcElement options={cvcOptions} />
          </label>
        </div>
      </div>

      <button type="submit" className="self-start rounded-xl bg-primary px-8 py-4 text-white">
        Save Payment Method
      </button>

      <div className="flex flex-col gap-3">
        <h2 className="font-semibold">Credit Cards In File</h2>
        {paymentMethods.map((pm) => (
          // ? How do I know what is default Payment Method
          <div
            key={pm.id}
            className={clsx(
              'flex w-full items-center gap-5 rounded-lg p-3 text-sm',
              pm.default ? 'bg-primary text-white' : 'border border-subtitle/20'
            )}
          >
            <CardLogo brand={pm.card.brand} className={clsx('h-8 w-12')} />
            <div className="flex flex-col gap-1">
              <span>
                {pm.card.brand[0].toUpperCase() + pm.card.brand.substring(1)} {pm.card.last4}
              </span>
              <span>
                Expires {String(pm.card.exp_month).padStart(2, '0')}/{pm.card.exp_year}
              </span>
            </div>

            {pm.default ? (
              <CheckCircleIcon className="ml-auto h-7" />
            ) : (
              <Menu as="div" className="ml-auto">
                <div>
                  <Menu.Button className="">
                    <span className="sr-only">Open card menu</span>
                    <EllipsisHorizontalIcon className="h-5 w-5" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => setDefaultPaymentMethod(pm.id)}
                        className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
                      >
                        <EyeIcon className="h-4 w-4" />
                        Set as Default
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => deletePaymentMethod(pm.id)}
                        className="flex items-center gap-2 px-4 py-2 text-sm text-red-700"
                      >
                        <TrashIcon className="h-4 w-4" />
                        Delete
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        ))}
      </div>

      {/* Invoice Table */}
      <table className="min-w-full table-fixed divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-lg font-medium text-gray-900">
              Invoice
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-lg font-medium text-gray-900">
              Amount
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-lg font-medium text-gray-900">
              Date
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-lg font-medium text-gray-900">
              Users
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-lg font-medium text-gray-900">
              Status
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Menu</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {invoices.map((invoice) => (
            <tr key={invoice.number} className="">
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.number}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{centsToDollars(invoice.total)}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dateConverter(invoice.created)}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{/*invoice.users*/}0</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <button className="rounded-full border border-green-500 px-6 py-1 font-semibold text-green-500">
                  Paid
                </button>
              </td>
              <td className="px-2">
                <Menu as="div">
                  <div>
                    <Menu.Button className="">
                      <span className="sr-only">Open job menu</span>
                      <EllipsisHorizontalIcon className="h-5 w-5" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <a
                          href={invoice.hosted_url}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
                        >
                          <EyeIcon className="h-4 w-4" />
                          View Invoice
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href={invoice.pdf_url}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
                        >
                          <PencilIcon className="h-4 w-4" />
                          Download PDF
                        </a>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  );
}
