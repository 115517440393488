import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementOptions,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { useAxios } from '../../contexts/axios';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { AxiosError } from 'axios';
import { errorProps, ErrorResponse } from '../../utils/errors';

const stripeStyle: StripeElementStyle = {
  base: {
    color: 'rgb(153 159 169)',
    fontSize: '16px',
    fontFamily: 'Nunito',
  },
};

const cardOptions: StripeCardNumberElementOptions = {
  style: stripeStyle,
  showIcon: true,
};

const expiryOptions: StripeCardExpiryElementOptions = {
  style: stripeStyle,
};

const cvcOptions: StripeCardCvcElementOptions = {
  style: stripeStyle,
};

interface IProps {
  complete: VoidFunction;
  back: VoidFunction;
}

export default function BillingPayment(props: IProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { axios } = useAxios();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardNumElem = elements.getElement(CardNumberElement);

    if (cardNumElem) {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumElem,
        // billing_details: {
        //   address: {
        //     postal_code: billingInfo.billing_zip,
        //   },
        // },
      });

      if (result.error) {
        console.log('Error creating PaymentMethod', result.error);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_FAILED, {
          error_code: result.error.code,
        });
        setLoading(false);
      } else {
        console.log('Created PaymentMethod', result.paymentMethod);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_SUCCESS);
        axios
          .post('/recruiters/billing/payment_methods', {
            payment_method_id: result.paymentMethod.id,
          })
          .then(() => {
            props.complete();
            Mixpanel.track(MixpanelEventType.BILLING_PAYMENT_SUCCESS);
          })
          .catch((err: AxiosError<ErrorResponse>) => {
            setLoading(false);
            Mixpanel.track(MixpanelEventType.BILLING_PAYMENT_FAILED, errorProps(err));
          });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="text-lg font-bold">Payment Method</h1>

      <div className="mt-5 pr-[5%] lg:pr-[20%]">
        <label className="mb-2 block font-medium" htmlFor="apt">
          Card Details
        </label>
        <CardNumberElement options={cardOptions} />
      </div>

      <div className="mt-5 grid w-full grid-cols-1 gap-3 pr-[5%] md:grid-cols-2 lg:pr-[20%]">
        <label className="flex flex-col gap-2 font-medium" htmlFor="expirationDate">
          Expiration Date
          <CardExpiryElement options={expiryOptions} />
        </label>

        <label className="flex flex-col gap-2 font-medium" htmlFor="cvv">
          CVV
          <CardCvcElement options={cvcOptions} />
        </label>
      </div>

      {/** Back & 'Start my free trial' Button */}
      <div className="mt-5 flex flex-row items-center">
        <button
          className="mr-3 inline-flex items-center font-medium text-primary no-underline hover:underline md:mr-4"
          onClick={() => props.back()}
        >
          <ChevronLeftIcon className="h-5 w-5 text-primary" strokeWidth={2.5} />
          Back
        </button>

        <button
          type="submit"
          className="btn btn-primary md:w-58 max-w-sm px-[9px] py-2 md:px-10 md:py-4"
          disabled={loading}
        >
          Start my free trial
        </button>
      </div>
    </form>
  );
}
