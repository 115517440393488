import React, { useEffect, useState } from 'react';
import JobListDropdown from './JobListDropdown';

import { ArchiveBoxIcon, DocumentCheckIcon, DocumentIcon, StopCircleIcon } from '@heroicons/react/24/outline';
import Badge from '../shared/badge';
import { Job, JobStatus } from '../../models/job';
import { Link } from 'react-router-dom';
import { daysUntil } from '../../utils/date';

const statusIcons: Record<JobStatus, typeof ArchiveBoxIcon> = {
  active: DocumentCheckIcon,
  draft: DocumentIcon,
  expired: ArchiveBoxIcon,
  disabled: StopCircleIcon,
};

const statusText: Record<JobStatus, string> = {
  active: 'Published',
  draft: 'Draft',
  expired: 'Expired',
  disabled: 'Disabled',
};

const statusLabel = (status?: JobStatus): string => {
  if (status === undefined) {
    return '';
  }

  return statusText[status];
};

function JobCard(props: { job: Partial<Job>; Deletejob: (id: string) => void }) {
  const [expirationCountdown, setExpirationCountdown] = useState<number>(0);

  useEffect(() => {
    if (props.job.expires_at) {
      setExpirationCountdown(daysUntil(props.job.expires_at));
    }
  }, [props.job.expires_at]);

  return (
    <div className="min-w-fit flex-grow rounded-md bg-white p-4" role="listitem">
      <div className=" flex place-content-between text-sm font-medium text-header">
        <Link to={`${props.job.id}`}>{props.job.title}</Link>
        <JobListDropdown job={{ ...props.job }} DeleteJob={props.Deletejob} />
      </div>
      <div className="my-2 text-xs text-subtitle">Matches:</div>
      <div className="flex w-full rounded-sm bg-lightblue p-2">
        <div className="flex flex-1 flex-col border-l-2 border-pblue px-2">
          <div className="text-xs uppercase text-subtitle">Total</div>
          <div>0</div>
        </div>
        <div className="flex flex-1 flex-col border-l-2 border-pblue px-2">
          <div className="text-xs uppercase text-subtitle">New</div>
          <div>0</div>
        </div>
      </div>
      <div className="my-2 flex space-x-4">
        {expirationCountdown !== null ? (
          <Badge color={expirationCountdown >= 15 ? 'green' : 'red'}>{expirationCountdown} days</Badge>
        ) : (
          <Badge color="orange">No Exp. Date</Badge>
        )}
        <Badge color="blue">3 interviews</Badge>
      </div>
      <div className="border-t-1 flex justify-between gap-x-4 border-[#F5F5F5] pt-2">
        <div className="flex text-xs text-subtitle">{statusLabel(props.job.status)}</div>
      </div>
    </div>
  );
}

export default JobCard;
