import { AxiosInstance, AxiosResponse } from 'axios';
import { Notification } from '../models/notification';

export function GetNotifications(axios: AxiosInstance): Promise<AxiosResponse<Notification[]>> {
  return axios.get<Notification[]>('/recruiters/notifications');
}

export function SetRead(axios: AxiosInstance, notification: Notification): Promise<AxiosResponse<Notification>> {
  return axios.put<Notification>(`/recruiters/notifications/${notification.id}`);
}

export function SetAllRead(axios: AxiosInstance): Promise<AxiosResponse<Notification>> {
  return axios.post<Notification>('/recruiters/notifications/mark_all_as_read');
}
