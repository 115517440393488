import React from 'react';
import clsx from 'clsx';
import {
  ChartPieIcon,
  UserIcon,
  Cog6ToothIcon,
  BellIcon,
  CreditCardIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { NavLink, Outlet } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51LqSRYJLv1pJKVphvt30OOn5iGB3hZq0rC9DSf6TE4rFNm3BN6pQqYxJfkuQDkcQrWysbjb1xoVmcWF7pUgx9i7s00HjSOIeee'
); // TODO: use envar for this once we are ready for prod use.

const stripeOptions: StripeElementsOptions = {
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito' }],
};

const generalSettings = {
  section: <h1 className="">GENERAL SETTINGS</h1>,
  children: [
    {
      name: 'Company Information',
      description: 'Tell us about where you work!',
      href: 'company',
      icon: ChartPieIcon,
    },
    {
      name: 'Personal Information',
      description: 'Tell us who you are!',
      href: 'personal',
      icon: UserIcon,
    },
  ],
};

const accountSettings = {
  section: <h1 className="mt-auto">ACCOUNT SETTINGS</h1>,
  children: [
    {
      name: 'Account Access & Security',
      description: 'Change your credentials here!',
      href: 'account',
      icon: Cog6ToothIcon,
    },
    {
      name: 'Notifications',
      description: 'Choose how often you want to hear from us!',
      href: 'notification',
      icon: BellIcon,
    },
    {
      name: 'Billing',
      description: 'Safe and secure.',
      href: 'billing',
      icon: CreditCardIcon,
    },
    {
      name: 'Help Desk',
      description: 'Got questions?',
      href: 'helpDesk',
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
  ],
};

function Settings() {
  return (
    <div className="flex flex-grow gap-8">
      <aside className="sticky top-24 flex max-h-[54rem] flex-col rounded-xl bg-white pb-10 [&>*]:py-5 [&>*]:pl-10 [&>*]:pr-10 2xl:[&>*]:!pr-0 ">
        {' '}
        {
          <>
            {generalSettings.section}
            {generalSettings.children.map((link) => (
              <NavLink
                key={link.href}
                to={link.href}
                className={({ isActive }) =>
                  clsx(isActive && 'border-l-4 border-l-primary bg-primary/10', 'flex items-center gap-7')
                }
              >
                <link.icon className="h-14 flex-shrink-0 rounded-full bg-primary/10 p-3 text-primary" />
                <div className="flex flex-col gap-2">
                  <h2 className="text-lg font-medium">{link.name}</h2>
                  {/* Font doesn't go any thinner */}
                  <p className="max-w-[20ch] font-thin 2xl:max-w-[40ch]">{link.description}</p>
                </div>
              </NavLink>
            ))}
          </>
        }
        {
          <>
            {accountSettings.section}
            {accountSettings.children.map((link) => (
              <NavLink
                key={link.href}
                to={link.href}
                className={({ isActive }) =>
                  clsx(isActive && 'border-l-4 border-l-primary bg-primary/10', 'flex items-center gap-7')
                }
              >
                <link.icon className="h-14 flex-shrink-0 rounded-full bg-primary/10 p-3 text-primary" />
                <div className="flex flex-col gap-2">
                  <h2 className="text-lg font-medium">{link.name}</h2>
                  {/* Font doesn't go any thinner */}
                  <p className="w-[20ch] font-thin 2xl:w-[40ch]">{link.description}</p>
                </div>
              </NavLink>
            ))}
          </>
        }
      </aside>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <section className="basis-full rounded-xl bg-white px-10 py-5">
          <Outlet />
        </section>
      </Elements>
    </div>
  );
}

export default Settings;
