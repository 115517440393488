import { AxiosError } from 'axios';
import React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { CheckIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid';
import ShowHideSection from '../settingPages/ShowHideSection';

interface IPersonChangeForm {
  first_name: string;
  last_name: string;
  phone_number: string;
}

interface Photo {
  name: string;
  size: number;
}

function UserSettings() {
  const { axios } = useAxios();
  const [inputs, setInputs] = useState<IPersonChangeForm>({
    first_name: '',
    last_name: '',
    phone_number: '',
  });
  const [photo, setPhoto] = useState<Photo>({} as Photo);
  const [photoURL, setURL] = useState('');
  useEffect(() => {
    axios.get('/recruiters/user_profile').then(
      (res) => {
        setInputs({
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          phone_number: res.data.phone_number,
        });
        setURL(process.env.REACT_APP_API_BASE + res.data.photo);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.validity.valid) {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  }

  function handleUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files === null || e.target.files.length === 0) return;
    const photo = e.target.files[0];

    setPhoto({
      name: photo.name,
      size: photo.size,
    });

    const data = new FormData();
    data.append('user[photo]', photo);
    axios
      .put('/recruiters/user_profile', data)
      .then((res) => {
        setURL(process.env.REACT_APP_API_BASE + res.data.photo);
        Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_PHOTO_SUCCESS);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        console.warn('error saving photo', err);
        Mixpanel.track(MixpanelEventType.CREATE_USER_PROFILE_PHOTO_FAILED, errorProps(err));
      });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    axios
      .put('/recruiters/user_profile', { user: inputs })
      .then(() => {
        Mixpanel.track(MixpanelEventType.CHANGE_USER_PROFILE_SUCCESS);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        console.warn('error saving profile', error);
        Mixpanel.track(MixpanelEventType.CHANGE_USER_PROFILE_FAILED, errorProps(error));
      });
  }

  return (
    <form className="flex w-full flex-col gap-8 rounded bg-white" onSubmit={handleSubmit}>
      <h1 className="text-xl font-bold">Personal Information</h1>
      <ShowHideSection className={'flex flex-col gap-5'} sectionName={'First Name'}>
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <h1 className="font-semibold">Edit First Name</h1>
          <input type="text" name="first_name" className="" value={inputs.first_name} onChange={onChange} />
        </label>
      </ShowHideSection>
      <ShowHideSection className={'flex flex-col gap-5'} sectionName={'Last Name'}>
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <h1 className="font-semibold">Edit Last Name</h1>
          <input type="text" name="last_name" className="" value={inputs.last_name} onChange={onChange} />
        </label>
      </ShowHideSection>
      <ShowHideSection className={'flex flex-col gap-5'} sectionName={'Image'}>
        <div>
          <label className="flex w-full min-w-[15rem] flex-col items-center gap-3 rounded-xl border border-dashed border-gray-300  py-8 text-subtitle">
            <ArrowUpCircleIcon className="h-12 flex-shrink-0  rounded-full border-2 border-subtitle p-2" />
            <div className="text-lg">
              <span className="line-through">Drag and drop</span>
              {' or '}
              <span className="cursor-pointer text-primary hover:text-primary/80">browse</span> to choose a file
            </div>
            <input id="dropzone-file" type="file" className="hidden" name="photo" onChange={handleUpload} />
          </label>
          {photo == null ? null : (
            <div className="mt-4 w-full">
              <h2 className="mb-4 font-semibold text-primary">Uploaded files</h2>
              <div className="flex w-full items-center gap-3 rounded-lg bg-gray-100 px-4 py-2 text-xs">
                <img src={photoURL} alt="Profile Photo" className="h-12 w-12 flex-shrink-0 rounded object-cover" />
                <div className="flex flex-col gap-1 text-xs">
                  <span className="font-semibold">{photo ? photo.name : 'Current Profile Photo'}</span>
                  <span className="text-subtitle">{photo ? photo.size : 'Unknown Size'}</span>
                </div>
                <CheckIcon className="ml-auto h-6 text-green-500" />
              </div>
            </div>
          )}
        </div>
      </ShowHideSection>
      <ShowHideSection className={'flex flex-col gap-5'} sectionName={'Phone Number'}>
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <h1 className="font-semibold">Edit Phone Number</h1>
          <input
            type="tel"
            name="phone_number"
            className=""
            value={inputs.phone_number}
            minLength={10}
            maxLength={10}
            onChange={onChange}
          />
        </label>
      </ShowHideSection>
      <button type="submit" className="self-start rounded-xl bg-primary px-8 py-4 text-white">
        Save Changes
      </button>
    </form>
  );
}

export default UserSettings;
