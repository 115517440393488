import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BillingInfo from './billingInfo';
import BillingPayment from './billingPayment';
import clsx from 'clsx';
import { useAxios } from '../../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { AxiosError } from 'axios';
import { useAuth } from '../../contexts/user';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';

type PlanSku = 'basic' | 'starter'; // | 'premium';

interface Invoice {
  subtotal: string;
  tax: string;
  total: string;
}

interface Coupon {
  code: string;
  coupon_id: string;
  name: string;
  promo_id: string;
  valid: boolean;
}

export default function Billing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { axios } = useAxios();
  const auth = useAuth();
  const { plan, ...initialState } = location.state as { jobCount: number; monthly: boolean; plan: PlanSku };
  const [jobCount, setJobCount] = useState(initialState.jobCount || 1);
  const [infoComplete, setInfoComplete] = useState(false);
  const [isMonthly, setIsMonthly] = useState(initialState.monthly);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const couponRef = useRef<HTMLInputElement>(null);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const selectedPeriod = () => {
    if (isMonthly) {
      return 'month';
    } else {
      return 'year';
    }
  };

  const toggleInfo = () => {
    setInfoComplete(!infoComplete);
  };

  useEffect(() => {
    const invoiceObj = isMonthly
      ? {
          quantity: jobCount,
          coupon: coupon?.coupon_id,
        }
      : {
          plan_sku: plan,
          quantity: jobCount,
          annual: !isMonthly,
          coupon: coupon?.coupon_id,
        };

    axios.post<Invoice>('/recruiters/billing/estimates', invoiceObj).then((res) => {
      setInvoice(res.data);
    });
  }, [isMonthly, coupon, jobCount]);

  const completePayment = () => {
    const invoiceObj = isMonthly
      ? {
          plan_sku: plan,
          billing_period: 'monthly',
          promo_code: coupon?.promo_id,
        }
      : {
          plan_sku: plan,
          billing_period: 'annual',
          annual_quantity: jobCount,
          promo_code: coupon?.promo_id,
        };
    axios
      .put('/recruiters/billing', { organization: invoiceObj })
      .then(() => {
        axios
          .get('/recruiters/auth/userinfo')
          .then((resp) => {
            if (resp.data?.id) {
              auth.signin(resp.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        Mixpanel.track(MixpanelEventType.BILLING_PLAN_SUCCESS);
        navigate('/');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        console.error('error setting plan', err);
        Mixpanel.track(MixpanelEventType.BILLING_PLAN_FAILED, errorProps(err));
      });
  };

  const trialEndDate = () => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    return endDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const applyCouponCode = () => {
    const code = couponRef.current?.value;
    if (code) {
      axios
        .post<Coupon>('/recruiters/billing/coupons', {
          coupon: code,
        })
        .then((res) => {
          if (res.data.valid) {
            setCoupon(res.data);
            Mixpanel.track(MixpanelEventType.BILLING_COUPON_SUCCESS, { code });
          } else {
            setCoupon(null);
            Mixpanel.track(MixpanelEventType.BILLING_COUPON_FAILED, { code });
          }
        });
    }
  };

  return (
    <section>
      <div className="flex flex-col gap-10 lg:flex-row lg:items-start">
        <div className="flex flex-col gap-5">
          <div className="flex min-w-[29vw] flex-col rounded-xl bg-white p-12">
            <h2 className="mb-3 text-2xl font-medium">Order summary</h2>

            <div className="hidden flex-col items-center justify-between gap-5 border-b-2 border-subtitle/10 py-5 lg:flex 2xl:flex-row">
              <h3 className="text-2xl font-semibold ">Billing option</h3>
              <div className="flex whitespace-nowrap">
                <label
                  className={clsx(
                    'cursor-pointer rounded-l-xl border-r-0 px-4 py-2',
                    isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
                  )}
                >
                  Monthly
                  <input
                    className="hidden"
                    type="radio"
                    name="billingOption"
                    onClick={() => setIsMonthly(true)}
                    value="monthly"
                    defaultChecked={isMonthly}
                  />
                </label>
                <label
                  className={clsx(
                    'cursor-pointer rounded-r-xl border-l-0 px-4 py-2',
                    !isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
                  )}
                >
                  Annually <span className="text-xs">(20% off)</span>
                  <input
                    className="hidden"
                    type="radio"
                    name="billingOption"
                    onClick={() => setIsMonthly(false)}
                    value="annually"
                    defaultChecked={!isMonthly}
                  />
                </label>
              </div>
            </div>

            <div className="hidden items-center justify-between border-b-2 border-subtitle/10 py-5 lg:flex">
              <h3>Job Posts</h3>

              <div className="flex items-center gap-5 text-primary">
                <button
                  onClick={() => setJobCount((prev) => (prev -= 1))}
                  disabled={plan !== 'starter' || jobCount <= 1}
                >
                  <MinusIcon className="h-7 rounded border border-primary p-1" />
                </button>
                <span className="text-lg font-semibold">{jobCount}</span>
                <button onClick={() => setJobCount((prev) => (prev += 1))} disabled={plan !== 'starter'}>
                  <PlusIcon className="h-7 rounded border border-primary p-1" />
                </button>
              </div>
            </div>

            <div className="border-b-2 border-subtitle/10 py-5">
              <div className="flex justify-between">
                <div>Subtotal</div>
                <div>
                  ${invoice?.subtotal} / {selectedPeriod()}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Tax</div>
                <div>
                  {invoice?.tax && '$'}
                  {invoice?.tax || 'Not calculated'}
                </div>
              </div>
            </div>

            <div className="flex justify-between pt-10 text-lg font-medium">
              <div>Due Today</div>
              <div>$0.00 USD</div>
            </div>
          </div>

          <label className="hidden flex-col gap-3 px-3 lg:flex" htmlFor="couponCode">
            <span className="text-xl">Coupon code</span>
            <div className="flex gap-5">
              {coupon?.valid ? (
                <div className="flex items-center text-primary">
                  {coupon.name}
                  <XMarkIcon className="h-5 w-5 cursor-pointer" title="Remove coupon" onClick={() => setCoupon(null)} />
                </div>
              ) : (
                <>
                  <input id="couponCode" type="text" placeholder="Coupon Code" ref={couponRef} />
                  <button
                    type="button"
                    className="cursor-pointer text-lg font-semibold text-primary"
                    onClick={applyCouponCode}
                  >
                    Apply
                  </button>
                </>
              )}
            </div>
          </label>
        </div>

        <div className="flex flex-col gap-7  rounded-xl bg-white p-8">
          {!infoComplete && <BillingInfo complete={toggleInfo} />}
          {infoComplete && <BillingPayment complete={completePayment} back={toggleInfo} />}
          <p className="text-center text-sm text-subtitle lg:mr-20 lg:text-left">
            By clicking above, you agree to our{' '}
            <a href="https://www.joinprosper.ai/terms" target="blank" className="mb-1 text-primary hover:underline">
              Terms of Service,
            </a>
            <span className="text-primary"> Cookies Policy, </span>
            <a href="https://www.joinprosper.ai/privacy" target="blank" className="mb-1 text-primary hover:underline">
              Privacy Policy
            </a>
            , and <span className="font-semibold">automatic renewal</span>. After the trial ends on{' '}
            <>{trialEndDate()}</>, your subscription will begin. You authorize this site, appearing as Prosper AI, to
            charge ${invoice?.total} USD plus any tax each {selectedPeriod()} there after at the current rate until you
            cancel. Cancel any time in your account settings.
          </p>
        </div>
      </div>
    </section>
  );
}
