import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import Logo from './shared/images/Logo';
import { useAuth } from '../contexts/user';
import onboardingBackground from '../assets/onboardingBackground.jpg';

const stripePromise = loadStripe(
  'pk_test_51LqSRYJLv1pJKVphvt30OOn5iGB3hZq0rC9DSf6TE4rFNm3BN6pQqYxJfkuQDkcQrWysbjb1xoVmcWF7pUgx9i7s00HjSOIeee'
); // TODO: use envar for this once we are ready for prod use.

const stripeOptions: StripeElementsOptions = {
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito' }],
};

function Onboarding() {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user?.organization.status !== 'onboarding') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <img className="fixed inset-0 -z-[2] h-1/2 w-full object-cover object-top" src={onboardingBackground} alt="" />
      <div className="fixed inset-0 -z-[3] min-h-screen bg-lightblue" />
      <div className="flex min-h-screen flex-col gap-16 px-14 py-9">
        <Logo className="h-12 self-start text-white" />

        <section className="flex justify-center">
          <Outlet />
        </section>
      </div>
    </Elements>
  );
}

export default Onboarding;
