export type Option = {
  value: string;
  label: string;
};
/** Extracts the `value` property from an array of `Option` objects with an `as const` assertion.
 * @template T - A readonly array of `Option` objects with an `as const` assertion.
 *
 * @remarks The input array has be defined with an `as const` assertion to ensure
 * that the the type works properly.
 * If not then the type maybe `string` instead of a union of strings.
 *
 * @example const options = [
 *   { value: 'option1', label: 'Option 1' },
 *   { value: 'option2', label: 'Option 2' },
 * ] as const;
 * type OptionValues = ExtractOptionValues<typeof options>;
 *
 * OptionValues is now equal to 'option1' | 'option2'
 */
export type ExtractOptionValues<T extends readonly Option[]> = T[number]['value'];

export const employmentTypes = [
  { value: 'full_time', label: 'Full Time' },
  { value: 'part_time', label: 'Part Time' },
  { value: 'contract', label: 'Contract' },
  { value: 'temporary', label: 'Temporary' },
  { value: 'other', label: 'Other' },
] as const;
export type EmploymentType = ExtractOptionValues<typeof employmentTypes>;

export const degreeStatuses: Option[] = [
  { value: 'no_degree', label: 'No Degree' },
  { value: 'high_school', label: 'High School' },
  { value: 'some_college', label: 'Some College' },
  { value: 'associates', label: 'Associates' },
  { value: 'bachelors', label: 'Bachelors' },
  { value: 'masters', label: 'Masters' },
  { value: 'doctorate', label: "Doctorate - Ph'D" },
  { value: 'bootcamp', label: 'Bootcamp' },
];

export const employmentTypeLabel = (value?: string): string | undefined => {
  return employmentTypes.find((type) => type.value === value)?.label;
};

export const getOptionLabel = (options: Option[], value?: string): string | undefined => {
  return options.find((option) => option.value === value)?.label;
};
