import React from 'react';
import { Link } from 'react-router-dom';
import complete from '../assets/passwordResetComplete.svg';

function PasswordResetSuccessful() {
  return (
    <div className="flex h-screen w-screen flex-row items-center justify-center">
      <div className="flex h-screen w-9/12 items-center justify-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex items-center justify-center p-4">
            <img src={complete} alt="lock" />
          </div>
          <div className="flex items-center justify-center p-4">
            <h1 className="text-3xl font-black">Password reset</h1>
          </div>
          <div className="flex flex-col items-center justify-center p-4">
            <h1 className="flex text-center text-sm text-subtitle">Your password has been successfully reset.</h1>
            <h1 className="flex text-center text-sm text-subtitle">Please click the link below to log in.</h1>
          </div>
          <div className="flex w-full flex-col items-center justify-center p-4">
            <Link
              to="/login"
              className="btn btn-flex justify-center rounded-md border border-transparent bg-primary px-20 py-2 text-sm font-medium text-white shadow-sm"
            >
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetSuccessful;
