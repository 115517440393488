import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useAxios } from '../../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { JobContext } from './CreateJob';
import CreateJobInputSection from './CreateJobInputSection';

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export default function NewJobGeneral() {
  const [jobFields, setJobFields] = useState<{ id: string; name: string }[]>([]);
  const { job, setJob, saveJob, toNextPage } = useContext(JobContext);
  const { axios } = useAxios();

  useEffect(() => {
    axios.get('/recruiters/job_fields').then(
      (res) => {
        setJobFields(res.data);
        Mixpanel.track(MixpanelEventType.CREATE_JOB_FIELDS_LOADED);
      },
      (err: AxiosError<ErrorResponse>) => {
        console.log(err);
        Mixpanel.track(MixpanelEventType.CREATE_JOB_FIELDS_FAILED, errorProps(err));
      }
    );
  }, []);

  function handleChange(e: ChangeEvent<InputElement>): void {
    const value = e.target.value;
    setJob({
      ...job,
      [e.target.name]: value,
    });
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (job) {
      saveJob().then(() => toNextPage());
    }
  };

  return (
    <form id="general" className="flex flex-col gap-5 p-5" onSubmit={handleSubmit}>
      <h2 className="font-bold">General Information</h2>

      <CreateJobInputSection title="Job Title" htmlFor="jobTitle">
        <input
          id="jobTitle"
          type="text"
          name="title"
          value={job.title}
          placeholder="Job Title"
          onChange={handleChange}
          required
        />
      </CreateJobInputSection>

      <CreateJobInputSection title="Job Function" htmlFor="job_field_id">
        <select
          name="job_field_id"
          id="job_field_id"
          defaultValue=""
          value={job.job_field_id}
          required
          onChange={handleChange}
        >
          <option value="" disabled>
            Job Function
          </option>
          {jobFields.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </CreateJobInputSection>

      <CreateJobInputSection title="Job Location">
        <div className="flex justify-end gap-3">
          <label>
            <input
              type="radio"
              name="location_type"
              value="remote"
              onChange={handleChange}
              checked={job.location_type === 'remote'}
              className="peer hidden"
            />
            <div
              role="radio"
              className="flex cursor-pointer gap-2 whitespace-nowrap rounded-2xl border border-subtitle/30 px-4 py-2 font-normal peer-checked:bg-primary peer-checked:text-white"
            >
              Remote
            </div>
          </label>
          <label>
            <input
              type="radio"
              name="location_type"
              value="hybrid"
              onChange={handleChange}
              checked={job.location_type === 'hybrid'}
              className="peer hidden"
            />
            <div
              role="radio"
              className="flex cursor-pointer gap-2 whitespace-nowrap rounded-2xl border border-subtitle/30 px-4 py-2 font-normal peer-checked:bg-primary peer-checked:text-white"
            >
              Hybrid
            </div>
          </label>
          <label>
            <input
              type="radio"
              name="location_type"
              value="in_person"
              onChange={handleChange}
              checked={job.location_type === 'in_person'}
              className="peer hidden"
            />
            <div
              role="radio"
              className="flex cursor-pointer gap-2 whitespace-nowrap rounded-2xl border border-subtitle/30 px-4 py-2 font-normal peer-checked:bg-primary peer-checked:text-white"
            >
              In Person
            </div>
          </label>
        </div>

        {job.location_type && job.location_type !== 'remote' ? (
          <div className="flex flex-col justify-start pt-3 text-sm">
            <GooglePlacesAutocomplete
              apiKey="AIzaSyC-XQQJD5Dkikd-frOZZy1yABkWdqyl7rQ"
              autocompletionRequest={{
                types: ['locality'],
                componentRestrictions: {
                  country: 'us',
                },
              }}
              selectProps={{
                placeholder: 'City',
                value: job.location ? { label: job.location, value: job.location } : null,
                onChange: (val) => {
                  setJob({ ...job, location: val?.label });
                },
                required: true,
                name: 'location',
                classNames: {
                  input: () => 'select-input',
                },
              }}
            />
          </div>
        ) : null}
      </CreateJobInputSection>

      <CreateJobInputSection title="Job Description" htmlFor="description">
        {/* <p className="text-xs text-[#6B6B6B] ">Briefly describe a day in the life of the position</p> */}
        <textarea
          className="h-64"
          id="description"
          name="description"
          required
          value={job.description}
          onChange={handleChange}
        />
      </CreateJobInputSection>
    </form>
  );
}
