import axios, { AxiosInstance } from 'axios';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useAuth } from './user';

interface IAxiosContext {
  axios: AxiosInstance;
  loading: boolean;
}

export const AxiosContext = createContext<IAxiosContext>(null!);
AxiosContext.displayName = 'AxiosContext';

export function useAxios() {
  return useContext(AxiosContext);
}

export const AxiosInstanceProvider = ({ children }: { children: ReactNode }) => {
  const instanceRef = useRef(axios.create());
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const value = {
    axios: instanceRef.current,
    loading,
  };

  useEffect(() => {
    instanceRef.current.interceptors.response.use(
      (resp) => resp,
      (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 403) {
          const errMsg = error.response.data.error || '';
          if (/CSRF/.test(errMsg)) {
            return axios.request(error.config);
          }
        } else if (status === 401) {
          auth.signout();
          if (!location.pathname.startsWith('/confirm/') && !location.pathname.startsWith('/passwordreset/')) {
            navigate('/login');
          }
          return Promise.resolve(error.response);
        }
        return Promise.reject(error);
      }
    );

    instanceRef.current
      .get('/recruiters/auth/userinfo')
      .then((resp) => {
        if (resp.data?.id) auth.signin(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Loader className="absolute left-1/2 top-1/2 flex before:h-12 before:w-12" />
  ) : (
    <AxiosContext.Provider value={value}>{!loading && children}</AxiosContext.Provider>
  );
};
