import React, { useState } from 'react';
import PasswordResetNewPassword from './passwordResetNewPassword';
import PasswordResetSuccesful from './passwordResetSuccessful';
import FeaturesPanel from './featuresPanel';

function NewPassword() {
  const [completed, setCompleted] = useState(false);

  return (
    <div className="flex h-screen w-full flex-row">
      <FeaturesPanel className="hidden w-5/12 flex-1 lg:flex" />
      <div className="flex bg-lightblue lg:w-7/12">
        {completed ? <PasswordResetSuccesful /> : <PasswordResetNewPassword setCompleted={setCompleted} />}
      </div>
    </div>
  );
}

export default NewPassword;
