import React, { useState } from 'react';

export default function ShowHideSection({
  className,
  sectionName,
  children,
}: {
  className?: string;
  sectionName: string;
  children: JSX.Element;
}) {
  const [isHidden, setIsHidden] = useState(false);

  const toggleSection = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <h2 className="font-semibold">{sectionName}</h2>
        <button type="button" className="rounded-md bg-subtitle/20 px-2 py-1 font-semibold" onClick={toggleSection}>
          {!isHidden ? 'Hide' : 'Show'}
        </button>
      </div>
      {!isHidden ? children : null}
    </div>
  );
}
