import React from 'react';
import clsx from 'clsx';
import Gravatar from 'react-gravatar';
import { useAuth } from '../../contexts/user';

type Props = {
  className: string;
};

function UserAvatar({ className }: Props) {
  const { user } = useAuth();

  const photoUrl = (path: string): string => {
    return process.env.REACT_APP_API_BASE + path;
  };

  return user?.photo ? (
    <img className={clsx('rounded-full', className)} src={photoUrl(user.photo)} />
  ) : (
    <Gravatar email={user?.email} className={clsx('rounded-full bg-gray-200 text-gray-400', className)} />
  );
}

export default UserAvatar;
