import { AxiosInstance, AxiosResponse } from 'axios';
import { Industry } from '../models/industry';

export function GetIndustries(axios: AxiosInstance): Promise<AxiosResponse<Industry[]>> {
  return axios.get<Industry[]>('/recruiters/industries');
}

export function GetIndustry(axios: AxiosInstance, id: string): Promise<AxiosResponse<Industry>> {
  return axios.get<Industry>(`/recruiters/industries/${id}`);
}
