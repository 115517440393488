import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useAxios } from '../../contexts/axios';
import { AxiosError } from 'axios';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { Job } from '../../models/job';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { SetJobPostingStatus } from '../../services/job-postings';
import { GetIndustry } from '../../services/industries';
import { GetCompany } from '../../services/companies';
import { titleize } from '../../utils/string';
import { JobContext, stages } from './CreateJob';
import { InputAttributes, NumericFormatProps, numericFormatter } from 'react-number-format';
import { JobTraitChoice } from '../../models/job-trait-choice';
import clsx from 'clsx';

export default function JobReview() {
  const { axios } = useAxios();
  const { job } = useContext(JobContext);
  const [companyIndustry, setCompanyIndustry] = useState<string | null>(null);

  const numberFormatProps: NumericFormatProps<InputAttributes> = {
    decimalScale: 2,
    thousandSeparator: ',',
    allowNegative: false,
    prefix: '$',
  };
  const min = numericFormatter(job.min_compensation?.toString() || '', numberFormatProps);
  const max = numericFormatter(job.max_compensation?.toString() || '', numberFormatProps);
  // const compensation_text = `${min} ${max ? `- ${max}` : ''} ${titleize(job?.compensation_period)}`;
  const compensation_text = `${min} ${max ? `- ${max}` : ''} USD`;

  const navigate = useNavigate();

  useEffect(() => {
    GetCompany(axios).then((res) => {
      GetIndustry(axios, res.data.industry_id).then((res) => {
        setCompanyIndustry(res.data.name);
      });
    });
  }, [job]);

  const handleSubmit = (e: React.FormEvent, type: 'draft' | 'active') => {
    e.preventDefault();
    SetJobPostingStatus(axios, job as Job, type)
      .then(() => {
        Mixpanel.track(MixpanelEventType.JOB_REVIEW_STATUS_UPDATE_SUCCESS, { status: type });
        navigate('/jobs');
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        console.error('Error');
        Mixpanel.track(MixpanelEventType.JOB_REVIEW_STATUS_UPDATE_FAILED, errorProps(error));
      });
  };

  const Pill = ({ text }: { text: string }) => (
    <div className="rounded-xl border border-black px-3 py-1">{titleize(text)}</div>
  );

  const SkillPill = ({ skill }: { skill: JobTraitChoice }) => {
    return (
      <li
        key={skill.id}
        className={clsx(
          'flex items-center gap-3 rounded-xl border bg-opacity-10 p-1',
          skill.priority === 1 ? 'border-niceToHave bg-niceToHave' : 'border-mustHave bg-mustHave'
        )}
      >
        <div
          className={clsx(
            'grid h-6 w-6 shrink-0 place-items-center rounded-full text-xs text-white',
            skill.priority === 1 ? 'bg-niceToHave' : 'bg-mustHave'
          )}
        >
          {skill.experience}+
        </div>

        <span className={clsx(skill.priority === 1 ? 'text-niceToHave' : 'text-mustHave')}>{skill.name}</span>
      </li>
    );
  };

  const niceToHaveSkills = job.skills?.filter((skill) => skill.priority === 1);
  const mustHaveSkills = job.skills?.filter((skill) => skill.priority === 2);

  const sections: { title: string; items: { title: string; children: React.ReactNode }[] }[] = [
    {
      title: 'General Information',
      items: [
        {
          title: 'Job Title',
          children: job.title,
        },
        {
          title: 'Job Location',
          children: job.location_type === 'remote' ? 'Remote' : `${titleize(job.location_type)} - ${job.location}`,
        },
        {
          title: 'Job Description',
          children: job.description,
        },
      ],
    },
    {
      title: 'Education Preferences',
      items: [
        {
          title: 'Employment Type',
          children: titleize(job.employment_type),
        },
        {
          title: 'Education Level',
          children: titleize(job.education_level),
        },
        {
          title: 'Degree / Focus',
          children: (
            <>
              {job.majors?.length ? (
                <div className="flex flex-row flex-wrap gap-3">
                  {job.majors?.map(({ id, name }) => <Pill key={id} text={name} />)}
                </div>
              ) : (
                'None'
              )}
            </>
          ),
        },
      ],
    },
    {
      title: 'Skill Requirements',
      items: [
        {
          title: 'Nice to Have',
          children: niceToHaveSkills?.length ? (
            <div className="flex flex-row flex-wrap gap-3">
              {niceToHaveSkills?.map((skill) => <SkillPill key={skill.id} skill={skill} />)}
            </div>
          ) : (
            'None'
          ),
        },
        {
          title: 'Must Have',
          children: mustHaveSkills?.length ? (
            <div className="flex flex-row flex-wrap gap-3">
              {mustHaveSkills.map((skill) => (
                <SkillPill key={skill.id} skill={skill} />
              ))}
            </div>
          ) : (
            'None'
          ),
        },
      ],
    },
    {
      title: 'Compensation & Benefits',
      items: [
        {
          title: 'Compensation Type',
          children: titleize(job.compensation_period),
        },
        {
          title: 'Rate / Range',
          children: job.max_compensation ? 'Range' : 'Rate',
        },
        {
          title: 'Compensation',
          children: compensation_text,
        },
        {
          title: 'Benefits',
          children: (
            <>
              {job.benefits?.length ? (
                <div className="flex flex-row flex-wrap gap-3">
                  {job.benefits?.map((text) => <Pill key={text} text={text} />)}
                </div>
              ) : (
                'None'
              )}
            </>
          ),
        },
        {
          title: 'Supplemental Pay',
          children: (
            <>
              {job.supplemental_pay?.length ? (
                <div className="flex flex-row flex-wrap gap-3">
                  {job.supplemental_pay?.map((text) => <Pill key={text} text={text} />)}
                </div>
              ) : (
                'None'
              )}
            </>
          ),
        },
      ],
    },
    {
      title: 'Interview Settings',
      items: [
        {
          title: 'Interview Location',
          children: titleize(job.interview_location),
        },
        {
          title: 'Interview Duration',
          // TODO - CreateJobBackend: Update when backend is ready
          children: '' /* job.interview_duration */,
        },
        {
          title: 'Interviewers',
          // TODO - CreateJobBackend: Update when backend is ready
          children: '' /* job.interviewers */,
        },
      ],
    },
  ];

  return (
    <form id={stages[5].name} className="flex flex-col gap-5 p-5" onSubmit={(e) => handleSubmit(e, 'active')}>
      <h1 className="font-bold">Review and Confirm</h1>

      {sections.map((section, idx) => (
        <ReviewSection key={section.title} idx={idx} {...section} />
      ))}
    </form>
  );
}

const ReviewSection = ({
  title,
  items,
  idx,
}: {
  title: string;
  idx: number;
  items: { title: string; children: React.ReactNode }[];
}) => {
  const { setCurrentPageIdx } = useContext(JobContext);

  return (
    <div className="flex flex-col gap-5 border-b border-subtitle/25 pb-6 last:border-b-0">
      <div className="flex justify-between">
        <span className="text-sm">{title}</span>
        <button
          type="button"
          onClick={() => setCurrentPageIdx(idx)}
          className="flex gap-2 rounded-xl border border-subtitle/25 px-3 py-1 transition-colors hover:bg-subtitle/25"
        >
          <PencilSquareIcon className="h-4" />
          <span className="text-xs">Edit</span>
        </button>
      </div>
      <ul className="flex flex-col gap-3">
        {items.map(({ title, children }) => (
          <li key={title} className="grid grid-cols-[1fr_2fr] text-sm">
            <h4 className="text-subtitle">{title}</h4>
            {typeof children === 'string' ? <p className="">{children}</p> : children}
          </li>
        ))}
      </ul>
    </div>
  );
};
