import React from 'react';
import clsx from 'clsx';
import { JobTraitChoice } from '../models/job-trait-choice';

export function SkillPills({ skills }: { skills: JobTraitChoice[] }) {
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {skills
        .sort((s1, s2) => s2.priority - s1.priority)
        .map((skill) => (
          <div
            key={skill.id}
            className={clsx(
              'flex items-center gap-2 rounded-xl border px-2 py-1',
              skill.priority == 2 && 'border-mustHave bg-mustHave text-white',
              skill.priority == 1 && 'border-niceToHave bg-niceToHave text-white',
              skill.priority == 0 && 'border-subtitle bg-white text-subtitle'
            )}
          >
            <div
              className={clsx(
                'grid h-7 w-7 shrink-0 place-items-center rounded-full text-sm',
                skill.priority == 2 && 'bg-white text-mustHave',
                skill.priority == 1 && 'bg-white text-niceToHave',
                skill.priority == 0 && 'bg-subtitle text-white'
              )}
            >
              {skill.experience}
            </div>
            {skill.name}
          </div>
        ))}
    </div>
  );
}
