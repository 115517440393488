export function titleize(input?: string): string {
  if (!input) {
    return '';
  }

  return input
    .toLowerCase()
    .replace(/(?:^|\s|-|_)\S/g, (x) => x.toUpperCase())
    .replace(/-|_/g, ' ');
}

export function capitalize(input?: string): string {
  if (!input) {
    return '';
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}
