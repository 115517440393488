import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Popover, Switch } from '@headlessui/react';
import { BellIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useAxios } from '../contexts/axios';
import { Notification } from '../models/notification';
import { GetNotifications, SetRead, SetAllRead } from '../services/notifications';
import { format, formatDistance, formatRelative } from 'date-fns';
import { capitalize } from '../utils/string';
import { useNavigate } from 'react-router-dom';
import { groupBy } from 'lodash';

function NotificationRow({ n }: { n: Notification }) {
  const { axios } = useAxios();
  const navigate = useNavigate();
  const [note, setNote] = useState(n);
  function handleClick(n: Notification) {
    navigate(n.url);
    SetRead(axios, n).then((res) => setNote(res.data));
  }

  return (
    <div
      className="flex cursor-pointer flex-row gap-4 border-b px-8 py-4 text-black hover:bg-primary/10"
      onClick={() => {
        handleClick(note);
      }}
    >
      <EllipsisVerticalIcon className="h-6 w-6 rounded-full bg-yellow-500 text-white" />
      <div className="flex grow flex-col justify-center gap-2">
        <div className="flex flex-row items-center justify-between">
          <span className="text-black">New Type</span>
          {n.unread ? <div className="h-fit rounded-full bg-primary p-[0.25rem]"></div> : null}
        </div>
        <span className="text-subtitle">{n.message}</span>
        <span className="text-sm text-subtitle">
          {capitalize(formatDistance(new Date(n.created_at), new Date(), { addSuffix: true }))}
        </span>
      </div>
    </div>
  );
}

function Notifications() {
  const [notes, setNotes] = useState<Notification[]>([]);
  const [showUnread, setShowUnread] = useState(false);
  const [type, setType] = useState('');
  const { axios } = useAxios();

  useEffect(() => {
    GetNotifications(axios).then(
      (res) => {
        setNotes(res.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  function GroupByDate(n: Notification[]) {
    const dict = groupBy(n, (e) => format(new Date(e.created_at), 'yyyy/MM/dd'));
    return Array.from(Object.keys(dict), (k) => ({ date: k, notes: dict[k] }));
  }

  function GetNotes(n: Notification[], showUnread: boolean, num: number, type: string) {
    const notes = n
      .filter((e) => (type !== '' ? e.type === type : true) && (showUnread ? e.unread : true))
      .slice(0, num);
    return GroupByDate(notes);
  }
  function markAllRead() {
    SetAllRead(axios);
  }

  return (
    <Popover className="relative">
      <Popover.Button className="ml-4 rounded-lg bg-white/10 p-2 text-white hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2">
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </Popover.Button>
      <Popover.Panel className="absolute right-[-325%] top-[150%] flex min-w-fit flex-col overflow-clip rounded-md bg-white shadow-2xl lg:left-0">
        <div className="flex flex-row items-center justify-between gap-x-8">
          <div className="flex flex-row items-center gap-x-2 p-4">
            <span className="text-black">Notifications</span>
            {notes.filter((n) => n.unread).length > 0 ? (
              <div className="min-w-[1.5rem] items-center justify-center rounded-full bg-primary text-center text-white">
                {notes.filter((n) => n.unread).length}
              </div>
            ) : null}
          </div>
          <div className="flex flex-row items-center gap-x-2 p-4">
            <div className="flex whitespace-nowrap text-subtitle ">Only Show Unread</div>
            <Switch
              className={clsx(
                'flex h-fit min-w-[2rem] rounded-full ease-in-out',
                showUnread ? 'bg-primary' : 'bg-subtitle'
              )}
              onClick={() => setShowUnread(!showUnread)}
              checked={showUnread}
            >
              <div
                className={clsx(
                  'w-fit transform rounded-full border bg-white p-2 duration-200 ease-in-out',
                  showUnread ? 'translate-x-4' : 'translate-x-0'
                )}
              />
            </Switch>
            <button className="text-subtitle">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="flex flex-row">
          <button
            className={clsx('grow border-b px-4 py-2', type === '' ? 'border-primary text-primary' : 'text-subtitle')}
            onClick={() => setType('')}
          >
            All
          </button>
          <button
            className={clsx(
              'grow border-b px-4 py-2',
              type === 'candidates' ? 'border-primary text-primary' : 'text-subtitle'
            )}
            onClick={() => setType('candidates')}
          >
            Candidates
          </button>
          <button
            className={clsx(
              'grow border-b px-4 py-2',
              type === 'interviews' ? 'border-primary text-primary' : 'text-subtitle'
            )}
            onClick={() => setType('interviews')}
          >
            Interviews
          </button>
        </div>
        <div className="flex flex-row justify-center border-b px-4 py-2">
          <button className="text-primary" onClick={markAllRead}>
            Mark all as read {'\u2713'}
          </button>
        </div>
        {GetNotes(notes, showUnread, 5, type).length != 0 ? (
          GetNotes(notes, showUnread, 5, type).map((notesGroup) => {
            return (
              <div key={notesGroup.date}>
                {notesGroup.notes.length > 0 ? (
                  <div className="flex flex-row justify-between border-b px-4 py-2">
                    <div className="text-subtitle">{formatRelative(new Date(notesGroup.date), new Date())}</div>
                  </div>
                ) : null}
                {notesGroup.notes.map((e: Notification) => (
                  <div key={e.id}>
                    <NotificationRow n={e} />
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className="flex flex-row justify-center border-b p-8 text-subtitle">No Notifications</div>
        )}
        <button className="items-center justify-center p-2 text-subtitle">View All</button>
      </Popover.Panel>
    </Popover>
  );
}

export default Notifications;
