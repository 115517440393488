import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface IBadgeProps {
  color: 'blue' | 'green' | 'yellow' | 'orange' | 'red';
  children: ReactNode;
}

const colorClasses = {
  blue: 'text-primary bg-lightblue',
  green: 'text-pgreen bg-pgreen/10',
  yellow: 'text-yellow-500 bg-yellow-500/10',
  orange: 'text-porange bg-porange/10',
  red: 'text-red-500 bg-red-500/10',
};

function Badge({ color, children }: IBadgeProps) {
  return (
    <span className={clsx('inline-flex items-center rounded-md px-3 py-0.5 text-xs', colorClasses[color])}>
      {children}
    </span>
  );
}

export default Badge;
