import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Toast } from '../../../utils/toast';
import { useAxios } from '../../../contexts/axios';
import { Dialog } from '@headlessui/react';
import googleCalenderIcon from '../../../assets/google-calendar.svg';
import outlookCalenderIcon from '../../../assets/outlook-calendar.svg';
import clsx from 'clsx';
import { titleize } from '../../../utils/string';
interface ILinkToCalendarProps {
  closeModal?: () => void;
}
type Calenders = 'google' | 'outlook';

type Connection = {
  id: string;
  email: string;
  provider: string;
  uid: string;
};

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
const SCOPE = [
  'email',
  'profile',
  'https://www.googleapis.com/auth/calendar.events',
  'https://www.googleapis.com/auth/calendar.readonly',
].join(' ');

const LinkToCalenders: React.FC<ILinkToCalendarProps> = ({ closeModal }) => {
  const { axios } = useAxios();
  const [connectedCalenders, setConnectedCalenders] = useState<Partial<{ [key in Calenders]: Connection }>>();
  const setConnectedCalender = (calender: Calenders, state: Connection) => {
    setConnectedCalenders((prev) => ({ ...prev, [calender]: state }));
  };

  const getConnections = () => {
    axios
      .get<Connection[]>('/recruiters/account/connections')
      .then((res) => {
        console.log('Google Calender', res);
        res.data.forEach((connection) => {
          setConnectedCalender(connection.provider as Calenders, connection);
        });
      })
      .catch((error) => {
        console.error('Error connecting to Google Calender', error);
      });
  };

  useEffect(getConnections, []);

  const isModal = typeof closeModal !== 'undefined';

  const doCalenderSuccess = () => {
    Toast.success(`Your ${titleize(selectedCalender)} Calendar has been successfully synced in to your account.`);
    nextPanel();
  };
  const doCalenderError = () => {
    Toast.error(`There was an error connecting to your ${titleize(selectedCalender)} Calender. Please try again.`);
  };

  const client = google.accounts.oauth2.initCodeClient({
    client_id: CLIENT_ID,
    scope: SCOPE,
    ux_mode: 'popup',
    callback: (response) => {
      if (response.error) {
        console.error('Error connecting to Google Calender', response.error);
        doCalenderError();
        return;
      }

      axios
        .post('/recruiters/auth/google/callback', response, {
          headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XmlHttpRequest',
          },
        })
        .then((res) => {
          // Currently a success is a 201 that returns a user object
          console.log('Google Calender', res);
          getConnections();
          doCalenderSuccess();
        })
        .catch((error) => {
          console.error('Error connecting to Google Calender', error);
          doCalenderError();
        });
    },
    error_callback: (error) => {
      console.error('Error connecting to Google Calender', error);
      doCalenderError();
    },
  });

  const [panelIdx, setPanelIdx] = useState(0);
  const [selectedCalender, setSelectedCalender] = useState<Calenders>();

  const nextPanel = () => setPanelIdx((prev) => prev + 1);
  const prevPanel = () => setPanelIdx((prev) => prev - (connectedCalenders?.[selectedCalender as Calenders] ? 2 : 1));

  const selectCalender = (calender: Calenders) => {
    setSelectedCalender(calender);
    nextPanel();
    if (connectedCalenders?.[calender]) {
      nextPanel();
    }
  };

  const panels = [
    <>
      <div className="flex w-full items-center gap-5 rounded-lg border-2 border-subtitle/20 p-4">
        <img src={googleCalenderIcon} className="h-6 w-6" />

        <p className="font-bold">Google Calender</p>
        <button
          onClick={() => selectCalender('google')}
          className={clsx(
            'ml-auto rounded-md  px-4 py-2 text-white',
            connectedCalenders?.google ? 'bg-green-500' : 'bg-primary'
          )}
        >
          {connectedCalenders?.google ? 'Connected' : 'Connect'}
        </button>
      </div>
      <div className="flex w-full items-center gap-5 rounded-lg border-2 border-subtitle/20 p-4">
        <img src={outlookCalenderIcon} className="h-6 w-6" />
        <p className="font-bold">Outlook</p>
        <button
          onClick={() => selectCalender('outlook')}
          className={clsx(
            'ml-auto rounded-md  px-4 py-2 text-white',
            connectedCalenders?.outlook ? 'bg-green-500' : 'bg-primary'
          )}
        >
          {connectedCalenders?.outlook ? 'Connected' : 'Connect'}
        </button>
      </div>
    </>,
    <>
      <div className="w-full space-y-3">
        <h4 className="text-lg font-bold leading-6 text-gray-900">{titleize(selectedCalender)} Calender</h4>
        <p className="text-subtitle">
          All your interviews will be instantly added to {titleize(selectedCalender)} Calendar.
        </p>
        <br />
        <p>Connect to {titleize(selectedCalender)} Calender</p>
        <p className="text-sm">
          To get started, allow access to your {titleize(selectedCalender)} Calendar account. You will be taken to
          {titleize(selectedCalender)} Calendar to sign in and grant access.
        </p>
        <button
          onClick={() => {
            console.clear();
            switch (selectedCalender) {
              case 'google':
                client.requestCode();
                break;
              case 'outlook':
                Toast.info('Outlook Calender is not yet supported');
                break;
            }
          }}
          className="ml-auto rounded-md bg-primary px-4 py-2 text-sm
         text-white"
        >
          Connect to {titleize(selectedCalender)} Calender
        </button>
      </div>
    </>,
    <>
      <div className="space-y-5">
        <h4 className="font-bold leading-6 text-gray-900">{titleize(selectedCalender)} Calender</h4>
        <p className="text-gray-500">
          All your interviews will be instantly added to {titleize(selectedCalender)} Calendar.
        </p>
        <br />
        <p className="text-xs text-subtitle">Connected to andrew@joinprosper.com</p>
        <div className="grid grid-cols-[1fr_3fr] items-center gap-3 rounded-lg border-2 border-subtitle/20 p-4">
          <h5 className="font-semibold">Prosper AI</h5>
          <p className="text-sm">Company name</p>
          <p className="col-span-2 text-sm text-subtitle">synced with</p>
          <label className="text-lg font-semibold" htmlFor="calenderSync">
            {titleize(selectedCalender)} Calender
          </label>

          <select
            name="calenderSync"
            id="calenderSync"
            placeholder={`Select the ${titleize(selectedCalender)} calendar to sync`}
          ></select>
        </div>
        <button className="text-xs text-red-600" type="button">
          Disconnect {titleize(selectedCalender)} Calendar account
        </button>
      </div>
    </>,
  ] as const;

  return (
    <>
      <div className="flex items-center justify-between">
        {isModal ? (
          <Dialog.Title as="h3" className="mb-5 text-lg font-bold leading-6 text-gray-900">
            {panelIdx > 0 && (
              <ChevronLeftIcon role="button" className="inline h-6 w-6 text-subtitle" onClick={prevPanel} />
            )}
            Sync to Prosper AI
          </Dialog.Title>
        ) : (
          <h3 className="mb-5 text-lg font-bold leading-6 text-gray-900">
            {panelIdx > 0 && (
              <ChevronLeftIcon role="button" className="inline h-6 w-6 text-subtitle" onClick={prevPanel} />
            )}
            Sync to Prosper AI
          </h3>
        )}
        {/*  */}
        {isModal && <XMarkIcon role="button" className="h-6 w-6 text-subtitle" onClick={closeModal} />}
      </div>
      <div className="mt-4 flex flex-col gap-5">{panels[panelIdx]}</div>
    </>
  );
};

export default LinkToCalenders;
