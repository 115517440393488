import React, { ReactElement } from 'react';

import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ShieldCheckIcon, CheckBadgeIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

type HelpDeskItem = {
  name: string;
  description: string;
  icon: ReactElement;
  panel: ReactElement;
};

const helpDeskItems: HelpDeskItem[] = [
  {
    name: 'What is Prosper AI',
    description: 'Here for the first time? See how Prosper AI can get you top notch candidates!',
    icon: <CheckBadgeIcon className="h-14 flex-shrink-0 rounded-full bg-primary/10 p-3 text-primary" />,
    panel: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus error ipsa reiciendis dolores. Pariatur
        voluptate a quas quia distinctio dolore sequi ab, possimus et iusto totam illo, eos, illum doloremque natus
        ullam enim nobis? Aut qui quaerat, quae molestias hic maiores? Maiores ut molestias fugiat.
      </p>
    ),
  },
  {
    name: 'Terms & Conditions',
    description: 'View our terms and conditions. You know, just the legal stuff.',
    icon: <ShieldCheckIcon className="h-14 flex-shrink-0 rounded-full bg-primary/10 p-3 text-primary" />,
    panel: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus error ipsa reiciendis dolores. Pariatur Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Ducimus error ipsa reiciendis dolores. Pariatur
      </p>
    ),
  },
  {
    name: 'Contact us!',
    description: 'Need help? Reach out to support@prosperjobs.co',
    icon: <EnvelopeIcon className="h-14 flex-shrink-0 rounded-full bg-primary/10 p-3 text-primary" />,
    panel: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus error ipsa reiciendis dolores. Pariatur Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Ducimus error ipsa reiciendis dolores. Pariatur
      </p>
    ),
  },
];

export default function HelpDesk() {
  return (
    <div>
      <h1 className="text-xl font-bold">Help Desk</h1>

      {helpDeskItems.map((item: HelpDeskItem, idx) => (
        <Disclosure key={item.name}>
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={clsx(
                  'flex cursor-pointer items-center gap-7 py-8',
                  idx != 0 && 'border-t border-subtitle/10'
                )}
              >
                {item.icon}
                <div className="flex flex-col gap-2">
                  <h2 className="text-lg font-medium">{item.name}</h2>
                  {/* Font doesn't go any thinner */}
                  <p className="font-thin text-subtitle">{item.description}</p>
                </div>
                <ChevronRightIcon
                  className={clsx('ml-auto inline h-8 fill-subtitle/20 transition-all', open && 'rotate-90')}
                />
              </Disclosure.Button>

              <Disclosure.Panel className="mb-4">{item.panel}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
