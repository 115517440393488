import mixpanel, { Dict } from 'mixpanel-browser';
mixpanel.init('0d16ad59ae3c4c3dbc20b66834e53392', {
  cross_subdomain_cookie: false,
});

const prod = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id: string) => {
    if (prod) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (prod) mixpanel.alias(id);
  },
  track: (name: MixpanelEventType, props: Dict = {}) => {
    if (prod) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (prod) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (prod) mixpanel.reset();
  },
};

export const Mixpanel = actions;

export enum MixpanelEventType {
  // Signup
  SIGNUP_SUCCESS = 'Signup.Success',
  SIGNUP_FAILED = 'Signup.Failed',

  // Login
  LOGIN_SUCCESS = 'Login.Success',
  LOGIN_FAILED = 'Login.Failed',

  // Confirm Email
  CONFIRM_EMAIL_SUCCESS = 'ConfirmEmail.Success',
  CONFIRM_EMAIL_INVALID = 'ConfirmEmail.Invalid',

  // Create Company Profile
  CREATE_COMPANY_PROFILE_SUCCESS = 'CreateCompanyProfile.Success',
  CREATE_COMPANY_PROFILE_FAILED = 'CreateCompanyProfile.Failed',
  CREATE_COMPANY_PROFILE_LOGO_SUCCESS = 'CreateCompanyProfile.Logo.Success',
  CREATE_COMPANY_PROFILE_LOGO_FAILED = 'CreateCompanyProfile.Logo.Failed',
  CREATE_COMPANY_PROFILE_INDUSTRIES_LOADED = 'CreateCompanyProfile.Industries.Loaded',
  CREATE_COMPANY_PROFILE_INDUSTRIES_FAILED = 'CreateCompanyProfile.Industries.Failed',

  // View Company Profile
  VIEW_COMPANY_LOADED = 'ViewCompany.Loaded',
  VIEW_COMPANY_FAILED = 'ViewCompany.Failed',
  VIEW_COMPANY_INDUSTRIES_LOADED = 'ViewCompany.Industries.Loaded',
  VIEW_COMPANY_INDUSTRIES_FAILED = 'ViewCompany.Industries.Failed',

  // Create User Profile
  CREATE_USER_PROFILE_SUCCESS = 'CreateUserProfile.Success',
  CREATE_USER_PROFILE_FAILED = 'CreateUserProfile.Failed',
  CREATE_USER_PROFILE_PHOTO_SUCCESS = 'CreateUserProfile.Photo.Success',
  CREATE_USER_PROFILE_PHOTO_FAILED = 'CreateUserProfile.Photo.Failed',
  CHANGE_USER_PROFILE_SUCCESS = 'ChangeUserProfile.Success',
  CHANGE_USER_PROFILE_FAILED = 'ChangeUserProfile.Failed',

  // Billing
  BILLING_INFO_SUCCESS = 'Billing.Info.Success',
  BILLING_INFO_FAILED = 'Billing.Info.Failed',
  BILLING_PLAN_SUCCESS = 'Billing.Plan.Success',
  BILLING_PLAN_FAILED = 'Billing.Plan.Failed',
  BILLING_PAYMENT_SUCCESS = 'Billing.Payment.Success',
  BILLING_PAYMENT_FAILED = 'Billing.Payment.Failed',
  BILLING_STRIPE_PAYMENT_SUCCESS = 'Billing.StripePayment.Success',
  BILLING_STRIPE_PAYMENT_FAILED = 'Billing.StripePayment.Failed',
  BILLING_COUPON_SUCCESS = 'Billing.Coupon.Success',
  BILLING_COUPON_FAILED = 'Billing.Coupon.Failed',

  // Forgot Password
  FORGOT_PASSWORD_SUBMIT_SUCCESS = 'ForgotPasswordSubmit.Success',
  FORGOT_PASSWORD_SUBMIT_FAILED = 'ForgotPasswordSubmit.Failed',

  // New Password
  NEW_PASSWORD_SUCCESS = 'NewPassword.Success',
  NEW_PASSWORD_FAILED = 'NewPassword.Failed',

  // Settings - Company
  SETTINGS_COMPANY_LOADED = 'Settings.Company.Loaded',
  SETTINGS_COMPANY_FAILED = 'Settings.Company.Failed',
  SETTINGS_COMPANY_INDUSTRIES_LOADED = 'Settings.Company.Industries.Loaded',
  SETTINGS_COMPANY_INDUSTRIES_FAILED = 'Settings.Company.Industries.Failed',
  SETTINGS_COMPANY_CHANGE_SUCCESS = 'Settings.Company.Change.Success',
  SETTINGS_COMPANY_CHANGE_FAILED = 'Settings.Company.Change.Failed',
  SETTINGS_COMPANY_CHANGE_LOGO_SUCCESS = 'Settings.Company.ChangeLogo.Success',
  SETTINGS_COMPANY_CHANGE_LOGO_FAILED = 'Settings.Company.ChangeLogo.Failed',

  // Settings - Account
  SETTINGS_ACCOUNT_CHANGE_EMAIL_SUCCESS = 'Settings.Account.ChangeEmail.Success',
  SETTINGS_ACCOUNT_CHANGE_EMAIL_FAIL = 'Settings.Account.ChangeEmail.Fail',
  SETTINGS_ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'Settings.Account.ChangePassword.Success',
  SETTINGS_ACCOUNT_CHANGE_PASSWORD_FAIL = 'Settings.Account.ChangePassword.Fail',
  SETTINGS_ACCOUNT_DELETE_SUCCESS = 'Settings.Account.Delete.Success',
  SETTINGS_ACCOUNT_DELETE_FAIL = 'Settings.Account.Delete.Fail',

  // Settings - Billing
  SETTING_BILLING_PAYMENT_METHODS_LOADED = 'Settings.Billing.PaymentMethods.Loaded',
  SETTING_BILLING_PAYMENT_METHODS_FAILED = 'Settings.Billing.PaymentMethods.Failed',

  SETTING_BILLING_ADD_PAYMENT_METHOD_SUCCESS = 'Settings.Billing.AddPaymentMethod.Success',
  SETTING_BILLING_ADD_PAYMENT_METHOD_FAILED = 'Settings.Billing.AddPaymentMethod.Failed',
  SETTING_BILLING_DELETE_PAYMENT_METHOD_SUCCESS = 'Settings.Billing.DeletePaymentMethod.Success',
  SETTING_BILLING_DELETE_PAYMENT_METHOD_FAILED = 'Settings.Billing.DeletePaymentMethod.Failed',
  SETTING_BILLING_SET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'Settings.Billing.SetDefaultPaymentMethod.Success',
  SETTING_BILLING_SET_DEFAULT_PAYMENT_METHOD_FAILED = 'Settings.Billing.SetDefaultPaymentMethod.Failed',

  SETTING_BILLING_INVOICES_LOADED = 'Settings.Billing.Invoices.Loaded',
  SETTING_BILLING_INVOICES_FAILED = 'Settings.Billing.Invoices.Failed',

  // Create Job
  CREATE_JOB_FIELDS_LOADED = 'CreateJob.JobFields.Loaded',
  CREATE_JOB_FIELDS_FAILED = 'CreateJob.JobFields.Failed',
  CREATE_JOB_SAVE_CREATE_SUCCESS = 'CreateJob.Save.Create.Success',
  CREATE_JOB_SAVE_CREATE_FAILED = 'CreateJob.Save.Create.Failed',
  CREATE_JOB_SAVE_UPDATE_SUCCESS = 'CreateJob.Save.Update.Success',
  CREATE_JOB_SAVE_UPDATE_FAILED = 'CreateJob.Save.Update.Failed',

  // Job Review
  JOB_REVIEW_STATUS_UPDATE_SUCCESS = 'JobReview.Status.Update.Success',
  JOB_REVIEW_STATUS_UPDATE_FAILED = 'JobReview.Status.Update.Failed',

  // Job Details
  JOB_DETAILS_DELETE_SUCCESS = 'JobDetails.Delete.Success',
  JOB_DETAILS_DELETE_FAILED = 'JobDetails.Delete.Failed',

  // Job Matches
  JOB_MATCHES_LOADED = 'JobMatches.Loaded',
  JOB_MATCHES_FAILED = 'JobMatches.Failed',

  LOCATION_CHANGE = 'Location.Change',
}
