import React, { ChangeEvent, FormEvent, useState } from 'react';
import Logo from './shared/images/Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { useAuth } from '../contexts/user';
import FeaturesPanel from './featuresPanel';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { errorProps, ErrorResponse } from '../utils/errors';
import { AxiosError } from 'axios';

interface ILoginForm {
  email?: string;
  password?: string;
  remember_me?: string;
}

function Login() {
  const [inputs, setInputs] = useState<ILoginForm>({});
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { axios } = useAxios();

  const from = location.state?.from?.pathName || '/';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    axios
      .post('/recruiters/sign_in', {
        recruiter: {
          email: inputs.email,
          password: inputs.password,
          remember_me: inputs.remember_me,
        },
      })
      .then((resp) => {
        auth.signin(resp.data);
        Mixpanel.track(MixpanelEventType.LOGIN_SUCCESS);
        navigate(from, { replace: true });
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.LOGIN_FAILED, errorProps(err));
      });
  };

  return (
    <div className="flex h-screen">
      <FeaturesPanel className="hidden w-0 flex-1 lg:flex" />

      {/** Showcase and form split  */}
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          {/* header */}
          <div>
            {/** Logo */}
            <Logo className="h-10 text-primary md:invisible" />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in</h2>
            <p className="mt-2 text-sm text-gray-600">
              New user?&nbsp;
              <Link className="font-medium text-primary no-underline hover:underline" to="/signup">
                Create an account
              </Link>
            </p>
          </div>
          <div className="mt-8">
            {' '}
            {/* password form */}
            <div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="user_email">Company Email Address</label>
                  <input
                    id="user_email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="space-y-1">
                  <label htmlFor="user_password">Password</label>
                  <input
                    id="user_password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="user_remember_me"
                      name="user[remember_me]"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      onChange={handleChange}
                    />
                    <label htmlFor="user_remember_me" className="ml-2 block text-sm text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link to="/passwordreset" className="font-medium text-primary">
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary w-full">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">or</span>
              </div>
            </div>
          </div>

          {/** Oauth buttons: Google & Facebook Container */}
          <div className="mt-8">
            <div className="flex md:flex-row">
              {/** Google Button */}
              <button className="flex w-full justify-center rounded-md border border-gray-300 bg-white px-3 py-[.65rem] text-sm font-medium text-black shadow-sm">
                <svg
                  className="my-[.1rem] mr-2 h-4 w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.527 8.54751C16.527 8.00539 16.4822 7.46034 16.3865 6.927H8.70947V9.99806H13.1057C12.9233 10.9885 12.3371 11.8647 11.4788 12.4215V14.4142H14.1016C15.6418 13.0252 16.527 10.9739 16.527 8.54751Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M8.70931 16.3395C10.9044 16.3395 12.7557 15.6332 14.1044 14.4142L11.4816 12.4215C10.7519 12.908 9.80987 13.1834 8.7123 13.1834C6.58893 13.1834 4.78856 11.7798 4.14258 9.89258H1.43604V11.9468C2.81772 14.6398 5.63192 16.3395 8.70931 16.3395Z"
                    fill="#34A853"
                  />
                  <path
                    d="M4.13966 9.8926C3.79872 8.90213 3.79872 7.8296 4.13966 6.83912V4.78491H1.43611C0.281714 7.03839 0.281714 9.69334 1.43611 11.9468L4.13966 9.8926Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M8.70931 3.54538C9.86968 3.52779 10.9912 3.95563 11.8315 4.74098L14.1553 2.46406C12.6839 1.11021 10.731 0.365888 8.70931 0.389331C5.63192 0.389331 2.81772 2.08897 1.43604 4.78494L4.13959 6.83915C4.78258 4.94904 6.58594 3.54538 8.70931 3.54538Z"
                    fill="#EA4335"
                  />
                </svg>
                Google
              </button>

              {/** Facebook Button */}
              <button className="ml-5 flex w-full justify-center rounded-md border border-gray-300 bg-white px-3 py-[.65rem] text-sm font-medium text-black shadow-sm">
                <svg
                  className="my-[.1rem] mr-2 h-4 w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.53684" cy="8.35483" r="7.99485" fill="#1977F3" />
                  <path
                    d="M11.6489 10.6664L12.0031 8.35486H9.78598V6.85506C9.78598 6.2232 10.0952 5.60597 11.089 5.60597H12.0975V3.63845C12.0975 3.63845 11.1823 3.48218 10.3076 3.48218C8.4818 3.48218 7.2878 4.58848 7.2878 6.5931V8.35486H5.25732V10.6664H7.2878V16.253C7.69479 16.3171 8.1119 16.3497 8.53689 16.3497C8.96187 16.3497 9.37898 16.316 9.78598 16.253V10.6664H11.6489Z"
                    fill="white"
                  />
                </svg>
                Facebook
              </button>
            </div>
          </div>

          {/** Privacy Policy & Terms Of Service fine print */}
          <div className="mt-6">
            <p className="text-sm text-gray-600">
              Protected by reCAPTCHA and subject to the Prosper{' '}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://www.joinprosper.ai/privacy"
                target="_blank"
                className="text-primary transition-all hover:opacity-70"
              >
                Prosper Privacy Policy
              </a>{' '}
              and
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://www.joinprosper.ai/terms"
                target="_blank"
                className="text-primary transition-all hover:opacity-70"
              >
                Terms of Service
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
