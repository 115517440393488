import React, { useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Combobox } from '@headlessui/react';
import clsx from 'clsx';
import type { Updater } from 'use-immer';
import type { Option } from '../../models/options';

const TagInput = ({
  options,
  selectedOptionsState,
  placeholder,
  labelText,
  onChoiceChange,
}: {
  options: Option[];
  selectedOptionsState: [Option[], Updater<Option[]>];
  placeholder: string;
  labelText: string;
  onChoiceChange?: (choice: Option, newSelectedOptions: Option[]) => void;
}) => {
  const [selectedChoice, setSelectedChoice] = useState<Option>();
  const [selectedOptions, setSelectedOptions] = selectedOptionsState;
  const [query, setQuery] = useState(selectedChoice?.value || '');
  const filteredOptions =
    query === ''
      ? options.filter((item) => !selectedOptions.some(({ value }) => value === item.value))
      : options.filter(
          (item) =>
            item.label.toLowerCase().includes(query.toLowerCase()) &&
            !selectedOptions.some(({ value }) => value === item.value)
        );
  return (
    <Combobox
      as={'label'}
      className="relative"
      value={selectedChoice}
      onChange={(a: Option) => {
        setSelectedChoice(a);
        setSelectedOptions((draft) => {
          !draft.some(({ value }) => value === a.value) && draft.push(a);
        });
        const newSelectedOptions = !selectedOptions.some(({ value }) => value === a.value)
          ? [...selectedOptions, a]
          : selectedOptions;
        if (typeof onChoiceChange !== 'undefined') onChoiceChange(a, newSelectedOptions);
      }}
    >
      {labelText}
      <Combobox.Input
        className="mt-2"
        type="text"
        placeholder={placeholder}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Combobox.Options className=" absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {filteredOptions.length !== 0 ? (
          filteredOptions.map((choice: Option) => (
            <Combobox.Option
              key={choice.value}
              value={choice}
              className={({ active }) =>
                clsx(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-primary text-white' : 'text-gray-900'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span className={clsx('block truncate', selected && 'font-semibold')}>{choice.label}</span>
                  {selected && (
                    <span
                      className={clsx(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-primary'
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))
        ) : (
          <div className="px-3 py-2">
            No results found {/* ({selectedOptions.length} / {options.length} options used) */}
          </div>
        )}
      </Combobox.Options>
      <div className="mt-2 flex flex-wrap gap-3">
        {selectedOptions.map(({ label, value }) => (
          <div className="flex items-center gap-1 rounded-xl bg-primary px-2 py-1 text-white" key={value}>
            <span>{label}</span>
            <button
              type="button"
              onClick={() => {
                const item = { label, value };
                setSelectedOptions((draft) => {
                  return draft.filter((c) => c.value !== value);
                });
                const newSelectedOptions = selectedOptions.filter((c) => c.value !== value);
                if (typeof onChoiceChange !== 'undefined') onChoiceChange(item, newSelectedOptions);
              }}
            >
              <XMarkIcon className="h-4" />
            </button>
          </div>
        ))}
      </div>
    </Combobox>
  );
};

export default TagInput;
