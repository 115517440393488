import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Job } from '../../models/job';
import { EyeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { titleize } from '../../utils/string';
import clsx from 'clsx';
import { CurrencyDollarIcon } from '@heroicons/react/20/solid';
import { useAxios } from '../../contexts/axios';
import { GetCompany } from '../../services/companies';
import { Company } from '../../models/company';
import { benefits } from '../../utils/benefit';

interface ICompany extends Omit<Company, 'industry_id'> {
  industry: {
    id: string;
    name: string;
  };
}

function getHeadcount(input: string) {
  switch (input) {
    case 'unknown':
      return 'unknown';
    case 'ten':
      return '1-10';
    case 'fifty':
      return '11-50';
    case 'two_hundred':
      return '51-200';
    case 'five_hundred':
      return '201-500';
    case 'one_thousand':
      return '501-1,000';
    case 'five_thousand':
      return '1,001-5,000';
    case 'ten_thousand':
      return '5,000-10,000';
    case 'max':
      return '10,000+';
    default:
      return '';
  }
}

const BenefitCards = ({
  benefit: { icon: Icon, text },
}: {
  benefit: {
    icon: React.ElementType;
    title: string;
    text: string;
  };
}) => (
  <span className="rounded-xl bg-subtitle/10 px-2 py-1">
    <Icon className="inline-block h-8 text-primary" /> {text}
  </span>
);

export default function MobilePreview({ job, isDropDown }: { job: Partial<Job>; isDropDown?: boolean }) {
  const { axios } = useAxios();
  const [companyInfo, setCompanyInfo] = useState<ICompany>({
    description: '',
    headcount: '',
    headquarters: '',
    founded: 0,
    website: '',
    industry: {
      id: '',
      name: '',
    },
    logo: '',
    name: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { industry_id, ...company },
        } = await GetCompany(axios);

        const {
          data: { name: industry_name },
        } = await axios.get<ICompany['industry']>(`/recruiters/industries/${industry_id}`);

        setCompanyInfo({
          ...company,
          logo: process.env.REACT_APP_API_BASE + company.logo,
          industry: { id: industry_id, name: industry_name },
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const [isShowingFront, setIsShowingFront] = useState(true);

  const [isJobDetails, setIsJobDetails] = useState(true);

  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
  });
  const min = formatter.format(job.min_compensation || 0);
  const max = formatter.format(job.max_compensation || 0);
  const compensation_text = `$${min} ${max && max !== '0' ? `- $${max}` : ''} ${titleize(job?.compensation_period)}`;

  return (
    <Popover>
      {({ open }) => (
        <>
          {/* Backdrop */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-10 bg-black/25" />
          </Transition.Child>
          {/* Preview Button */}
          {isDropDown ? (
            <Popover.Button className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-gray-100">
              <EyeIcon className="mr-2 h-5 w-5" />
              Preview
            </Popover.Button>
          ) : (
            <Popover.Button
              hidden={open}
              className="rounded-lg border border-primary px-10 py-1 font-semibold text-primary hover:bg-primary hover:text-white"
            >
              Preview
            </Popover.Button>
          )}

          {/* Preview */}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 translate-x-9"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-9"
          >
            <Popover.Panel className="fixed right-4 top-24 z-10  h-[calc(100vh-6rem-1rem)] max-h-[56rem] w-full max-w-md rounded-lg bg-white">
              <Popover.Button className="absolute right-0 p-3">
                <XMarkIcon className="h-8 w-8" />
              </Popover.Button>

              <div className="flex h-full flex-col">
                {/* Header */}
                <div className="flex flex-col p-6 pb-4">
                  <h1 className="text-lg font-semibold">Mobile Preview</h1>
                  <h3 className="text-sm font-normal text-subtitle">This is what a Candidate will see</h3>
                </div>

                {/* Mobile Preview */}
                <section className="flex grow flex-col overflow-y-scroll">
                  {/* Mobile Preview Header */}
                  <div className="sticky top-0 z-10 flex items-center gap-6 bg-primary p-8">
                    <div className="h-24 w-24 rounded-2xl bg-white" />

                    <div className="flex flex-col justify-between gap-1">
                      <h1 className="text-xl font-semibold text-white">{job.title || 'Undefined'}</h1>

                      <h3 className="text-sm font-normal text-white">
                        {job.company || 'Undefined'} • {companyInfo.industry.name || 'Undefined'}
                      </h3>

                      <div className="self-start rounded-full bg-green-600 p-4 py-1 text-white">92% Match!</div>
                    </div>
                  </div>

                  {/* Front & Back */}
                  {isShowingFront ? (
                    // * Front
                    <div className="flex h-full flex-col items-start gap-2 px-8 py-5">
                      <h2 className="text-xl">Compensation</h2>

                      <span className="rounded-xl bg-subtitle/10 px-2 py-1">
                        <CurrencyDollarIcon className="inline-block h-8 text-primary" /> {compensation_text}
                      </span>

                      <h2 className="text-xl">Benefits</h2>

                      <div className="flex flex-wrap gap-3">
                        {job.benefits?.map((benefit) => <BenefitCards key={benefit} benefit={benefits[benefit]} />)}
                      </div>

                      <h2 className="text-xl">Description</h2>

                      <p className="-mt-1">{job.description || 'Undefined'}</p>
                    </div>
                  ) : (
                    // * Back
                    <div>
                      <nav className="relative flex w-full justify-evenly after:absolute after:bottom-0 after:h-px after:w-full after:bg-subtitle/10">
                        <button
                          type="button"
                          onClick={() => setIsJobDetails(true)}
                          className={clsx('border-b p-3', isJobDetails ? 'border-primary' : 'border-transparent')}
                        >
                          Job Details
                        </button>
                        <button
                          type="button"
                          onClick={() => setIsJobDetails(false)}
                          className={clsx('border-b p-3', !isJobDetails ? 'border-primary' : 'border-transparent')}
                        >
                          Company Details
                        </button>
                      </nav>
                      {isJobDetails ? (
                        <div className="flex h-full flex-col items-start gap-2 px-8 py-5">
                          <h2 className="text-xl">Compensation</h2>

                          <span className="rounded-xl bg-subtitle/10 px-2 py-1">{compensation_text}</span>

                          <h2 className="text-xl">Benefits</h2>

                          <div className="flex flex-wrap gap-3">
                            {job.benefits?.map((benefit) => <BenefitCards key={benefit} benefit={benefits[benefit]} />)}
                          </div>

                          <h2 className="text-xl">Description</h2>
                          <p className="-mt-1">{job.description || 'Undefined'}</p>

                          <h2 className="text-xl">Responsibilities</h2>
                          <p className="-mt-1">{job.description || 'Undefined'}</p>

                          <h2 className="text-xl">Skills</h2>

                          {!job.skills && <h3>No Skills</h3>}

                          {job.skills?.some((skill) => skill.priority === 2) && <h3>Must Have</h3>}
                          <div className="flex flex-wrap gap-3">
                            {job.skills
                              ?.filter((skill) => skill.priority === 2)
                              .map((skill) => (
                                <div
                                  key={skill.id}
                                  className="flex items-center gap-2 rounded-xl border border-mustHave px-2 py-1 text-mustHave"
                                >
                                  <div className="grid h-7 w-7 shrink-0 place-items-center rounded-full bg-mustHave text-sm text-white">
                                    {skill.experience}+
                                  </div>
                                  {skill.name}
                                </div>
                              ))}
                          </div>

                          {job.skills?.some((skill) => skill.priority === 1) && <h3>Nice to Have</h3>}
                          <div className="flex flex-wrap gap-3">
                            {job.skills
                              ?.filter((skill) => skill.priority === 1)
                              .map((skill) => (
                                <div
                                  key={skill.id}
                                  className="flex items-center gap-2 rounded-xl border border-niceToHave px-2 py-1 text-niceToHave"
                                >
                                  <div className="grid h-7 w-7 shrink-0 place-items-center rounded-full bg-niceToHave text-sm text-white">
                                    {skill.experience}+
                                  </div>
                                  {skill.name}
                                </div>
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div className="flex h-full flex-col items-start gap-2 px-8 py-5">
                          <h2 className="text-xl">{job.company} Overview</h2>

                          <p className="-mt-1">{companyInfo.description || 'Undefined'}</p>

                          <h2 className="text-xl">Company Size</h2>
                          <p className="-mt-1">
                            {companyInfo.headcount ? `${getHeadcount(companyInfo.headcount)} employees` : 'Undefined'}
                          </p>

                          <h2 className="text-xl">Headquarters</h2>
                          <p className="-mt-1">{companyInfo.headquarters || 'Undefined'}</p>
                        </div>
                      )}
                    </div>
                  )}
                </section>

                {/* View Button */}
                <button
                  type="button"
                  onClick={() => setIsShowingFront((prev) => !prev)}
                  className="rounded-b-lg bg-subtitle/10 py-5 text-xl text-black"
                >
                  View {isShowingFront ? 'Back' : 'Front'}
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
