import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useImmer } from 'use-immer';
import { JobContext, stages } from './CreateJob';
import without from 'lodash/without';
import { Option } from '../../models/options';
import { NumericFormat } from 'react-number-format';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CreateJobInputSection from './CreateJobInputSection';

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface ICompensation {
  min_compensation: number;
  max_compensation?: number;
}

export default function NewJobBenefits() {
  const { job, setJob, saveJob, toNextPage } = useContext(JobContext);

  // * RHF is only used for validation
  const { control, handleSubmit, watch, setValue, reset } = useForm<ICompensation>({
    shouldUseNativeValidation: true,
  });
  useEffect(
    () =>
      reset({
        min_compensation: job.min_compensation,
        max_compensation: job.max_compensation === null ? undefined : job.max_compensation,
      }),
    []
  );

  const isRange =
    (job.max_compensation !== null && job.min_compensation !== job.max_compensation) || job.min_compensation === null;
  const [range, setRange] = useState(isRange);

  const [supplementalPays /* , setSupplementalPays */] = useImmer<Option[]>([
    { label: 'Signing Bonus', value: 'signing_bonus' },
    { label: 'Commission Pay', value: 'commission_pay' },
    { label: 'Living Stipends', value: 'living_stipends' },
    { label: 'Free Rent', value: 'free_rent' },
    { label: 'Tips', value: 'tips' },
    { label: 'Health Stipends', value: 'health_stipends' },
    { label: 'Severance Pay', value: 'severance_pay' },
    { label: 'Back Pay', value: 'back_pay' },
    { label: 'Fitness Stipends', value: 'fitness_stipends' },
  ]);

  const [benefits /* , setBenefits */] = useImmer<Option[]>([
    { label: 'Health Insurance', value: 'health_insurance' },
    { label: 'Paid Time Off', value: 'pto' },
    { label: '401(k)', value: 'retirement_401k' },
    { label: 'Dental Insurance', value: 'dental_insurance' },
    { label: 'Flexible Schedule', value: 'flexible_schedule' },
  ]);

  useEffect(() => {
    setJob({
      ...job,
      compensation_period: job.compensation_period || 'annual',
    });
  }, []);

  function handleChange(e: ChangeEvent<InputElement>): void {
    const value = e.target.value;
    const name = e.target.name;

    setJob({
      ...job,
      [name]: value,
    });
  }

  function handleBenefitsChange(e: ChangeEvent<HTMLInputElement>): void {
    const selectedBenefits = job.benefits || [];
    if (e.target.checked) {
      setJob({
        ...job,
        benefits: [...selectedBenefits, e.target.name],
      });
    } else {
      setJob({
        ...job,
        benefits: without(selectedBenefits, e.target.name),
      });
    }
  }

  function handleSuppPayChange(e: ChangeEvent<HTMLInputElement>): void {
    const selectedSuppPay = job.supplemental_pay || [];
    if (e.target.checked) {
      setJob({
        ...job,
        supplemental_pay: [...selectedSuppPay, e.target.name],
      });
    } else {
      setJob({
        ...job,
        supplemental_pay: without(selectedSuppPay, e.target.name),
      });
    }
  }

  const onSubmit: SubmitHandler<ICompensation> = () => {
    // TODO: validate the job rather than checking current element

    if (job) {
      saveJob().then(() => toNextPage());
    }
  };

  return (
    <form id={stages[3].name} className="flex flex-col gap-5 p-5" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Compensation Details</h1>

      <CreateJobInputSection title="Compensation Type" htmlFor="comp_period">
        <select
          name="compensation_period"
          id="comp_period"
          defaultValue={job.compensation_period}
          onChange={handleChange}
          required
        >
          <option value="annual">Annual Salary</option>
          <option value="hourly">Hourly</option>
          <option value="fixed">Fixed</option>
        </select>
      </CreateJobInputSection>

      <CreateJobInputSection title="Rate / Range" htmlFor="rate_range">
        <select
          name="rate_range"
          id="rate_range"
          className="mr-4 mt-2 grow"
          defaultValue={range ? 'true' : 'false'}
          onChange={(e) => {
            setRange(e.target.value === 'true');
            if (e.target.value === 'false') {
              // * Needed to use null as undefined didn't work properly
              setJob({ ...job, max_compensation: null as unknown as undefined });
              setValue('max_compensation', undefined);
            }
          }}
          required
        >
          <option value="false">Rate</option>
          <option value="true">Range</option>
        </select>

        {/* 
            // ! Update Design of this section
          */}
        <div className="ml-5 mt-5 flex flex-row items-center gap-3">
          <div className="relative flex flex-row items-center rounded-md bg-gray-300/75">
            <div className=" items-center justify-center px-[0.75rem] text-xl text-gray-500">$</div>
            <Controller
              name="min_compensation"
              control={control}
              rules={{
                required: 'This field is required',
                min: {
                  value: job.compensation_period === 'hourly' ? 7.25 : 1000,
                  message: `Must be greater than or equal to ${
                    job.compensation_period === 'hourly' ? '$7.25' : '$1,000'
                  }`,
                },
              }}
              render={({ field: { ref, onChange, ...field }, fieldState: { invalid } }) => (
                <NumericFormat
                  displayType="input"
                  decimalScale={2}
                  thousandSeparator=","
                  allowNegative={false}
                  placeholder={job.compensation_period === 'hourly' ? '45' : '100,000'}
                  aria-invalid={invalid}
                  className="invalid rounded-l-none"
                  getInputRef={ref}
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    setJob({
                      ...job,
                      min_compensation: values.floatValue,
                    });
                  }}
                  {...field}
                />
              )}
            />
          </div>
          {range && (
            <>
              -
              <div className="flex flex-row items-center rounded-md bg-gray-300/75">
                <div className="items-center justify-center px-[0.75rem] text-xl text-gray-500">$</div>
                <Controller
                  name="max_compensation"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) => {
                      if (value !== undefined && watch('min_compensation') > value) {
                        return 'Max compensation must be greater than min compensation';
                      }
                    },
                  }}
                  render={({ field: { ref, onChange, ...field }, fieldState: { invalid } }) => (
                    <NumericFormat
                      displayType="input"
                      decimalScale={2}
                      thousandSeparator=","
                      allowNegative={false}
                      placeholder={job.compensation_period === 'hourly' ? '45' : '100,000'}
                      aria-invalid={invalid}
                      className="invalid rounded-l-none"
                      getInputRef={ref}
                      onValueChange={(values) => {
                        onChange(values.floatValue);
                        setJob({
                          ...job,
                          max_compensation: values.floatValue,
                        });
                      }}
                      {...field}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
      </CreateJobInputSection>

      <CreateJobInputSection title="Supplemental Pay">
        <div className="flex flex-wrap gap-2">
          {supplementalPays.map((item) => (
            <div key={item.value}>
              <input
                className="peer hidden"
                type="checkbox"
                name={item.value}
                id={item.value}
                onChange={handleSuppPayChange}
                checked={job.supplemental_pay?.includes(item.value)}
              />
              <label
                htmlFor={item.value}
                className="cursor-pointer rounded-2xl border border-subtitle/20 px-4 py-2 text-sm font-normal text-subtitle peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white"
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </CreateJobInputSection>

      <CreateJobInputSection title="Benefits">
        <div className="flex flex-wrap gap-2">
          {benefits.map((item) => (
            <div key={item.value}>
              <input
                className="peer hidden"
                type="checkbox"
                name={item.value}
                id={item.value}
                onChange={handleBenefitsChange}
                checked={job.benefits?.includes(item.value)}
              />
              <label
                htmlFor={item.value}
                className="cursor-pointer rounded-2xl border border-subtitle/20 px-4 py-2 text-sm font-normal text-subtitle peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white"
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </CreateJobInputSection>
    </form>
  );
}
