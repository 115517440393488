import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { addDays, compareAsc, eachDayOfInterval, format, isAfter, isSameDay, isToday, parseISO } from 'date-fns';
import { useAuth } from '../contexts/user';
import UserAvatar from './shared/userAvatar';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/solid';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Interview } from '../services/interviews';

function DashboardInterviews({ today, interviews }: { today: Date; interviews: Interview[] }) {
  const [startDate, setStartDate] = useState(today);
  const [currentDate, setCurrentDate] = useState<{
    date: string;
    day: string;
    raw: Date;
    interviews: Interview[];
  }>(null!);

  const { user } = useAuth();

  useEffect(() => {
    const date = week.find((d) => isSameDay(d.raw, startDate));
    date && setCurrentDate(date);
  }, [startDate]);

  const beforeDotClasses =
    'before:absolute before:rounded-full before:h-3 before:w-3 before:bg-green-600 before:-bottom-4 before:transition-all';

  const endDate = addDays(startDate, 6);
  const week = eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
    return {
      date: format(d, 'd'),
      day: format(d, 'eee').toUpperCase(),
      raw: d,
      interviews: interviews.filter((i) => isSameDay(parseISO(i.start_at), d)),
    };
  });

  const isAfterLastInterview = (): boolean => {
    if (!interviews.length) return false;

    const lastInterviewStart = parseISO(interviews[interviews.length - 1].start_at);
    return isAfter(addDays(startDate, 6), lastInterviewStart);
  };

  const changeWeek = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      if (isAfterLastInterview()) return;
      setStartDate(addDays(startDate, 6));
    } else {
      setStartDate(addDays(startDate, -6));
    }
  };

  function handleChange(date: { date: string; day: string; raw: Date; interviews: Interview[] }) {
    setCurrentDate(date);
  }

  return (
    <div className="row-span-3 rounded-xl bg-white">
      <div className="flex items-center gap-5 px-8 py-4">
        <UserAvatar className="relative z-10 h-16 w-16 border-4 border-primary" />
        <div className="flex flex-col">
          <span className="text-2xl font-semibold">
            Hello, {user?.first_name} {user?.last_name}
          </span>
          <span className="text-sm text-subtitle">Upcoming Interviews</span>
        </div>
      </div>

      <div className="flex items-center bg-subtitle/20 p-3 pb-5">
        <button type="button" className="group" disabled={isToday(startDate)}>
          <ChevronLeftIcon
            aria-hidden="false"
            className="-order-10 mr-auto h-7 flex-shrink-0 fill-subtitle group-enabled:hover:fill-primary"
            onClick={() => changeWeek('prev')}
          />
        </button>
        {week.map((date) => (
          <div key={date.date} className="flex-grow">
            <input
              className="peer hidden"
              type="radio"
              name="date"
              id={date.date}
              onChange={() => handleChange(date)}
              checked={isSameDay(date.raw, currentDate?.raw)}
            />
            <label
              htmlFor={date.date}
              className={clsx(
                'relative flex cursor-pointer flex-col items-center rounded-lg px-3 py-1 peer-checked:bg-white peer-checked:text-primary',
                date.interviews.length && beforeDotClasses,
                date.interviews.length && 'text-primary'
              )}
            >
              <span className="text-xl ">{date.date}</span>
              <span className="text-xs text-subtitle">{date.day}</span>
            </label>
          </div>
        ))}
        <button type="button" className="group" disabled={isAfterLastInterview()}>
          <ChevronRightIcon
            aria-hidden="false"
            className="order-10 ml-auto h-7 flex-shrink-0 fill-subtitle group-enabled:hover:fill-primary"
            onClick={() => changeWeek('next')}
          />
        </button>
      </div>

      <div className="flex min-h-[34vh] flex-grow flex-col gap-5 overflow-y-auto px-6 py-4">
        {currentDate?.interviews.length ? (
          currentDate.interviews
            .sort((a, b) => compareAsc(parseISO(a.start_at), parseISO(b.start_at)))
            .slice(0, 2)
            .map((item) => {
              const start_at = parseISO(item.start_at);
              const end_at = parseISO(item.end_at);
              return (
                <div key={item.id} className="rounded-xl border border-subtitle/20 px-4 py-2">
                  <h3 className="text-lg font-semibold">Interview with {item.recruiter}</h3>
                  <p className="text-subtitle">{format(start_at, 'MMMM d, yyyy')}</p>

                  <div className="mt-3 flex justify-between">
                    <div>
                      <UserAvatar className="inline h-8 w-8" />
                      <img
                        className="-ml-3 inline h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt="Your Profile Picture"
                      />
                      <span className="ml-2">2 People</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <ClockIcon className="h-8 fill-subtitle" />
                      <span>
                        {format(start_at, 'p')} - {format(end_at, 'p')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <div className="mt-10 flex items-center gap-1 self-center rounded-xl border-2 border-dashed border-subtitle/10 px-10 py-5">
            <CalendarIcon className="inline h-8 text-subtitle" />
            <span className="font-medium text-subtitle">No upcoming interviews</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardInterviews;
