import {
  ClockIcon,
  EyeIcon,
  BanknotesIcon,
  LifebuoyIcon,
  FaceSmileIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/20/solid';

export interface Benefit {
  icon: React.ElementType;
  title: string;
  text: string;
}

export const benefits: { [key: string]: Benefit } = {
  health_insurance: { icon: LifebuoyIcon, title: 'Insurance', text: 'Health' },
  dental_insurance: { icon: FaceSmileIcon, title: 'Insurance', text: 'Dental' },
  vision_insurance: { icon: EyeIcon, title: 'Insurance', text: 'Vision' },
  pto: { icon: PaperAirplaneIcon, title: 'Schedule', text: 'PTO' },
  retirement_401k: { icon: BanknotesIcon, title: 'Retirement', text: '401(k)' },
  flexible_schedule: { icon: ClockIcon, title: 'Schedule', text: 'Flexible' },
};
