import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { GlobeAltIcon, MapPinIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Job } from '../../../models/job';
import HandshakeIcon from '../../shared/images/Handshake';
import { JobPostingMatch } from '../../../models/job-posting-match';
import { useAxios } from '../../../contexts/axios';
import { GetJobMatchDetails, GetJobMatches, UpdateJobMatch } from '../../../services/job-postings';
import { JobTimeline } from './JobTimeline';
import { SkillPills } from '../../../utils/pills';
import { Mixpanel, MixpanelEventType } from '../../../services/mixpanel';

const bg = ['bg-[#0F3E4C]', 'bg-primary'];

function Card({ job, match, color }: { job: Job; match: JobPostingMatch; color: string }) {
  const [timeline, setShowTimeline] = useState(false);
  const [details, setDetails] = useState<JobPostingMatch>();
  const { axios } = useAxios();

  useEffect(() => {
    if (match.id == undefined) return;
    if (match.candidate == undefined) return;
    GetJobMatchDetails(axios, job.id, match.id).then(
      (res) => {
        setDetails(res.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }, [match.id]);

  function Approve() {
    //post status approved
    UpdateJobMatch(axios, job.id, match.id, 'approved').then(
      (res) => {
        setDetails(res.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  function Decline() {
    //post status declined
    UpdateJobMatch(axios, job.id, match.id, 'rejected').then(
      (res) => {
        setDetails(res.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  return (
    <div className="absolute left-0 top-4 z-30 flex h-full w-full flex-col overflow-hidden rounded-xl border bg-white">
      {/* Heading */}
      <div className={clsx('flex flex-row justify-between rounded-t-xl text-white', color)}>
        <div className="mx-4 my-2 flex flex-row items-center gap-4">
          <div className="flex flex-col">
            <div className="font-semibold" data-testid="candidate-name">
              {details?.candidate?.name}
            </div>
            <div className="">{job.title}</div>
            <div className="flex flex-row items-center gap-2">
              <MapPinIcon className="h-6 w-6" />
              <span className="">{job.location_type || job.location}</span>
            </div>
          </div>
          <div
            className={clsx(
              'flex-none rounded-full px-4 py-2',
              match.score > 80
                ? 'bg-pgreen'
                : match.score > 60
                ? 'bg-yellow-500'
                : match.score > 40
                ? 'bg-porange'
                : 'bg-red-600'
            )}
          >
            {match.score || 0} %
          </div>
        </div>
        {details?.status === 'super_yes' || details?.status === 'yes' || details?.status === 'no' ? (
          <div className="flex flex-row items-center justify-center gap-x-4 rounded-tr-xl bg-black bg-opacity-25 px-12">
            <button className="flex h-12 w-12 items-center justify-center rounded-full" onClick={Decline}>
              <XCircleIcon className="h-12 w-12" />
            </button>
            <button className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-500" onClick={Approve}>
              <HandshakeIcon className="h-6 w-6" />
            </button>
          </div>
        ) : (
          <div className="text-bold flex flex-row items-center justify-center px-12 text-subtitle">
            {details?.status}
          </div>
        )}
      </div>
      {/* About or Timeline */}
      <div className="flex justify-around pt-6 text-center">
        <span
          className={clsx(
            'w-full cursor-pointer border-b-2 pb-4 text-xl font-semibold',
            !timeline ? 'border-primary text-primary' : 'border-subtitle text-subtitle'
          )}
          onClick={() => setShowTimeline(false)}
        >
          About
        </span>
        <span
          className={clsx(
            'w-full cursor-pointer border-b-2 pb-4 text-xl font-semibold',
            !timeline ? 'border-subtitle text-subtitle' : 'border-primary text-primary'
          )}
          onClick={() => setShowTimeline(true)}
        >
          Experience Timeline
        </span>
      </div>
      {/* Subsection */}
      {!timeline ? (
        <div className="mb-4 flex h-full flex-col items-stretch gap-4 overflow-y-auto overflow-x-clip p-4 lg:flex-row">
          <div className="flex w-full flex-col">
            <span className="">Description</span>
            <div className="grow rounded-xl bg-gray-200 p-4">{details?.candidate?.description}</div>
          </div>
          <div className="flex w-full flex-col">
            {details?.candidate?.website ? (
              <>
                <span className="">Website</span>
                <div className="mb-4 flex flex-row gap-x-2 rounded-xl bg-gray-200 p-2">
                  <GlobeAltIcon className="h-6 w-6 fill-subtitle" /> {details?.candidate?.website}
                </div>
              </>
            ) : null}
            <span className="">Matched Skills</span>
            <div className="grow gap-2 rounded-xl bg-gray-200 p-4">
              {details && details.matched_skills && details.matched_skills?.length != 0 ? (
                <>
                  <SkillPills skills={details.matched_skills} />
                </>
              ) : (
                'None'
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full grow flex-col justify-center overflow-y-auto p-0">
          {details ? <JobTimeline match={details} /> : null}
        </div>
      )}
    </div>
  );
}

export default function JobCardDeck({ job, filter }: { job: Job; filter?: string }) {
  const [matches, setMatches] = useState<JobPostingMatch[]>([]);
  const [currentMatch, setCurrentMatch] = useState(0);
  const { axios } = useAxios();

  useEffect(() => {
    GetJobMatches(axios, job.id, filter).then(
      (res) => {
        setMatches(res.data);
        Mixpanel.track(MixpanelEventType.JOB_MATCHES_LOADED);
      },
      (err) => {
        console.error(err);
        Mixpanel.track(MixpanelEventType.JOB_MATCHES_FAILED);
      }
    );
  }, [job, filter]);
  useEffect(() => {
    const keyPress = (event: { key: string }) => {
      if (event.key === 'ArrowRight') {
        setCurrentMatch(currentMatch < matches.length - 1 ? currentMatch + 1 : matches.length - 1);
      }
      if (event.key === 'ArrowLeft') {
        setCurrentMatch(currentMatch > 0 ? currentMatch - 1 : 0);
      }
    };
    window.addEventListener('keydown', keyPress);
    return () => window.removeEventListener('keydown', keyPress);
  }, [currentMatch]);

  return (
    <div className="relative min-h-[100vh] gap-x-4 lg:min-h-[75vh]">
      {matches.length > 0 ? (
        <Card job={job} match={matches[currentMatch]} color={bg[currentMatch % 2]} />
      ) : (
        <div className="flex w-full flex-col items-center justify-center text-primary">No Matches Available</div>
      )}
      {currentMatch + 1 < matches.length ? (
        <div
          className={clsx(
            'absolute left-2 top-2 z-20 flex h-full w-full flex-col overflow-hidden rounded-xl',
            bg[(currentMatch + 1) % 2]
          )}
        />
      ) : null}
      {currentMatch + 2 < matches.length ? (
        <div
          className={clsx(
            'absolute left-4 top-0 z-10 flex h-full w-full flex-col overflow-hidden rounded-xl',
            bg[currentMatch % 2]
          )}
        />
      ) : null}
    </div>
  );
}
