import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { ChangeEvent, useState, useRef, FormEvent } from 'react';
import { useImmer } from 'use-immer';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/user';
import { Toast } from '../../utils/toast';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps, ErrorResponse } from '../../utils/errors';

import ShowHideSection from './ShowHideSection';

type AccountInfo = {
  email: string;
  new_password: string;
  confirmation_password: string;
};

export default function AccountSettings() {
  const { user } = useAuth();
  const [accountInfo, setAccountInfo] = useImmer<AccountInfo>({
    email: user?.email ? user?.email : '',
    new_password: '',
    confirmation_password: '',
  });
  const emailRef = useRef<string>(accountInfo.email);
  const initalEmail = emailRef.current;
  const [passwordShown, setPasswordShown] = useState(false);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name in accountInfo) {
      setAccountInfo((draft) => {
        draft[name as keyof AccountInfo] = value;
      });
    } else {
      throw new Error(`Unknown field ${name}`);
    }
  };

  const deleteAccount = () => {
    if (confirmDelete) {
      axios
        .delete('/recruiters/account')
        .then((resp) => {
          console.log(resp.data);
          Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_DELETE_SUCCESS);
          Toast.success('Successfully Deleted Account');
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          console.error(err);
          Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_DELETE_FAIL, errorProps(err));
          Toast.error('An error occurred, please try again');
        });
    } else {
      Toast.error('You Must Click Checkbox to Confirm Account Deletion');
    }
  };

  const onInvalid = (event: React.FormEvent, inputErrorMessage: string) => {
    const target = event.target as HTMLInputElement;
    // !Note - could override HTML5 validation to add custom error messages instead of setting customValidity to inputErrorMessage
    target.setCustomValidity(inputErrorMessage);
    setShowInvalid(true);
  };

  const saveEmail = (e: FormEvent) => {
    e.preventDefault();

    const data = {
      recruiter: {
        email: accountInfo.email,
        password: null,
        password_confirmation: null,
      },
    };

    if (data.recruiter.email == initalEmail) return Toast.info('Email has not been changed');

    axios
      .put('/recruiters/account', data)
      .then(() => {
        Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_CHANGE_EMAIL_SUCCESS);
        Toast.success('An email verification link was sent to your entered email address');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        console.error(err);
        Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_CHANGE_EMAIL_FAIL, errorProps(err));
        Toast.error('An error occurred, please try again');
      });
  };

  const savePassword = (e: FormEvent) => {
    e.preventDefault();

    const data = {
      recruiter: {
        email: initalEmail,
        password: accountInfo.new_password,
        password_confirmation: accountInfo.confirmation_password,
      },
    };

    if (!data.recruiter.password || !data.recruiter.password_confirmation)
      return Toast.error('Passwords cannot be blank');

    if (data.recruiter.password !== data.recruiter.password_confirmation) return Toast.error('Passwords do not match');

    axios
      .put('/recruiters/account', data)
      .then(() => {
        Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_CHANGE_PASSWORD_SUCCESS);
        Toast.success('Password has been changed');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        console.error(err);
        Mixpanel.track(MixpanelEventType.SETTINGS_ACCOUNT_CHANGE_PASSWORD_FAIL, errorProps(err));
        Toast.error('An error occurred, please try again');
      });
  };

  return (
    <div className="flex flex-col gap-8">
      <h1 className="text-xl font-bold ">Account Settings</h1>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Email Address">
        <form onSubmit={saveEmail}>
          <p className="-mt-2 text-sm text-subtitle">Your email address is {initalEmail}</p>
          <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
            <span className="font-semibold">Edit Email Address</span>
            <input
              className=""
              name="email"
              type="email"
              placeholder=""
              onChange={handleChange}
              value={accountInfo.email}
            />
          </label>
          <SubmitButton />
        </form>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Password">
        <form onSubmit={savePassword}>
          <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
            <span className="font-semibold">New password</span>
            <div className="relative">
              <input
                name="new_password"
                type={passwordShown ? 'text' : 'password'}
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                onInvalid={(e) =>
                  onInvalid(
                    e,
                    'must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum length of 10 characters'
                  )
                }
                autoComplete="current-password"
                /** password regex
                 *  must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and min length 8 char */
                pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$"
                className={clsx('', showInvalid && 'invalid')}
                onChange={handleChange}
                value={accountInfo.new_password}
              />
              <button
                type="button"
                onClick={() => setPasswordShown(!passwordShown)}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {passwordShown ? (
                  <EyeSlashIcon className="h-5 w-5 text-black" aria-hidden="true" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-black" aria-hidden="true" />
                )}
              </button>
            </div>
            <span className="font-semibold">Confirm password</span>
            <div className="relative">
              <input
                name="confirmation_password"
                type={passwordShown ? 'text' : 'password'}
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                onInvalid={(e) =>
                  onInvalid(
                    e,
                    'must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum length of 10 characters'
                  )
                }
                autoComplete="false"
                /** password regex
                 *  must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and min length 8 char */
                pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$"
                className={clsx('', showInvalid && 'invalid')}
                onChange={handleChange}
                value={accountInfo.confirmation_password}
              />
              <button
                type="button"
                onClick={() => setPasswordShown(!passwordShown)}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {passwordShown ? (
                  <EyeSlashIcon className="h-5 w-5 text-black" aria-hidden="true" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-black" aria-hidden="true" />
                )}
              </button>
            </div>
          </label>
          <SubmitButton />
        </form>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Delete Your Account">
        <>
          <p className="-mt-2 pr-16 text-sm text-subtitle">
            When you delete your account, you lose access to Front account services, and we permanently delete your
            personal data. You can cancel the deletion for 14 days.
          </p>
          <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
            <div className="flex items-center gap-3">
              <input
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                name="confirm_delete"
                type="checkbox"
                placeholder=""
                checked={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.checked)}
              />
              <span className="">Confirm that I want to delete my account.</span>
            </div>
          </label>
          <button
            type="button"
            className="self-start rounded-xl bg-primary px-32 py-4 text-white"
            onClick={deleteAccount}
          >
            Delete Account
          </button>
        </>
      </ShowHideSection>
    </div>
  );
}

const SubmitButton = () => {
  return (
    <button type="submit" className="mt-4 self-start rounded-xl bg-primary px-8 py-4 text-white">
      Save Changes
    </button>
  );
};
