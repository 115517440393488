import React from 'react';
import { Link } from 'react-router-dom';
import check from '../assets/passwordResetEmailIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';

type Props = {
  email: string;
};

function PasswordResetVerifyEmail({ email }: Props) {
  return (
    <div className="flex h-screen w-screen flex-row items-center justify-center">
      <div className="flex h-screen w-9/12 items-center justify-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex items-center justify-center p-4">
            <img src={check} alt="lock" />
          </div>

          <div className="flex items-center justify-center p-4">
            <h1 className="text-3xl font-black">Check your email</h1>
          </div>

          <div className="flex flex-row items-center justify-center p-4">
            <h1 className="flex text-center text-sm text-subtitle">We have sent an email to&nbsp;</h1>
            <h1 className="text-sm text-black">{email}</h1>
          </div>

          <form>
            <div className="flex w-full flex-col items-center justify-center p-4">
              <Link to="/login" className="flex flex-row items-center p-2 text-sm text-subtitle">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
            <div className="flex w-full flex-col items-center justify-center p-4 text-sm">
              <h1 className="text-subtitle">
                Didn&apos;t receive the email?&nbsp;
                <a className="text-primary">Click to resend</a>
              </h1>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetVerifyEmail;
