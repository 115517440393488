import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import lock from '../assets/passwordResetIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { AxiosError } from 'axios';
import { IUser } from '../models/user';
import { errorProps, ErrorResponse } from '../utils/errors';
import { useAxios } from '../contexts/axios';

interface Props {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

function ResetPasswordEmail({ setEmail, email, setCompleted }: Props) {
  const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  const { axios } = useAxios();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (regex.test(email)) {
      axios
        .post<IUser>('/recruiters/password', {
          recruiter: {
            email: email,
          },
        })
        .then(() => {
          setCompleted(true);
          Mixpanel.track(MixpanelEventType.FORGOT_PASSWORD_SUBMIT_SUCCESS);
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          Mixpanel.track(MixpanelEventType.FORGOT_PASSWORD_SUBMIT_FAILED, errorProps(err));
          console.log('Sorry, an error occurred');
        });
    }
  };

  return (
    <div className="flex h-screen w-screen flex-row items-center justify-center">
      <div className="flex h-screen w-9/12 items-center justify-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex items-center justify-center p-4">
            <img src={lock} alt="lock" />
          </div>

          <div className="flex items-center justify-center p-4">
            <h1 className="text-3xl font-black">Forgot password?</h1>
          </div>

          <div className="flex items-center justify-center p-4">
            <h1 className="flex text-center text-sm text-subtitle">
              Enter the email address associated with your account, and we will send you a reset link!
            </h1>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="items-left flex flex-col justify-center p-4">
              <label htmlFor="email">Company Email Address</label>
              <input
                id="email"
                type="email"
                required
                placeholder="Company email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            <div className="flex w-full flex-col items-center justify-center p-4">
              <button type="submit" className="btn btn-primary w-full">
                Reset password
              </button>

              <Link to="/login" className="mt-3 flex flex-row items-center p-2 text-sm text-subtitle">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordEmail;
