import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  PlusIcon,
  BellIcon,
  BriefcaseIcon,
  CalendarIcon,
  Cog6ToothIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { Link, Navigate, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg';
import clsx from 'clsx';
import { useAuth } from '../contexts/user';
import UserAvatar from './shared/userAvatar';
import Notifications from './notifications';

const navigation = [
  {
    section: '',
    children: [
      { name: 'Dashboard', href: '/', icon: HomeIcon },
      { name: 'Interviews', href: '/interviews', icon: CalendarIcon },
    ],
  },
  {
    section: 'RECRUITMENT',
    children: [{ name: 'Job Postings', href: '/jobs', icon: BriefcaseIcon }],
  },
  {
    section: 'ORGANIZATION',
    children: [
      { name: 'Profile', href: '/company', icon: ClipboardDocumentListIcon },
      { name: 'Settings', href: '/settings/company', icon: Cog6ToothIcon },
    ],
  },
];

const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  { name: 'Settings', href: '/settings/company' },
];

function Layout() {
  const [searchInput, setSearchInput] = useState<string>('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    auth.signout();
  };

  if (auth.user?.organization.status === 'onboarding') {
    return <Navigate to="/onboard/profile" state={{ from: location }} replace />;
  }

  return (
    <>
      {/* top nav */}
      <nav className="fixed inset-x-0 top-0 z-50 flex h-20 items-center justify-between bg-primary font-medium text-white shadow-lg">
        {/** Logo */}
        <div className="flex flex-shrink-0 items-center bg-primary px-4">
          <img className="h-10 w-auto" src={logo} alt="logo" />
        </div>
        <div className="flex flex-1 justify-between px-4">
          <div className="flex flex-1 items-center">
            {/** Form */}
            <form className="w-22 md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative text-lightblue focus-within:text-gray-600">
                {/** Search Icon */}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <MagnifyingGlassIcon className={clsx('h-5 w-5', searchInput !== '' && 'hidden')} aria-hidden="true" />
                </div>
                {/** Search Input */}
                <input
                  id="search-field"
                  className="block h-10 w-full rounded-full border-transparent bg-white/10 py-2 pl-8 pr-3 leading-5 text-lightblue placeholder-lightblue focus:bg-white focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm md:pl-3"
                  placeholder="Search..."
                  type="search"
                  name="search"
                  onChange={(event) => setSearchInput(event.target.value)}
                  value={searchInput}
                />
              </div>
            </form>
            {/** 'BellIcon'/Notification Button  */}
            <Notifications />
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {/** 'Create New Job' Button -> navigate to /jobs/create  */}
            <button
              onClick={() => navigate('/jobs/create')}
              type="button"
              className="mr-1 flex gap-2 rounded-lg bg-white/10 p-2 font-normal text-white focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2"
            >
              <span className="sr-only">View notifications</span>
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
              Create Job
            </button>
            {/* Profile Dropdown*/}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <UserAvatar className="h-10 w-10 border border-white" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          className={clsx(active && 'bg-gray-100', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    <a href="#" onClick={logout} className="block px-4 py-2 text-sm text-gray-700">
                      Sign out
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </nav>

      {/* desktop sidebar */}
      <aside className={'fixed inset-y-0 overflow-y-auto overflow-x-hidden bg-white pt-20 text-white md:w-60'}>
        <div className="mt-3 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2 pb-4">
            {navigation.map((item) => (
              <div key={item.section}>
                <h3 className={'hidden text-sm font-bold text-subtitle/75 md:block'}>{item.section}</h3>
                {item.children.map((link) => (
                  <NavLink
                    title={link.name}
                    key={link.name}
                    to={link.href}
                    className={({ isActive }) =>
                      clsx(
                        isActive ? 'bg-lightblue text-[#0F799A]' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                      )
                    }
                    end={link.href === '/' ? true : false}
                  >
                    {({ isActive }) => (
                      <>
                        <link.icon
                          aria-label={link.name}
                          className={clsx(
                            isActive ? 'text-[#0F799A]' : 'text-gray-400 group-hover:text-gray-500',
                            'h-6 w-6 flex-shrink-0 md:mr-3'
                          )}
                          aria-hidden="true"
                        />
                        <span className={'hidden md:block'}>{link.name}</span>
                      </>
                    )}
                  </NavLink>
                ))}
              </div>
            ))}
          </nav>
        </div>
      </aside>

      {/* main content */}
      <main className="h-full pl-14 pt-20 md:pl-60">
        <div className="flex min-h-full flex-col bg-lightblue p-4">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default Layout;
