import React from 'react';
import MobilePhone from './shared/images/MobilePhone';

function DashboardApp() {
  return (
    <div className="relative flex items-end justify-between gap-7 rounded-xl bg-primary px-7 pt-7">
      <div className="mb-7 flex max-w-[28ch] flex-col items-start gap-3">
        <p className="font-semibold text-white">
          Get Prosper AI on App Store and in Play Store now! Match anywhere, anytime!
        </p>
        <button className="btn rounded-xl bg-[#52A0B7] px-8 py-3 text-base font-thin text-white">Download</button>
      </div>

      <MobilePhone className="h-40 flex-shrink-0" />
    </div>
  );
}

export default DashboardApp;
