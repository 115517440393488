import { toast as t } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

export enum ToastPositionType {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
}

const defaultOptions: ToastOptions = {
  position: ToastPositionType.TOP_CENTER,
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: 'light',
};

export const Toast = {
  success: (message: string, options?: ToastOptions) => {
    t.success(message, { ...defaultOptions, ...options });
  },
  info: (message: string, options?: ToastOptions) => {
    t.info(message, { ...defaultOptions, ...options });
  },
  error: (message: string, options?: ToastOptions) => {
    t.error(message, { ...defaultOptions, ...options });
  },
  warn: (message: string, options?: ToastOptions) => {
    t.warn(message, { ...defaultOptions, ...options });
  },
};
