import { ArrowUpIcon, CheckIcon } from '@heroicons/react/24/solid';
import axios, { AxiosError } from 'axios';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import ShowHideSection from './ShowHideSection';
import { Toast } from '../../utils/toast';

interface CompanyInfo {
  description: string;
  headcount: string;
  id: string;
  industry_id: string;
  logo: string;
  name: string;
  // * Website URL isn't in the company endpoint
  url: string;
  // * headquarters isn't in the company endpoint
  headquarters: string;
}

interface Logo {
  name: string;
  size: number;
}

export default function CompanySettings() {
  const [companyInfo, setCompanyInfo] = useImmer<CompanyInfo>({} as CompanyInfo);
  const [logo, setLogo] = useImmer<Logo>({} as Logo);
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    axios.get('/recruiters/industries').then(
      (res) => {
        setIndustries(res.data);
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_INDUSTRIES_LOADED);
      },
      (err: AxiosError<ErrorResponse>) => {
        console.warn(err);
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_INDUSTRIES_FAILED, errorProps(err));
      }
    );
  }, []);

  useEffect(() => {
    axios.get('/recruiters/company').then(
      (res) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_LOADED);
        setCompanyInfo({ ...res.data, logo: process.env.REACT_APP_API_BASE + res.data.logo });
      },
      (err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_FAILED);
        console.error(err);
      }
    );
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const name: keyof CompanyInfo = e.target.name as keyof CompanyInfo;
    const value = e.target.value;

    if (name == 'logo') return;

    setCompanyInfo((draft) => {
      draft[name] = value;
    });
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || e.target.files.length === 0) return;
    const logo = e.target.files[0];

    setLogo((draft) => {
      draft.name = logo.name;
      draft.size = logo.size;
    });

    const data = new FormData();
    data.append('company[logo]', logo);
    axios
      .put('/recruiters/company', data)
      .then((resp) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_CHANGE_LOGO_SUCCESS);
        setCompanyInfo((draft) => {
          draft.logo = process.env.REACT_APP_API_BASE + resp.data.logo;
        });
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_CHANGE_LOGO_FAILED);
        console.error(err);
      });
  };

  const saveChanges = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .put('/recruiters/company', { company: companyInfo })
      .then((resp) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_CHANGE_SUCCESS);
        Toast.success('Changes saved');

        setCompanyInfo((draft) => {
          draft.logo = process.env.REACT_APP_API_BASE + resp.data.logo;
        });
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.SETTINGS_COMPANY_CHANGE_FAILED);
        Toast.error('Failed to save changes');
        console.error(err);
      });
  };

  return (
    <form onSubmit={saveChanges} className="flex flex-col gap-8">
      <h1 className="text-xl font-bold ">Company Information</h1>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Company name">
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit company name</span>
          <input
            className=""
            name="name"
            type="text"
            placeholder=""
            onChange={(e) => handleChange(e)}
            value={companyInfo.name}
          />
        </label>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Logo">
        <>
          <label className="flex w-full min-w-[15rem] flex-col items-center gap-3 rounded-xl border border-dashed border-gray-300  py-8 text-subtitle">
            <ArrowUpIcon className="h-12 flex-shrink-0  rounded-full border-2 border-subtitle p-2" />
            <div className="text-lg">
              <span className="line-through">Drag and drop</span>
              {' or '}
              <span className="cursor-pointer text-primary hover:text-primary/80">browse</span> to choose a file
            </div>
            <input
              id="dropzone-file"
              name="logo"
              type="file"
              className="hidden"
              onChange={(e) => handleLogoChange(e)}
            />
          </label>
          <h2 className="font-semibold text-primary">Uploaded files</h2>
          <div className="flex items-center gap-3 rounded-lg bg-gray-100 px-4 py-2 text-xs">
            <img src={companyInfo.logo} alt="Company Logo" className="h-12 w-12 flex-shrink-0 rounded object-cover" />
            <div className="flex flex-col gap-1 text-xs">
              <span className="font-semibold">{logo.name || 'Current Logo'}</span>
              <span className="text-subtitle">{logo.size || 'Unknown Size'}</span>
            </div>
            <CheckIcon className="ml-auto h-6 text-green-500" />
          </div>
        </>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Description">
        <label className="flex w-full min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit description</span>
          <textarea
            className="min-h-[7.5rem]"
            name="description"
            onChange={(e) => handleChange(e)}
            value={companyInfo.description}
          />
        </label>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Number of employees">
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit number of employees</span>
          <select
            id="headcount"
            name="headcount"
            value={companyInfo.headcount}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <option className="text-subtitle" value="ten">
              1-10
            </option>
            <option className="text-subtitle" value="fifty">
              11-50
            </option>
            <option className="text-subtitle" value="two_hundred">
              51-200
            </option>
            <option className="text-subtitle" value="five_hundred">
              201-500
            </option>
            <option className="text-subtitle" value="one_thousand">
              501-1,000
            </option>
            <option className="text-subtitle" value="five_thousand">
              1,001-5,000
            </option>
            <option className="text-subtitle" value="ten_thousand">
              5,001-10,000
            </option>
            <option className="text-subtitle" value="max">
              10,000+
            </option>
          </select>
        </label>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Website URL">
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit URL</span>
          <input
            className=""
            name="url"
            type="text"
            placeholder=""
            onChange={(e) => handleChange(e)}
            value={companyInfo.url}
          />
        </label>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Industry">
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit industry</span>
          <select
            name="industry_id"
            id="industry_id"
            value={companyInfo.industry_id}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <option value="" disabled>
              Industry
            </option>
            {industries.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </label>
      </ShowHideSection>

      <ShowHideSection className="flex flex-col gap-5" sectionName="Headquarters">
        <label className="flex w-1/3 min-w-[15rem] flex-col gap-3">
          <span className="font-semibold">Edit headquarters</span>
          <input
            className=""
            name="headquarters"
            type="text"
            placeholder=""
            onChange={(e) => handleChange(e)}
            value={companyInfo.headquarters}
          />
        </label>
      </ShowHideSection>

      <button type="submit" className="self-start rounded-xl bg-primary px-8 py-4 text-white">
        Save Changes
      </button>
    </form>
  );
}
