import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import JobListDropdown from './JobListDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import {
  SortingState,
  flexRender,
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { usePagination } from '../../utils/pagination';
import { Link } from 'react-router-dom';
import { Job } from '../../models/job';
import { daysUntil } from '../../utils/date';
import { useAxios } from '../../contexts/axios';
import { GetJobPostings } from '../../services/job-postings';
import { DeleteJob } from '../../utils/job';

function getSalary(job: Partial<Job>, newtype = ''): string {
  const format = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const value = job.max_compensation ? job.max_compensation : job.min_compensation || 0;
  const conversion =
    newtype === job.compensation_period
      ? 1.0
      : newtype === 'hourly'
      ? 1 / (40 * 52)
      : job.compensation_period === 'hourly'
      ? 40 * 52
      : 1.0;
  const type = newtype ? newtype : !job.compensation_period ? '' : job.compensation_period;
  const typetext = !type || type === 'fixed' ? '' : type === 'annual' ? '/yr' : '/hr';
  return `${format.format(value * conversion)}${typetext}`;
}

function daysLeft(job: Partial<Job>): number {
  if (!job.expires_at) return 0;
  return daysUntil(job.expires_at);
}

function JobsTable() {
  const [salaryType, setSalaryType] = useState('annual');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { pagination, onPaginationChange, page, limit } = usePagination();
  const [jobs, setJobs] = useState<Partial<Job>[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const { axios } = useAxios();

  useEffect(() => {
    GetJobPostings(axios, page + 1, limit).then(
      (res) => {
        const count = Number(res.headers['x-total']);
        setJobs(res.data);
        setTotal(count);
        setPageCount(Math.round(count / limit));
      },
      (err) => {
        console.error(err);
      }
    );
  }, [limit, page]);

  function incrementSalaryType() {
    const salaryTypes = ['annual', 'hourly'];
    const index = (salaryTypes.indexOf(salaryType) + 1) % salaryTypes.length;
    setSalaryType(salaryTypes[index]);
  }

  const columns: ColumnDef<Partial<Job>>[] = useMemo(
    () => [
      {
        header: 'Postings',
        columns: [
          {
            id: '1',
            header: 'Job Title',
            accessorFn: (job) => job,
            cell: (info) => <Link to={`/jobs/${info.getValue().id}`}>{info.getValue().title}</Link>,
            sortingFn: (a, b, id) => {
              const jobA: Partial<Job> = a.getValue(id);
              const jobB: Partial<Job> = b.getValue(id);
              return (jobA.title || '') > (jobB.title || '') ? -1 : 1;
            },
          },
          {
            id: '2',
            header: 'Total Compensation',
            accessorFn: (job) => job,
            cell: (info) => {
              return <span data-testid="salary">{getSalary(info.getValue(), salaryType)}</span>;
            },
            sortingFn: (a, b, id) => {
              const jobA: Partial<Job> = a.getValue(id);
              const jobB: Partial<Job> = b.getValue(id);
              return (jobA.min_compensation || 0) > (jobB.min_compensation || 0) ? -1 : 1;
            },
          },
          {
            id: '3',
            header: 'Days Left',
            accessorFn: (job) => daysLeft(job),
            cell: (info) => {
              return <span data-testid="date">{info.getValue()}</span>;
            },
          },
          {
            id: '4',
            header: 'Location',
            accessorFn: (job) => job.location || 'Remote',
            cell: (info) => info.getValue(),
          },
          {
            id: '5',
            accessorKey: 'status',
            header: 'Status',
            cell: (info) => {
              return (
                <select
                  className={clsx(
                    'block w-full flex-1 rounded-md border-gray-300 px-2 py-2 text-sm focus:border-primary focus:ring-primary',
                    info.getValue() === 'active' ? 'text-primary' : 'text-gray-300'
                  )}
                  defaultValue={info.getValue()}
                >
                  <option value="active">Active</option>
                  <option value="disabled">Disabled</option>
                </select>
              );
            },
            enableSorting: false,
          },
          {
            id: '6',
            header: 'Menu',
            accessorFn: (job) => job,
            cell: (info) => {
              return <JobListDropdown job={info.getValue()} DeleteJob={DeleteJob} />;
            },
            enableSorting: false,
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data: jobs,
    columns,
    state: {
      sorting,
      pagination,
    },
    pageCount,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //
    debugTable: false,
  });

  return (
    <>
      {jobs.length == 0 ? (
        <div className="flex flex-row justify-center">No Data</div>
      ) : (
        <table className="min-w-full table-fixed divide-y divide-gray-300">
          <thead className="bg-gray-50">
            {table
              .getHeaderGroups()
              .slice(1)
              .map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex justify-center items-center'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <div className="text-lg font-medium">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                            {
                              {
                                asc: <ChevronUpIcon className="flex h-6 cursor-pointer fill-black px-3" />,
                                desc: <ChevronDownIcon className="flex h-6 cursor-pointer fill-black px-3" />,
                              }[header.column.getIsSorted() as string]
                            }
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {table
              .getRowModel()
              .rows.slice(0, 10)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {total <= limit ? null : (
        <div className="mt-7 flex justify-center">
          <div className="flex w-80 justify-evenly rounded-xl border-2 border-subtitle">
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
              <ChevronDoubleLeftIcon className="h-11 cursor-pointer border-r-[1px] border-subtitle fill-subtitle p-3" />
            </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              <ChevronLeftIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3" />
            </button>

            <select
              className="h-11  border-x-[1px] border-y-0 text-primary"
              name=""
              id=""
              onChange={(e) => table.setPageIndex(Number(e.target.value))}
              value={table.getState().pagination.pageIndex}
            >
              {Array.from(Array(table.getPageCount()).keys()).map((page) => (
                <option value={page} key={page}>
                  {page + 1}
                </option>
              ))}
            </select>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              <ChevronRightIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3 " />
            </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
              <ChevronDoubleRightIcon className="h-11 cursor-pointer border-l-[1px] border-subtitle fill-subtitle p-3" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default JobsTable;
