import React from 'react';
import { Job } from '../../models/job';
import JobCard from './JobCard';

interface IJobCardProps {
  jobs: Partial<Job>[];
  DeleteJob: (id: string) => void;
}

function JobCards(props: IJobCardProps) {
  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5" role="list">
      {props.jobs.map((job) => (
        <JobCard key={job.id} job={job} Deletejob={props.DeleteJob} />
      ))}
    </div>
  );
}

export default JobCards;
