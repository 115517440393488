import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import {
  SortingState,
  flexRender,
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { JobPostingMatch } from '../../../models/job-posting-match';
import { localDateFromISO } from '../../../utils/date';
import { usePagination } from '../../../utils/pagination';
import { GetJobMatches } from '../../../services/job-postings';
import { useAxios } from '../../../contexts/axios';
import { Job } from '../../../models/job';
import { Mixpanel, MixpanelEventType } from '../../../services/mixpanel';

const sortingTranslation: { [key: string]: { id: string; desc: boolean } } = {
  newest: { id: 'updated_at', desc: true },
  match: { id: 'candidate', desc: true },
  status: { id: 'status', desc: true },
};

const statusText: { [key: string]: string } = {
  yes: 'New Match',
  no: 'New Match',
  approved: 'Approved',
  interviewed: 'Interviewed',
  rejected: 'Denied',
};

function StatusButton(props: { status: string }) {
  return (
    <button
      className={clsx(
        'h-10 w-32 rounded-full px-4 py-2 font-semibold',
        props.status === 'yes' && 'border-[1px] border-pgreen text-pgreen',
        props.status === 'no' && 'border-[1px] border-pgreen text-pgreen',
        props.status === 'approved' && 'border-[1px] border-yellow-500 text-yellow-500',
        props.status === 'interviewed' && 'bg-primary text-white',
        props.status === 'rejected' && 'bg-red-500 text-white'
      )}
    >
      {statusText[props.status] || props.status}
    </button>
  );
}

export default function JobMatches({ job, filter }: { job: Job; filter?: string }) {
  const { axios } = useAxios();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { pagination, onPaginationChange, limit, page } = usePagination();
  const [matches, setMatches] = useState<JobPostingMatch[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    GetJobMatches(axios, job.id, filter, page + 1, limit).then(
      (res) => {
        const count = Number(res.headers['x-total']);
        setTotal(count);
        setMatches(res.data);
        setPageCount(Math.round(count / limit));
        Mixpanel.track(MixpanelEventType.JOB_MATCHES_LOADED);
      },
      (err) => {
        console.error(err);
        Mixpanel.track(MixpanelEventType.JOB_MATCHES_FAILED);
      }
    );
  }, [job, limit, page, filter]);

  const columns: ColumnDef<JobPostingMatch>[] = useMemo(
    () => [
      {
        header: 'Matches',
        columns: [
          {
            id: '0',
            accessorKey: 'updated_at',
            header: 'Date Applied',
            cell: (info) => {
              return localDateFromISO(info.getValue());
            },
          },
          {
            id: '1',
            accessorKey: 'candidate',
            header: 'Candidate',
            cell: (info) => <div className="flex items-center justify-center">{info.getValue().name}</div>,
          },
          {
            id: '2',
            accessorKey: 'candidate',
            header: 'Location',
            cell: (info) => info.getValue().location,
            enableSorting: false,
          },
          {
            id: '3',
            accessorKey: 'status',
            header: 'Status',
            cell: (info) => (
              <div className="flex items-center justify-center">
                <StatusButton status={info.getValue()} />
              </div>
            ),
            sortingFn: (rowA, rowB, columnId) => {
              const translate: { [key: string]: number } = { yes: 3, no: 3, scheduled: 2, interviewed: 1, rejected: 0 };
              const a: string = rowA.getValue(columnId);
              const b: string = rowB.getValue(columnId);
              if (translate[a] > translate[b]) return 1;
              if (translate[a] < translate[b]) return -1;
              return 0;
            },
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data: matches,
    columns,
    state: {
      sorting,
      pagination,
    },
    pageCount,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //
    debugTable: false,
  });

  return (
    <div className="p-2 px-8">
      {/* Top Matches and Sort */}
      <div className="flex items-center">
        <div className="mr-auto flex items-center gap-3">
          <span className="font-semibold">Top Matches</span>
          <span className="cursor-pointer text-sm text-primary">View All</span>
        </div>

        <div className="ml-auto flex items-center gap-3">
          <label className="text-subtitle" htmlFor="sort">
            Sort By:
          </label>
          <select
            className="rounded-md border-none py-1"
            name="sort"
            id="sort"
            defaultValue="match"
            onChange={(e) => setSorting([sortingTranslation[e.target.value]])}
          >
            <option value="newest">Newest</option>
            <option value="match">Match</option>
            <option value="status">Status</option>
          </select>
        </div>
      </div>

      {/* React Table */}
      {matches.length == 0 ? (
        <div className="flex flex-row justify-center">No Data</div>
      ) : (
        <table className="mt-7 w-full [&_tr_td]:p-3">
          <thead className="bg-subtitle/10 [&_tr_th]:py-6">
            {table
              .getHeaderGroups()
              .slice(1)
              .map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex flex-col justify-center'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <div className="text-lg font-medium">{header.column.columnDef.header as string}</div>
                            {
                              {
                                asc: <ChevronUpIcon className="flex h-11 cursor-pointer fill-subtitle p-3" />,
                                desc: <ChevronDownIcon className="flex h-11 cursor-pointer fill-subtitle p-3" />,
                              }[header.column.getIsSorted() as string]
                            }
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 10)
              .map((row) => {
                return (
                  <tr key={row.id} className="border-b-2 border-subtitle/10">
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {/* Page Box */}
      {total <= limit ? null : (
        <div className="mt-7 flex justify-center">
          <div className="flex w-80 justify-evenly rounded-xl border-2 border-subtitle">
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
              <ChevronDoubleLeftIcon className="h-11 cursor-pointer border-r-[1px] border-subtitle fill-subtitle p-3" />
            </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              <ChevronLeftIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3" />
            </button>

            <select
              className="h-11  border-x-[1px] border-y-0 text-primary"
              name=""
              id=""
              onChange={(e) => table.setPageIndex(Number(e.target.value))}
              value={table.getState().pagination.pageIndex}
            >
              {Array.from(Array(table.getPageCount()).keys()).map((page) => (
                <option value={page} key={page}>
                  {page + 1}
                </option>
              ))}
            </select>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              <ChevronRightIcon className="h-11 cursor-pointer border-x-[1px] border-subtitle fill-subtitle p-3 " />
            </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
              <ChevronDoubleRightIcon className="h-11 cursor-pointer border-l-[1px] border-subtitle fill-subtitle p-3" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
