import React from 'react';

export default function MobilePhone({ className }: { className: string }) {
  return (
    <svg
      className={className}
      // width="55"
      // height="137"
      viewBox="0 0 55 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.2768 93.6523H8.75557C4.11712 93.6523 0.335449 90.3193 0.335449 86.2312V8.37427C0.335449 4.28613 4.11712 0.953125 8.75557 0.953125H46.2768C50.9153 0.953125 54.6969 4.28613 54.6969 8.37427V86.2312C54.6969 90.3193 50.9153 93.6523 46.2768 93.6523Z"
        fill="white"
      />
      <path
        d="M46.3947 91.439H8.63713C5.35772 91.439 2.69873 89.0955 2.69873 86.2052V8.40036C2.69873 5.51002 5.35772 3.1665 8.63713 3.1665H46.3947C49.6741 3.1665 52.3331 5.51002 52.3331 8.40036V86.2052C52.3331 89.0955 49.6741 91.439 46.3947 91.439Z"
        fill="#73C1D8"
      />
      <path
        d="M21.4595 81.2835H12.6257C11.3258 81.2835 10.2622 80.3461 10.2622 79.2004V71.4147C10.2622 70.269 11.3258 69.3315 12.6257 69.3315H21.4595C22.7594 69.3315 23.823 70.269 23.823 71.4147V79.2004C23.823 80.3461 22.7594 81.2835 21.4595 81.2835Z"
        fill="white"
      />
      <path
        d="M42.5244 41.4417H24.2956C22.4047 41.4417 20.8389 40.0877 20.8389 38.3951V38.0847C20.8389 36.4182 22.3752 35.0381 24.2956 35.0381H42.5244C44.4152 35.0381 45.9811 36.3921 45.9811 38.0847V38.3951C45.9811 40.0877 44.4447 41.4417 42.5244 41.4417Z"
        fill="white"
      />
      <path
        d="M22.7003 21.5883H11.7984C10.1144 21.5883 8.75537 20.3905 8.75537 18.9063V17.1757C8.75537 15.6915 10.1144 14.4937 11.7984 14.4937H22.7003C24.3843 14.4937 25.7433 15.6915 25.7433 17.1757V18.9063C25.7433 20.3905 24.3843 21.5883 22.7003 21.5883Z"
        fill="white"
      />
      <path
        d="M33.7204 7.20288H21.0164C20.0119 7.20288 19.0369 6.73417 18.5051 5.97904C17.6351 4.74119 18.5205 3.03662 20.0335 3.03662H34.6862C36.1997 3.03662 37.0807 4.74666 36.2021 5.97904C35.6703 6.73417 34.7249 7.20288 33.7204 7.20288Z"
        fill="white"
      />
      <path
        d="M2.573 136.342C2.48437 136.342 2.39574 136.342 2.27756 136.316C1.65713 136.186 1.27306 135.613 1.42078 135.04C3.81386 126.577 10.1954 118.642 15.8384 113.694C20.8314 109.32 25.5289 105.18 26.6811 99.7895C26.7993 99.2166 27.4198 98.8521 28.0697 98.9562C28.7197 99.0604 29.1333 99.6072 29.0151 100.18C27.7447 106.221 22.7813 110.57 17.5224 115.179C11.8499 120.152 5.9706 127.671 3.75477 135.535C3.57751 136.03 3.1048 136.342 2.573 136.342Z"
        fill="white"
      />
      <path
        d="M28.3728 102.649H25.7729C24.9456 102.649 24.2661 102.051 24.2661 101.321L24.2661 96.459C24.2661 94.9089 25.5227 93.6523 27.0728 93.6523C28.6229 93.6523 29.8795 94.9089 29.8795 96.4591V101.321C29.8795 102.051 29.2 102.649 28.3728 102.649Z"
        fill="white"
      />
    </svg>
  );
}
