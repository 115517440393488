import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

import LinkToCalenders from './LinkToCalender';

interface ILinkToCalendarsModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO: Add LinkToCalender COmponent
const LinkToCalendersModal: React.FC<ILinkToCalendarsModalProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="container fixed left-1/2 top-1/2 max-w-3xl -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white px-8 py-12 shadow-xl transition-all">
            <LinkToCalenders closeModal={() => setIsOpen(false)} />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default LinkToCalendersModal;
