import { DeleteJobPosting } from '../services/job-postings';
import axios from 'axios';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
(', MixpanelEventTyp');

export const DeleteJob = (id: string, callback?: (id: string) => void) => {
  DeleteJobPosting(axios, id)
    .then((res) => {
      if (res.status === 204) {
        Mixpanel.track(MixpanelEventType.JOB_DETAILS_DELETE_SUCCESS);
        // Use callback for notify it to refresh the data
        callback && callback(id);
      }
    })
    .catch((error: unknown) => {
      console.warn(error);
    });
};
