import React, { useContext, FormEvent, ChangeEvent } from 'react';
import { useImmer } from 'use-immer';

import { JobContext, stages } from './CreateJob';
import { degreeStatuses, employmentTypes, Option } from '../../models/options';
import CreateJobInputSection from './CreateJobInputSection';
import TagInput from './TagInput';
import { useAxios } from '../../contexts/axios';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../../utils/errors';
import { titleize } from '../../utils/string';

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export default function NewJobDetails() {
  const { job, setJob, saveJob, toNextPage } = useContext(JobContext);

  const { axios } = useAxios();

  const selectedFocusesState = useImmer<Option[]>([]);

  const [majors, setMajors] = useImmer<Option[]>([]);

  React.useEffect(() => {
    axios.get('/recruiters/majors').then(
      (res) => {
        const array = res.data as { id: string; name: string }[];
        const majors: Option[] = array.map((major) => ({
          label: titleize(major.name),
          value: major.id,
        }));

        setMajors(majors);

        // sets initial state of majors
        selectedFocusesState[1](() => {
          const defaultMajors: Option[] = job.majors?.map(({ id, name }) => ({ value: id, label: name })) || [];
          return defaultMajors;
        });
      },
      (err: AxiosError<ErrorResponse>) => {
        console.log(err);
      }
    );
  }, []);

  function handleChange(e: ChangeEvent<InputElement>): void {
    const name = e.target.name;
    const value = e.target.value;

    const isNonCollege = name === 'education_level' && (value === 'high_school' || value === 'no_degree');

    if (isNonCollege) {
      setJob({
        ...job,
        education_level: value,
        major_ids: [],
      });
    } else {
      setJob({
        ...job,
        [name]: value,
      });
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO: validate the job rather than checking current element
    if (job) {
      saveJob()
        .then(() => toNextPage())
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <form id={stages[1].name} className="flex flex-col gap-5 p-5" onSubmit={handleSubmit}>
      <h1 className="font-bold">Education Preferences</h1>

      <CreateJobInputSection title="Employment Type" htmlFor="employment_type">
        <select
          name="employment_type"
          id="employment_type"
          defaultValue=""
          value={job.employment_type}
          required
          onChange={handleChange}
        >
          <option value="" disabled>
            Employment Type
          </option>
          {employmentTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </CreateJobInputSection>

      <CreateJobInputSection title="Requirements">
        <div className="flex flex-col gap-5">
          <label>
            Any education requirements?
            <select
              className="mt-2"
              name="education_level"
              id="education_level"
              value={job.education_level}
              onChange={handleChange}
              defaultValue=""
              required
            >
              <option value="" disabled>
                Education Level
              </option>
              {degreeStatuses.map((level) => (
                <option key={level.value} value={level.value}>
                  {level.label}
                </option>
              ))}
            </select>
          </label>

          {job.education_level && job.education_level !== 'high_school' && job.education_level !== 'no_degree' ? (
            <TagInput
              options={majors}
              selectedOptionsState={selectedFocusesState}
              onChoiceChange={(_choice, newSelectedOptions) => {
                // save the majors
                const major_ids = newSelectedOptions.map((major) => major.value);
                setJob((prev) => ({ ...prev, major_ids: major_ids }));
              }}
              placeholder="Degree or Focus"
              labelText="Since you selected some college or higher, what degree or focus applies to this role?"
            />
          ) : null}
        </div>
      </CreateJobInputSection>
    </form>
  );
}
