import { AxiosInstance, AxiosResponse } from 'axios';

export type Interview = {
  id: string;
  job_posting_match_id: string;
  start_at: string;
  end_at: string;
  location: string;
  recruiter_id: string;
  job_posting_id: string;
  job_title: string;
  recruiter: string;
};

export function GetInterviews(axios: AxiosInstance): Promise<AxiosResponse<Interview[]>> {
  return axios.get<Interview[]>('/recruiters/interviews');
}
