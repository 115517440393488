import React, { useState, FormEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import lock from '../assets/passwordResetIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { AxiosError } from 'axios';
import { IUser } from '../models/user';
import { useAuth } from '../contexts/user';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';
import { errorProps, ErrorResponse } from '../utils/errors';
import { useAxios } from '../contexts/axios';
import { Toast } from '../utils/toast';

type Props = {
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PasswordResetNewPassword({ setCompleted }: Props) {
  const [password, setPassword] = useState('');
  const [passwordC, setPasswordC] = useState('');
  const [showInvalid, setShowInvalid] = useState<boolean>(false);
  const { axios } = useAxios();

  const auth = useAuth();
  const { token } = useParams();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (password && passwordC) {
      const lengthP = password.length;
      const lengthPC = password.length;
      if (lengthP === lengthPC) {
        axios
          .put<IUser>('/recruiters/password', {
            recruiter: {
              reset_password_token: token,
              password: password,
              password_confirmation: passwordC,
            },
          })
          .then(() => {
            Mixpanel.track(MixpanelEventType.NEW_PASSWORD_SUCCESS);
            setCompleted(true);
            auth.signout();
          })
          .catch((error: AxiosError<ErrorResponse>) => {
            Mixpanel.track(MixpanelEventType.NEW_PASSWORD_FAILED, errorProps(error));
            switch (error.response?.status) {
              case 422:
                Toast.error('Error: password reset link is invalid or expired');
                console.log(error.response.data.errors);
                break;
              default:
                Toast.error('Error: password reset invalid');
            }
          });
      }
    }
  };

  const onInvalid = (event: FormEvent, inputErrorMessage: string) => {
    const target = event.target as HTMLInputElement;
    target.setCustomValidity(inputErrorMessage);
    setShowInvalid(true);
  };

  return (
    <div className="flex h-screen w-screen flex-row items-center justify-center">
      <div className="flex h-screen w-9/12 items-center justify-center bg-lightblue p-4">
        <div className="flex w-8/12 flex-col">
          <div className="flex items-center justify-center p-4">
            <img src={lock} alt="lock" />
          </div>
          <div className="flex items-center justify-center p-4">
            <h1 className="text-3xl font-black">Set a new password</h1>
          </div>
          <div className="flex items-center justify-center p-4">
            <h1 className="flex text-center text-sm text-subtitle">Please enter your new password below.</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="items-left flex flex-col justify-center p-4">
              <input
                required
                autoComplete="new-password"
                type="password"
                value={password}
                pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{10,}$"
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                onInvalid={(e) =>
                  onInvalid(
                    e,
                    'must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum length of 10 characters'
                  )
                }
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <p className="p-1 text-sm text-subtitle">Must be at least 8 characters long.</p>
            </div>
            <div className="items-left flex flex-col justify-center p-4">
              <input
                required
                autoComplete="new-password"
                type="password"
                value={passwordC}
                placeholder="Confirm password"
                onChange={(e) => {
                  setPasswordC(e.target.value);
                }}
              />
            </div>
            <div className="flex w-full flex-col items-center justify-center p-4">
              <div className="items-center justify-center p-4">
                <button type="submit" className="btn btn-primary">
                  Reset password
                </button>
              </div>
              <div className="flex items-center justify-center">
                <h1>Didn&apos;t receive an email?</h1>
                <Link to="/passwordreset" className="flex flex-row items-center p-2 text-sm text-primary">
                  Click to re-send
                </Link>
              </div>
              <Link to="/login" className="flex flex-row items-center p-2 text-sm text-subtitle">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
