import React from 'react';
import LinkToCalenders from '../jobs/job_matches/LinkToCalender';

export default function NotificationSettings() {
  return (
    <div>
      <LinkToCalenders />
    </div>
  );
}
