import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Job } from '../../models/job';
import { useAxios } from '../../contexts/axios';

interface IJobSurveyModal {
  job: Partial<Job>;
  onClose: () => void;
}
type JobSurveyType = {
  job_posting_close_survey: {
    filled: boolean | null;
    unfilled_reasons: string[];
  };
};

export default function JobSurveyModal({ job, onClose }: IJobSurveyModal) {
  const [roleFilled, setRollFilled] = useState<boolean>(false);
  const [reason, setReason] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [surveyData, setSurveyData] = useState<JobSurveyType>({
    job_posting_close_survey: {
      filled: false,
      unfilled_reasons: [],
    },
  });
  const navigate = useNavigate();
  const { axios } = useAxios();

  useEffect(() => {
    setSurveyData({
      ...surveyData,
      job_posting_close_survey: {
        ...surveyData.job_posting_close_survey,
        filled: roleFilled,
        unfilled_reasons: reason,
      },
    });
  }, [roleFilled, reason]);

  const survey = (reason: string) => {
    if (surveyData.job_posting_close_survey.unfilled_reasons.includes(reason)) {
      setReason((prevSurvey) => prevSurvey.filter((item) => item !== reason));
    } else if (surveyData.job_posting_close_survey.unfilled_reasons.includes(reason) === false) {
      setReason((prevSurvey) => [...prevSurvey, reason]);
    }
  };

  const SubmitSurvey = () => {
    setLoading(true);
    axios
      .put(`/recruiters/job_postings/${job?.id}/close_survey`, surveyData)
      .then(() => {
        onClose();
        navigate('/jobs');
      })
      .catch((error) => {
        console.error('Error making PUT request:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-auto max-w-3xl">
          {/*content*/}
          <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
            {/*header*/}
            <div className="border-blueGray-200 flex items-start justify-between rounded-t border-b border-solid p-5">
              <h3 className="text-2xl font-semibold">Close Job Post</h3>
              <button
                className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="block h-6 w-6 bg-transparent text-2xl text-black opacity-5 outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}

            <div>
              <h2 className="mx-4 mt-4 text-xl font-bold ">Tell us more</h2>
              <p className="mx-4 mt-4 text-lg font-semibold">Has this role been filled?</p>
              <div className="flex flex-row ">
                <div className="mx-5 my-4 flex items-center justify-center">
                  <input
                    type="radio"
                    value=""
                    name="first-radio"
                    className="h-4 w-4 border-gray-300 bg-gray-100 text-primary focus:ring-2 focus:ring-primary dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary"
                    onChange={() => {
                      setRollFilled(true);
                    }}
                  />
                  <label className="ms-2 text-lg font-medium text-gray-900 dark:text-gray-300">Yes</label>
                </div>
                <div className="mx-5 my-4 flex items-center justify-center">
                  <input
                    type="radio"
                    defaultChecked
                    value=""
                    name="first-radio"
                    className="h-4 w-4 border-gray-300 bg-gray-100 text-primary focus:ring-2 focus:ring-primary dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary"
                    onChange={() => {
                      setRollFilled(false);
                    }}
                  />
                  <label className="ms-2 text-lg font-medium text-gray-900 dark:text-gray-300">No</label>
                </div>
              </div>
              <div className="m-4">
                <p className="mt-4 text-lg font-semibold">
                  If no, is the reason for closing this role any of the following?
                </p>
                <div>
                  <div className="flex flex-row items-center">
                    <input
                      name="second-radio"
                      value=""
                      id={'position_eliminated'}
                      type="checkbox"
                      className="h-4 w-4 rounded-sm"
                      onChange={(e) => {
                        survey(e.target.id);
                      }}
                    />
                    <label className="ms-2 text-base font-medium text-slate-500 dark:text-gray-300">
                      Position is on eliminated
                    </label>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="second-radio"
                      value=""
                      id={'on_hold'}
                      type="checkbox"
                      className="h-4 w-4 rounded-sm"
                      onChange={(e) => {
                        survey(e.target.id);
                      }}
                    />
                    <label className="ms-2 text-base font-medium text-slate-500 dark:text-gray-300">
                      Position is on hold
                    </label>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="second-radio"
                      value=""
                      id={'not_enough_applicants'}
                      type="checkbox"
                      className="h-4 w-4 rounded-sm"
                      onChange={(e) => {
                        survey(e.target.id);
                      }}
                    />
                    <label className="ms-2 text-base font-medium text-slate-500 dark:text-gray-300">
                      Not enough candidates in the pipeline
                    </label>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="second-radio"
                      value=""
                      id={'bad_matches'}
                      type="checkbox"
                      className="h-4 w-4 rounded-sm"
                      onChange={(e) => {
                        survey(e.target.id);
                      }}
                    />
                    <label className="ms-2 text-base font-medium text-slate-500 dark:text-gray-300">Bad matches</label>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="second-radio"
                      value=""
                      id={'other'}
                      type="checkbox"
                      className="h-4 w-4 rounded-sm"
                      onChange={(e) => {
                        survey(e.target.id);
                      }}
                    />
                    <label className="ms-2 text-base font-medium text-slate-500 dark:text-gray-300">Other</label>
                  </div>
                </div>
              </div>
              <div className="m-4 flex justify-end">
                <button
                  className="background-transparent mb-1 mr-6 border  px-6 py-2 text-sm font-bold text-black outline-none transition-all duration-150 ease-linear focus:outline-none"
                  type="button"
                  onClick={() => {
                    onClose();
                    navigate('/jobs');
                  }}
                >
                  Skip
                </button>
                <button
                  className="mb-1 mr-1 rounded bg-primary px-6 py-3 text-sm font-bold text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none"
                  type="button"
                  disabled={loading}
                  onClick={SubmitSurvey}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}
