import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';

interface IPlan {
  name: string;
  priceElement: JSX.Element;
  options: string[];
}

//Plans: What's located on each card
function Plans() {
  const [isMonthly, setIsMonthly] = useState(true);

  const [jobCount, setJobCount] = useState(1);

  const navigate = useNavigate();

  const plans: IPlan[] = [
    {
      name: 'Basic',
      priceElement: <span className="text-5xl text-primary">Free</span>,
      options: ['Post 1 Job', 'Job Expires after 14 days', 'Max 10 Matches', 'Automated Interview Tool'],
    },
    {
      name: 'Starter',
      priceElement: (
        <span className="text-5xl text-primary">
          ${isMonthly ? 250 : 2400} <span className="text-base">/job</span>
        </span>
      ),
      options: [
        'Job Post Expires After 30 Days',
        'Al Matching Tool',
        'Automated Interview Tool',
        'Custom Job Creation',
        'Help Desk Support',
      ],
    },
    {
      name: 'Premium',
      priceElement: (
        <span className="text-primary">We offer custom enterprise pricing plans. Some of our benefits below. </span>
        // <span className="text-primary">
        //   We offer custom enterprise pricing plans. Consider this if you are an HR/recruiting firm, a Fortune 1000
        //   company or a company with more than 2 open requisitions per month
        // </span>
      ),
      options: [
        'Admin Console',
        'Verified Employer Badge',
        'Priority Help Desk Support',
        'Data Analytics',
        'Seamless ATS Integration',
      ],
    },
  ];

  const Plan = ({ plan: { name, options, priceElement } }: { plan: IPlan }) => (
    <section className="flex w-full max-w-sm flex-col rounded-xl border border-primary bg-white px-8 py-9">
      <h2 className="text-lg font-semibold">{name}</h2>
      {priceElement}
      <div className="flex w-full flex-col py-2">
        {options.map((value) => (
          <div className="relative flex-row py-2" key={value}>
            <div className="pointer-events-none absolute left-0 flex items-center">
              <CheckCircleIcon className="h-6 w-6 fill-gray-400 stroke-white" />
            </div>
            <div className="pl-8 text-sm">{value}</div>
          </div>
        ))}
      </div>

      {name === 'Starter' ? (
        <div className="my-5 flex items-center gap-5 bg-lightblue px-7 py-5 text-primary">
          <button onClick={() => setJobCount((prev) => (prev -= 1))} disabled={jobCount <= 1}>
            <MinusIcon className="h-7 rounded border border-primary p-1" />
          </button>
          <span>{jobCount}</span>
          <button onClick={() => setJobCount((prev) => (prev += 1))}>
            <PlusIcon className="h-7 rounded border border-primary p-1" />
          </button>
          <span className="text-sm font-medium">Total: ${jobCount * (isMonthly ? 250 : 2400)}</span>
        </div>
      ) : null}

      {name === 'Premium' ? (
        <a
          href="https://www.joinprosper.ai/contact"
          target="blank"
          className="btn mt-auto border border-primary text-primary"
        >
          Contact Us
        </a>
      ) : (
        <Link
          className="btn btn-primary mt-auto"
          to="/onboard/billing"
          state={{ plan: name.toLowerCase(), monthly: isMonthly, jobCount }}
        >
          <button>Select Plan</button>
        </Link>
      )}
    </section>
  );

  return (
    <div className="relative">
      <header className="flex flex-col items-center gap-5 text-white">
        <div className="flex items-center gap-4 px-5 text-5xl font-semibold">
          <button type="button" onClick={() => navigate('/onboard/profile')}>
            <ChevronLeftIcon className="h-6" />
          </button>
          <h1>Prosper AI has a plan for teams of all sizes</h1>
        </div>
        <h2 className="px-14 text-base">
          On average, hiring managers review
          <strong className="font-semibold"> 250+ applications </strong>
          prior to extending an offer...Not on Prosper!
        </h2>
        <div className="grid grid-cols-2 text-center">
          <label
            className={clsx(
              'cursor-pointer rounded-l-xl border border-r-0 border-white px-4 py-2',
              isMonthly ? 'bg-primary text-white' : 'bg-white text-subtitle'
            )}
          >
            Monthly
            <input
              className="hidden"
              type="radio"
              name="billingOption"
              onClick={() => setIsMonthly(true)}
              value="monthly"
              defaultChecked={isMonthly}
            />
          </label>

          <label
            className={clsx(
              'cursor-pointer rounded-r-xl border border-l-0 border-white px-4 py-2',
              !isMonthly ? 'bg-primary text-white' : 'bg-white text-subtitle'
            )}
          >
            Annually <span className="text-xs">(20% off)</span>
            <input
              className="hidden"
              type="radio"
              name="billingOption"
              onClick={() => setIsMonthly(false)}
              value="annually"
              defaultChecked={!isMonthly}
            />
          </label>
        </div>
      </header>
      <div className="mx-auto mt-10 flex w-screen max-w-7xl flex-wrap justify-center gap-10">
        {plans.map((value, index) => (
          <Plan key={index} plan={value} />
        ))}
      </div>
    </div>
  );
}

export default Plans;
