import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/axios';
import { useAuth } from '../../contexts/user';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { errorProps, ErrorResponse } from '../../utils/errors';
import { stateAbbeviations } from '../../utils/states';

interface IBillingInfo {
  billing_first_name?: string;
  billing_last_name?: string;
  billing_email?: string;
  billing_street1?: string;
  billing_street2?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
}

interface IProps {
  complete: VoidFunction;
}

export default function BillingInfo(props: IProps) {
  const { axios } = useAxios();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [billingInfo, setBillingInfo] = useState<IBillingInfo>({
    billing_first_name: user?.organization.billing_first_name,
    billing_last_name: user?.organization.billing_last_name,
    billing_email: user?.organization.billing_email,
    billing_street1: user?.organization.billing_street1,
    billing_street2: user?.organization.billing_street2,
    billing_city: user?.organization.billing_city,
    billing_state: user?.organization.billing_state,
    billing_zip: user?.organization.billing_zip,
  });
  const [loading, setLoading] = useState(false);

  const saveBillingInfo = () => {
    axios
      .put('/recruiters/billing', {
        organization: billingInfo,
      })
      .then(() => {
        props.complete();
        Mixpanel.track(MixpanelEventType.BILLING_INFO_SUCCESS);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        Mixpanel.track(MixpanelEventType.BILLING_INFO_FAILED, errorProps(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    saveBillingInfo();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="text-lg font-bold">Billing Information</h1>
      <div className="mt-5 grid w-full grid-cols-2 gap-3 pr-[5%] lg:pr-[20%]">
        <label className="flex flex-col gap-1" htmlFor="billing_first_name">
          First Name
          <input
            type="text"
            required
            name="billing_first_name"
            pattern={'[a-zA-Z]+'}
            placeholder="First Name"
            onChange={handleChange}
            value={billingInfo.billing_first_name}
          />
        </label>

        <label className="flex flex-col gap-1" htmlFor="billing_last_name">
          Last Name
          <input
            type="text"
            required
            name="billing_last_name"
            pattern={'[a-zA-Z]+'}
            placeholder="Last Name"
            onChange={handleChange}
            value={billingInfo.billing_last_name}
          />
        </label>
      </div>

      <div className="mt-5 pr-[5%] lg:pr-[20%]">
        <label htmlFor="billing_email">Email Address</label>
        <input
          type="email"
          required
          id="billing_email"
          name="billing_email"
          placeholder="Email Address"
          onChange={handleChange}
          value={billingInfo.billing_email}
        />
      </div>
      <div className="mt-5 pr-[5%] lg:pr-[20%]">
        <label htmlFor="billing_street1">Street Address</label>
        <input
          type="text"
          required
          id="billing_street1"
          name="billing_street1"
          placeholder="Street Address"
          onChange={handleChange}
          value={billingInfo.billing_street1}
        />
      </div>
      <div className="mt-5 pr-[5%] lg:pr-[20%]">
        <label htmlFor="billing_street2">Apt, suite, etc. (optional)</label>
        <input
          type="text"
          id="billing_street2"
          name="billing_street2"
          placeholder="Apt, suite, etc. (optional)"
          onChange={handleChange}
          value={billingInfo.billing_street2}
        />
      </div>
      <div className="mt-5 grid w-full grid-cols-1 gap-3 pr-[5%] md:grid-cols-3 lg:pr-[20%]">
        <div>
          <label htmlFor="city">City</label>
          <input
            type="text"
            required
            id="billing_city"
            name="billing_city"
            onChange={handleChange}
            value={billingInfo.billing_city}
            placeholder="City"
          />
        </div>
        <div>
          <label htmlFor="billing_state">State</label>
          <select
            name="billing_state"
            id="billing_state"
            defaultValue=""
            required
            onChange={handleChange}
            value={billingInfo.billing_state}
          >
            <option value="" disabled>
              State
            </option>
            {stateAbbeviations.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="billing_zip">Zipcode</label>
          <input
            type="text"
            required
            id="billing_zip"
            name="billing_zip"
            onChange={handleChange}
            value={billingInfo.billing_zip}
            pattern={'[0-9]{5}'}
            maxLength={5}
            placeholder="Zipcode"
          />
        </div>

        {/** Back and 'Save & Continue' button */}
        <div className="mt-5 flex w-full max-w-sm flex-row items-center md:w-96">
          <button
            className="mr-5 inline-flex items-center font-medium text-primary no-underline hover:underline"
            onClick={() => navigate('/onboard/plans')}
          >
            <ChevronLeftIcon className="h-5 w-5 text-primary" strokeWidth={2.5} />
            Back
          </button>

          <button type="submit" className="btn btn-primary px-[9px] py-2 md:px-10 md:py-4" disabled={loading}>
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  );
}
